import React, { useState } from "react";
import Layout from "../../Components/Layout";
import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import ErrorBox from "../../Components/ErrorBox";
import { Link as DirectLink } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import TemplateCard from "../../Components/Template/TemplateCard";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import Sidebar from "../../Components/Sidebar";
import { Filter } from "@mui/icons-material";

function DaftarListTemplate() {
  //==================================================Context
  const masukContext = useGlobal();
  const { user, openErrorBox, setErrorServer } = masukContext;
  //==========================================================get data
  const [data, setData] = useState([]);

  const getDaftarTemplate = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/template/template";
    try {
      const response = await axios.get(url);
      setData(
        response.data.map((e) => {
          return {
            nama_template: e.nama_template,
            pk_id_template: e.pk_id_template,
            religi: e.religi,
            bahasa: e.bahasa,
            path_thumb_template: e.path_thumb_template,
            path_image_template: e.path_image_template,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  useEffect(() => {
    getDaftarTemplate(/*usrid*/);
  }, []);

  //==========================================================Delete data
  const deleteData = (id) => {
    setData(data.filter((item) => item.pk_id_template != id));
  };

  //=============================================================pooper
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPop, setOpenPop] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPop((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  //========================================data category

  const [dataBahasa, setDataBahasa] = useState({
    Indonesia: true,
    English: true,
  });

  const [dataReligi, setDataReligi] = useState({
    General: true,
    Kristen: true,
    Budha: true,
    Hindu: true,
    Islam: true,
  });

  const [selectAllLanguages, setSelectAllLanguages] = useState(true);
  const [selectAllReligions, setSelectAllReligions] = useState(true);

  useEffect(() => {
    const allLanguagesChecked = Object.values(dataBahasa).every(
      (value) => value === true
    );
    const allReligionsChecked = Object.values(dataReligi).every(
      (value) => value === true
    );

    setSelectAllLanguages(allLanguagesChecked);
    setSelectAllReligions(allReligionsChecked);
  }, [dataBahasa, dataReligi]);

  const handleLanguageCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setDataBahasa({
      ...dataBahasa,
      [name]: checked,
    });
  };

  const handleReligionCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setDataReligi({
      ...dataReligi,
      [name]: checked,
    });
  };

  const handleSelectAllLanguagesChange = () => {
    const newValue = !selectAllLanguages;
    setSelectAllLanguages(newValue);
    const updatedFilters = {};

    Object.keys(dataBahasa).forEach((language) => {
      updatedFilters[language] = newValue;
    });

    setDataBahasa(updatedFilters);
  };

  const handleSelectAllReligionsChange = () => {
    const newValue = !selectAllReligions;
    setSelectAllReligions(newValue);
    const updatedFilters = {};

    Object.keys(dataReligi).forEach((religion) => {
      updatedFilters[religion] = newValue;
    });

    setDataReligi(updatedFilters);
  };

  const filteredData = data.filter(
    (item) =>
      (selectAllLanguages ||
        Object.keys(dataBahasa).some(
          (bahasa) => dataBahasa[bahasa] && item.bahasa === bahasa
        )) &&
      (selectAllReligions ||
        Object.keys(dataReligi).some(
          (religi) => dataReligi[religi] && item.religi === religi
        ))
  );

  return (
    <Layout>
      <Sidebar />
      <Popper
        open={openPop}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ p: 2 }}>
              <Typography>Pilih Bahasa</Typography>
              <FormControlLabel
                key="allBahasa"
                control={
                  <Checkbox
                    checked={selectAllLanguages}
                    onChange={handleSelectAllLanguagesChange}
                    name="SelectAllLanguages"
                  />
                }
                label="All"
              />
              {Object.keys(dataBahasa).map((option) => (
                <FormControlLabel
                  key={option}
                  // value={option}
                  control={
                    <Checkbox
                      checked={dataBahasa[option]}
                      onChange={handleLanguageCheckboxChange}
                      name={option}
                    />
                  }
                  label={option}
                />
              ))}
              <Typography>Pilih Religi</Typography>
              <FormControlLabel
                key="allReligion"
                control={
                  <Checkbox
                    checked={selectAllReligions}
                    onChange={handleSelectAllReligionsChange}
                    name="SelectAllReligions"
                  />
                }
                label="All"
              />
              {Object.keys(dataReligi).map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={
                    <Checkbox
                      checked={dataReligi[option]}
                      onChange={handleReligionCheckboxChange}
                      name={option}
                    />
                  }
                  label={option}
                />
              ))}
            </Paper>
          </Fade>
        )}
      </Popper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", mt: 7 }}>
            <ErrorBox />

            <Box
              sx={{
                mb: 5,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3">Daftar Template</Typography>
              <Box>
                {user.role == "super" ? (
                  <Button component={DirectLink} to="/template/tambah">
                    Tambah Template
                  </Button>
                ) : (
                  <Box />
                )}
                <Button
                  variant="contained"
                  color="ok"
                  sx={{ color: "white", borderRadius: "100px" }}
                  size="small"
                  startIcon={<Filter />}
                  onClick={handleClick("bottom-end")}
                >
                  Filter
                </Button>
              </Box>
            </Box>
            <TemplateCard
              user={user.role}
              data={filteredData}
              deleteData={deleteData}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default DaftarListTemplate;
