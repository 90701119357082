import React from "react";
import { Box, Button} from "@mui/material";
import Register from "../../Page/User/Register";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import Login from "../../Page/User/Login";
function HeaderBtnLogout() {
  const sidebarContext = useGlobal();
  const { setToOpenMasuk } = sidebarContext;
	return (
		<Box sx={{ display: "flex", gap: 3 }}>
			<Register/>
			<Login/>
			<Button
				onClick={()=>{setToOpenMasuk()}}
				variant="contained"
				color="tertiary"
				size="small"
				sx={{ borderRadius:'999px', textTransform: "none", fontWeight: "400", marginRight: "20px" }}
			>
				Masuk
			</Button>
		</Box>
	);
}

export default HeaderBtnLogout;
