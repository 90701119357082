import { ThemeProvider } from "@mui/material";
import Router from "./Router";
import { mainTheme } from "./Assets/Theme/mainTheme";
import GlobalContext from "./Helper/Context/GlobalContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./App.css";

function App() {
  return (
    <GlobalContext>
      <ThemeProvider theme={mainTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router />
        </LocalizationProvider>
      </ThemeProvider>
    </GlobalContext>
  );
}

export default App;
