import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { styleBoxModal } from "../../Helper/style/style";
function ModalRespose({ openResponse, setToCloseResponse, data }) {
  const history = useHistory();
  const Image = `../../Assets/Response/${data.pathImage}`;
  return (
    <Modal
      open={openResponse}
      onClose={() => {
        setToCloseResponse();
        if (data.pathButton) {
          history.push(`${data.pathButton}`);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleBoxModal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center ",
            width: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "center", maxWidth: "60%" }}
          >
            <img src={Image} alt={data.pathImage} width="250px" />
          </Box>
          {data.tittle && (
            <Typography
              textAlign="center"
              mt={3}
              variant="h5"
              component="div"
              color="secondary"
            >
              {data.tittle}
            </Typography>
          )}
          {data.massage && (
            <Typography textAlign="center" sx={{ m: 3 }} variant="p">
              {data.massage}
            </Typography>
          )}
          <Box>
            <Button
              onClick={() => {
                setToCloseResponse();
                if (data.pathButton) {
                  history.push(`${data.pathButton}`);
                }
              }}
              variant="contained"
              color="secondary"
              size="Large"
              sx={{ borderRadius: "99px" }}
            >
              {data.nameButton ? data.nameButton : "Tutup"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalRespose;
