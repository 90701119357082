import {Box,  Modal, Typography } from "@mui/material";
import React  from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Close } from "@mui/icons-material";

export default function DokuPayment({ open, closeModal, paymentUrl }) {
  const history = useHistory();

  
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") {
          return;
        }
      }}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "80%",
          minWidth: "330px",
          bgcolor: "background.paper",
          boxShadow: 10,
          borderRadius: "10px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "white",
            position: "absolute",
            right: "-10px",
            top: "-10px",
            border: "solid",
            borderRadius: "50px",
            borderColor: "primary.main",
          }}
        >
          <Typography
            sx={{ color: "primary.main" }}
            onClick={() => {
              closeModal();
              history.push("/pesan/keranjang");
            }}
          >
            <Close />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center ",
            width: "700px",
            maxWidth: "100%",
            height: "450px",
            maxHeight: "100%",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <iframe
            height="100%"
            width="100%"
            allow
            title="Doku Paymet"
            src={paymentUrl}
            allowFullScreen
            
          />

          {/* <Button
          onClick={() => {
            closeModal();
            history.push('/pesan/keranjang')
          }}
          variant="contained"
          color="secondary"
          size="Large"
          sx={{ borderRadius: "99px" }}
        >
          Tutup
        </Button> */}
        </Box>
      </Box>
    </Modal>
  );
}
