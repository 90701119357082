export const getWidth = (element) => {
  if (element) {
    const bbox = element.getBBox();
    return bbox.width;
  }
  return 0;
};

export const getTextAlign = (svgElementRef) => {
  if (svgElementRef.current) {
    const element = svgElementRef.current.querySelector("#pengirim");
    if (element) {
      const classElement = element.getAttribute("class");
      if (classElement.includes("center")) {
        return "center";
      } else if (classElement.includes("end")) {
        return "end";
      }
    }
  }
  return "";
};

export const getFontSize = (selector, svgElementRef) => {
  if (svgElementRef.current) {
    const element = svgElementRef.current.querySelector(selector);
    if (element) {
      const computedStyle = window.getComputedStyle(element);
      const initialFontSize = parseFloat(computedStyle.fontSize);
      return initialFontSize;
    }
  }
};

const getAlignCalculate = (align, XMatrix, elemenWidth) => {
  const WIDTH_LOGO = 210;
  const GAP_LOGO = 20;
  const SVG_WIDTH = 1920;
  if (align.includes("center")) {
    return (SVG_WIDTH - elemenWidth) / 2 - WIDTH_LOGO - GAP_LOGO;
  } else if (align.includes("end")) {
    return XMatrix - elemenWidth - GAP_LOGO - WIDTH_LOGO;
  } else {
    return XMatrix + elemenWidth + GAP_LOGO;
  }
};

const getTransformX = (
  senderXMatrix,
  detailXMatrix,
  senderWidth,
  senderDetailWidth,
  align
) => {
  if (senderWidth === 0 && senderDetailWidth === 0) {
    return getAlignCalculate(align, senderXMatrix, senderWidth);
  } else if (senderWidth > senderDetailWidth) {
    return getAlignCalculate(align, senderXMatrix, senderWidth);
  } else {
    return getAlignCalculate(align, detailXMatrix, senderDetailWidth);
  }
};

export const getMatrixTransform = (
  elementLogo,
  elementPengirim,
  elementDetail,
  senderWidth,
  senderDetailWidth
) => {
  const align = elementPengirim.getAttribute("class");
  const logoMatrix = elementLogo
    .getAttribute("transform")
    .match(/matrix\(([^)]+)\)/);
  const pengirimMatrix = elementPengirim
    .getAttribute("transform")
    .match(/matrix\(([^)]+)\)/);
  const detailMatrix = elementDetail
    .getAttribute("transform")
    .match(/matrix\(([^)]+)\)/);

  const logoValues = logoMatrix[1].split(" ").map(parseFloat);
  const pengirimValues = pengirimMatrix[1].split(" ").map(parseFloat);
  const detailValues = detailMatrix[1].split(" ").map(parseFloat);

  // Update the y value (5th element in the matrix)
  logoValues[4] = getTransformX(
    pengirimValues[4],
    detailValues[4],
    senderWidth,
    senderDetailWidth,
    align
  );

  // Set the new transform attribute
  const newTransform = `matrix(${logoValues.join(" ")})`;
  return newTransform;
};

export const getNewFontSize = (
  width,
  maxWidth,
  gapMinWidth,
  fontSize,
  minFontSize = 20,
  maxFontSize = 50
) => {
  let newFontSize = fontSize;
  if (width > maxWidth && newFontSize >= minFontSize) {
    return newFontSize - 1;
  } else if (width < maxWidth - gapMinWidth && newFontSize + 1 <= maxFontSize) {
    return newFontSize + 1;
  } else {
    return fontSize;
  }
};

export function splitStringBySpace(str) {
  if (str.includes("\n")) {
    return str;
  }
  if (str.length > 50) {
    const words = str.split(" ");
    if (words.length < 2) {
      return (
        str.substring(0, str.length / 2) +
        "- \n" +
        str.substring(str.length / 2)
      );
    } else {
      let result = "";
      let line = "";
      let isSplited = false;

      for (let word of words) {
        if ((line + word).length > str.length / 2 && !isSplited) {
          result += line.trim() + "\n";
          line = word + " ";
          isSplited = true;
        } else {
          line += word + " ";
        }
      }

      result += line.trim(); // Add the last line
      return result;
    }
  }
  return str;
}
