import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fade, FormControlLabel, Modal, Paper, Popper, Typography } from '@mui/material'
import React from 'react'
import TemplateCard from './TemplateCard'
import { useEffect } from 'react';
import { Close, Filter } from '@mui/icons-material';
import { useState } from 'react';

function TemplateModal({modal,setClose, data}) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "80%",
        minWidth:"330px",
        bgcolor: "background.paper",
        boxShadow: 10,
        p: 4,
        borderRadius: "10px",
        boxSizing:'border-box'
      };
      //=============================================================pooper
  const [openPop, setOpenPop] = React.useState(false);


  const handleClick = () => {
    setOpenPop(!openPop);
    if(openPop===false){
      setDataBahasa(initialBahasa);
      setDataReligi(initialReligi)
    }
  };

    //========================================data category
    const initialBahasa ={
      Indonesia: true,
      English: true,
      // Tambahkan lebih banyak bahasa di sini sesuai dengan data Anda
    }
    const initialReligi ={
      General: true,
      Kristen: true,
      Budha: true,
      Hindu: true,
      Islam: true
      // Tambahkan lebih banyak agama di sini sesuai dengan data Anda
    }
    const [dataBahasa, setDataBahasa] = useState(initialBahasa);
    
      const [dataReligi, setDataReligi] = useState(initialReligi);
    
      const [selectAllLanguages, setSelectAllLanguages] = useState(true);
      const [selectAllReligions, setSelectAllReligions] = useState(true);
    
      useEffect(() => {
        const allLanguagesChecked = Object.values(dataBahasa).every(
          (value) => value === true
        );
        const allReligionsChecked = Object.values(dataReligi).every(
          (value) => value === true
        );
    
        setSelectAllLanguages(allLanguagesChecked);
        setSelectAllReligions(allReligionsChecked);
    
      }, [dataBahasa, dataReligi]);
    
      const handleLanguageCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setDataBahasa({
          ...dataBahasa,
          [name]: checked,
        });
      };
    
      const handleReligionCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setDataReligi({
          ...dataReligi,
          [name]: checked,
        });
      };
    
      const handleSelectAllLanguagesChange = () => {
        const newValue = !selectAllLanguages;
        setSelectAllLanguages(newValue);
        const updatedFilters = {};
    
        Object.keys(dataBahasa).forEach((language) => {
          updatedFilters[language] = newValue;
        });
    
        setDataBahasa(updatedFilters);
      };
    
      const handleSelectAllReligionsChange = () => {
        const newValue = !selectAllReligions;
        setSelectAllReligions(newValue);
        const updatedFilters = {};
    
        Object.keys(dataReligi).forEach((religion) => {
          updatedFilters[religion] = newValue;
        });
    
        setDataReligi(updatedFilters);
      };
    
      const filteredData = data.filter(
        (item) =>
        (selectAllLanguages ||
          Object.keys(dataBahasa).some(
            (bahasa) => dataBahasa[bahasa] && item.bahasa === bahasa
          )) &&
        (selectAllReligions ||
          Object.keys(dataReligi).some(
            (religi) => dataReligi[religi] && item.religi === religi
          ))
          
      );
    
  return (
    <Box>
      <Modal
        open={openPop}
        onClose={() => {
          handleClick();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "25px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              backgroundColor: "white",
              position: "absolute",
              right: "-10px",
              top: "-10px",
              border: "solid",
              borderRadius: "50px",
              borderColor: "primary.main",
            }}
          >
            <Typography
              sx={{ color: "primary.main" }}
              onClick={() => {
                handleClick();
              }}
            >
              <Close />
            </Typography>
          </Box>
          <Typography>Pilih Bahasa</Typography>
          <FormControlLabel
            key="allBahasa"
            control={
              <Checkbox
                checked={selectAllLanguages}
                onChange={handleSelectAllLanguagesChange}
                name="SelectAllLanguages"
              />
            }
            label="All"
          />
          {Object.keys(dataBahasa).map((option) => (
            <FormControlLabel
              key={option}
              // value={option}
              control={
                <Checkbox
                  checked={dataBahasa[option]}
                  onChange={handleLanguageCheckboxChange}
                  name={option}
                />
              }
              label={option}
            />
          ))}
          <Typography>Pilih Religi</Typography>
          <FormControlLabel
            key="allReligion"
            control={
              <Checkbox
                checked={selectAllReligions}
                onChange={handleSelectAllReligionsChange}
                name="SelectAllReligions"
              />
            }
            label="All"
          />
          {Object.keys(dataReligi).map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={
                <Checkbox
                  checked={dataReligi[option]}
                  onChange={handleReligionCheckboxChange}
                  name={option}
                />
              }
              label={option}
            />
          ))}
        </Box>
      </Modal>
      <Dialog
        fullWidth
        maxWidth={'xl'}
        open={modal}
        onClose={setClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box
          color="white"
          sx={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "white",
            position: "absolute",
            right: "0px",
            top: "0px",
            border: "solid",
            borderRadius: "50px",
            borderColor: "primary.main",
          }}
        >
          <Typography
            sx={{ color: "primary.main" }}
            onClick={() => {
              setClose();
            }}
          >
            <Close />
          </Typography>
        </Box>
        <DialogTitle id="scroll-dialog-title">Daftar Template</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="ok"
            sx={{ color: "white", borderRadius: "100px" }}
            size="small"
            startIcon={<Filter />}
            onClick={() => {
              handleClick();
            }}
          >
            Filter
          </Button>
        </DialogActions>
        <DialogContent dividers>
          <TemplateCard data={filteredData} setClose={setClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default TemplateModal