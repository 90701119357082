import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import { SvgLoader, SvgProxy } from "react-svgmt";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";
import Avatar from "react-avatar-edit";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { Handshake, SaveAs } from "@mui/icons-material";
import jwt from "jwt-decode";
import TemplateModal from "../../Components/Template/TemplateModal";
import { useTheme } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import { useRef } from "react";
import { base64ToFile } from "../../Helper/image/imageFunction";
import { useDropzone } from "react-dropzone";

import { baseStyle } from "../../Helper/style/style";
//===============================================style dropzone

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
//========================================end dropzone style
//==================================================multiple select

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function SponsorAddForm() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //=========================================================authorize

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role != "super") {
      history.push("/");
    }
  };
  //=======================================dropzone handle svg
  const [SVGFile, setSVGFile] = useState();

  const {
    getRootProps: getRootSVGProps,
    getInputProps: getInputSVGProps,
    isFocused: isFocusedSVG,
    isDragAccept: isDragAcceptSVG,
    isDragReject: isDragRejectSVG,
    isDragActive: isDragActiveSVG,
    acceptedFiles: acceptedFilesSVG,
  } = useDropzone({ accept: { "image/svg+xml": [".svg"] } });

  const stylesvg = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedSVG ? focusedStyle : {}),
      ...(isDragAcceptSVG ? acceptStyle : {}),
      ...(isDragRejectSVG ? rejectStyle : {}),
    }),
    [isFocusedSVG, isDragAcceptSVG, isDragRejectSVG]
  );

  useEffect(() => {
    setSVGFile(acceptedFilesSVG[0]);
  }, [acceptedFilesSVG]);

  const hapusSvg = () => {
    setSVGFile();
  };

  //=======================================dropzone handle thumb
  const [file, setFile] = useState(null);
  const {
    getRootProps: getRootThumbProps,
    getInputProps: getInputThumbProps,
    isFocused: isFocusedThumb,
    isDragAccept: isDragAcceptThumb,
    isDragReject: isDragRejectThumb,
    isDragActive: isDragActiveThumb,
    acceptedFiles: acceptedFilesThumb,
  } = useDropzone({ accept: { "image/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedThumb ? focusedStyle : {}),
      ...(isDragAcceptThumb ? acceptStyle : {}),
      ...(isDragRejectThumb ? rejectStyle : {}),
    }),
    [isFocusedThumb, isDragAcceptThumb, isDragRejectThumb]
  );
  const createLink = () => {
    if (acceptedFilesThumb.length !== 0) {
      setFile(URL.createObjectURL(acceptedFilesThumb[0]));
    }
  };
  useEffect(() => {
    createLink();
  }, [acceptedFilesThumb]);

  //===============================================================submit file

  const submitFile = () => {
    setData({...data,SVGFile: SVGFile});
  }
  const handleReset = () => {
    setData({ ...data, SVGFile: "", path_custom_template:""});
    setFile(null);
  };
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //========================================handling input
  const initialState = {
    nama_sponsor: "",
    keterangan_pengirim: "",
    pesan: "",
    tanggal_berakhir: "",
    tanggal_mulai: "",
  };
  const [data, setData] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleInputChangePesan = (value) => {
    if (value.length >= 50 && !value.includes("\n")) {
      value = value.substring(0, 50) + "- \n" + value.substring(50);
    }

    const stringsArray = value.split("\n");

    setData({
      ...data,
      baris_1: stringsArray[0],
      baris_2: ".",
    });

    if (stringsArray[1]) {
      setData({
        ...data,
        baris_1: stringsArray[0],
        baris_2: stringsArray[1],
      });
    }
  };

  useEffect(() => {
    if (data.pesan) {
      handleInputChangePesan(data.pesan);
    }
  }, [data.pesan]);

  const [universal, setUniversal] = useState(false);
  const handleChangeUniversal = (event) => {
    setUniversal(event.target.checked);
  };

  const [customTemplate, setCustomTemplate] = useState(false);
  const handleChangeTemplate = (event) => {
    setCustomTemplate(event.target.checked);
  };
  //========================================logo input
  const [logo, setLogo] = useState("");
  const cropAvatar = (view) => {
    setLogo(view);
  };
    //DELETE
    const [openKota, setOpenKota] = useState(false);

    const toggleKota = () => {
      if(!universal){
        setOpenKota(!openKota);
      }
    };
  
    const handleDeleteKota = (pk_id_kota) => {
      setOpenKota(false);
      setSelectedKota(selectedKota.filter((kota) => kota !== pk_id_kota));
    };
  
    //DELETE
    const [openRD, setOpenRD] = useState(false);
  
    const toggleRD = () => {
      if(!universal){
        setOpenRD(!openRD);
      }
    };
  
    const handleDeleteRD = (pk_id_rd) => {
      setOpenRD(false);
      setSelectedRD(selectedRD.filter((rd) => rd !== pk_id_rd));
    };
  
    //DELETE
    const [openRuangan, setOpenRuangan] = useState(false);
  
    const toggleRuangan = () => {
      if(!universal){
        setOpenRuangan(!openRuangan);
      }
    };
  
    const handleDeleteRuangan = (pk_id_ruangan) => {
      setOpenRuangan(false);
      setSelectedRuangan(
        selectedRuangan.filter((ruangan) => ruangan !== pk_id_ruangan)
      );
    };

  //=============================================templates
  const [modal, setModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const closeModal = () => {
    setModal(false);
    const path = sessionStorage.getItem("selected_template");
    if (path) {
      setSelectedTemplate(
        dataTemplate.filter((item) => item.path_image_template === path)[0]
      );
      getSvg(path);
    }
  };
  const openModal = () => {
    setModal(true);
    sessionStorage.removeItem("selected_template");
  };
  const [dataTemplate, setDataTemplate] = useState([]);

  const getDaftarTemplate = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/template/template";
    try {
      const response = await axios.get(url);
      setDataTemplate(
        response.data.map((e) => {
          return {
            nama_template: e.nama_template,
            pk_id_template: e.pk_id_template,
            deskripsi: e.deskripsi,
            path_thumb_template: e.path_thumb_template,
            path_image_template: e.path_image_template,
            bahasa: e.bahasa,
            religi: e.religi,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  //=======================================================================get data template

  const [SVG, setSVG] = useState();
  const svgRef = useRef(null);

  const getSvg = (path) => {
    const svgUrl =
      process.env.REACT_APP_BASE_URL + "api/Template/file?path_image=" + path;
    try {
      axios.get(svgUrl).then((response) => {
        setSVG(response.data);
      });
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  //========================================get rd, Kota, ruangan

  const [dataRD, setDataRD] = useState([]);

  const getDaftarRD = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/RumahDuka/RumahDuka";
    try {
      const response = await axios.get(url);
      setDataRD(
        response.data.map((e) => {
          return {
            pk_id_rd: e.pk_id_rd,
            nama_rd: e.nama_rd,
            alamat: e.alamat,
            commission: e.commission,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  const [dataKota, setDataKota] = useState([]);

  const GetKota = async (value) => {
    const url = process.env.REACT_APP_BASE_URL + "api/Kota/Kota";

    try {
      const response = await axios.get(url);
      setDataKota(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data kota, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  const [dataRuangan, setDataRuangan] = useState([]);

  const GetRuangan = async (value) => {
    const url = process.env.REACT_APP_BASE_URL + "api/Ruangan/Ruangan";

    try {
      const response = await axios.get(url);
      setDataRuangan(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data kota, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  useEffect(() => {
    GetKota();
    getDaftarRD();
    getDaftarTemplate();
    GetRuangan();
    auth();
  }, []);
  //=========================================================multiple select

  const theme = useTheme();
  const [selectedKota, setSelectedKota] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedKota(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [selectedRD, setSelectedRD] = React.useState([]);

  const handleChangeRD = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRD(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [selectedRuangan, setSelectedRuangan] = React.useState([]);

  const handleChangeRuangan = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRuangan(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  //==============================================================validation

  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    const validationErrors = {};
    if (!logo) {
      validationErrors.logo = "Masukan Logo Sponsor";
    }
    if (!data.nama_sponsor) {
      validationErrors.nama_sponsor = "Masukan Nama Sponsor";
    }
    if (!data.tanggal_berakhir) {
      validationErrors.tanggal_berakhir = "Masukan tanggal akhir sponsor";
    }
    if (!data.tanggal_mulai) {
      validationErrors.tanggal_mulai = "Masukan tanggal mulai";
    }
    if(customTemplate){
      if (!data.SVGFile) {
        validationErrors.fk_id_template = "Pilih Template";
      }
    }else{
      if (!selectedTemplate) {
        validationErrors.fk_id_template = "Pilih Template";
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      setToOpenKonfirm();
    }
  };
  //========================================handling submit
  useEffect(() => {
    if (dataKonfirm === true) {
      handleSubmit();
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm(null);
  }, [dataKonfirm]);

  //=======================================================================handle font zixe
  useEffect(() => {
    getFontSize();
  }, [data.nama_sponsor]);

  const [sponsorFontSize, setSponsorFontSize] = useState("94px");
  const getFontSize = () => {
    if (data.nama_sponsor.length >= 22 && data.nama_sponsor.length <= 32) {
      setSponsorFontSize("70px");
    } else if (
      data.nama_sponsor.length >= 33 &&
      data.nama_sponsor.length <= 40
    ) {
      setSponsorFontSize("54px");
    } else {
      setSponsorFontSize("94px");
    }
  };
  const param = useParams();
  //===================================================handle submit
  const handleSubmit = async () => {
    const avatar = base64ToFile(logo);
    const formData = new FormData();

    if (SVGFile) {
      formData.append("custom_template", SVGFile);
    } 
    if (acceptedFilesThumb[0]) {
      formData.append("thumb_custom_template", acceptedFilesThumb[0]);
    } 
    if (selectedTemplate) {
      formData.append("fk_id_template", selectedTemplate.pk_id_template);
    } 


    formData.append("avatar", avatar, `${data.nama_sponsor}.jpg`);
    formData.append("nama_sponsor", data.nama_sponsor);
    formData.append("tanggal_berakhir", data.tanggal_berakhir);
    formData.append("tanggal_mulai", data.tanggal_mulai);
    formData.append("universal", universal);
    formData.append("pk_id_user", param.id);
    formData.append("is_use_custom", customTemplate);
    if (!universal) {
      if (selectedKota.length > 0) {
        selectedKota.forEach((id) => {
          formData.append("sponsorKota", id);
        });
      }
      if (selectedRD.length > 0) {
        selectedRD.forEach((id) => {
          formData.append("sponsorRD", id);
        });
      }
      if (selectedRuangan.length > 0) {
        selectedRuangan.forEach((id) => {
          formData.append("sponsorRuangan", id);
        });
      }
    }
    if (data.pesan) {
      formData.append("pesan", data.pesan);
    }
    if (data.keterangan_pengirim) {
      formData.append("keterangan_pengirim", data.keterangan_pengirim);
    }

    const url = process.env.REACT_APP_BASE_URL + "api/sponsor/sponsor";
    await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        history.push("/users");
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat tambah sponsor, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" textAlign="center" my={4}>
            Form Tambah Sponsor
          </Typography>

          <ErrorBox />
          <Typography variant="h5" textAlign="center" my={2}>
            Info Pesan
          </Typography>
          <Avatar width={150} height={150} onCrop={cropAvatar} />
          <Typography>Logo Sponsor</Typography>
          <FormHelperText error>{errors.logo}</FormHelperText>
          <TextField
            fullWidth
            required
            size="small"
            id="nama_sponsor"
            name="nama_sponsor"
            onChange={handleInputChange}
            label="Masukan nama sponsor"
            variant="outlined"
            sx={{ mt: 3 }}
            error={!!errors.nama_sponsor}
            helperText={errors.nama_sponsor}
            inputProps={{
              maxLength: 40, // Set the maximum character limit
            }}
          />
          <TextField
            fullWidth
            required
            size="small"
            id="keterangan_pengirim"
            name="keterangan_pengirim"
            onChange={handleInputChange}
            label="Masukan keterangan pengirim pesan"
            variant="outlined"
            sx={{ mt: 3 }}
            error={!!errors.keterangan_pengirim}
            helperText={errors.keterangan_pengirim}
            inputProps={{
              maxLength: 30, // Set the maximum character limit
            }}
          />

          <TextField
            fullWidth
            required
            multiline
            size="small"
            id="pesan"
            name="pesan"
            onChange={handleInputChange}
            label="Masukan pesan"
            variant="outlined"
            sx={{ mt: 3 }}
            error={!!errors.pesan}
            helperText={errors.pesan}
            inputProps={{
              maxLength: 100, // Set the maximum character limit
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
            <Typography>Gunakan Template Custom</Typography>
            <Switch
              checked={customTemplate}
              onChange={handleChangeTemplate}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <FormHelperText error>{errors.fk_id_template}</FormHelperText>
          {customTemplate ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Typography my={2}>Template Custom</Typography>
              {data.SVGFile || data.path_custom_template ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img width={"80%"} src={file} alt="uploaded file" />
                  <Button variant="contained"
                        color="error"
                        sx={{ borderRadius: "100px", color: "white", mt: 3 }}
                        onClick={handleReset}>
                    Batal
                  </Button>
                </Box>
              ):(
              <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5">Upload Thumbnail</Typography>
                <Typography mb={5}>
                  Eksistensi file jpg/png dengan maks 200kb
                </Typography>
                {file && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img width={"80%"} src={file} alt="uploaded file" />
                  </Box>
                )}
                <Box my={3} />
                <Box {...getRootThumbProps({ style })} className="dropzone">
                  <input type="file" {...getInputThumbProps()} />
                  {isDragAcceptThumb && <p>All files will be accepted</p>}
                  {isDragRejectThumb && <p>Some files will be rejected</p>}
                  {isDragActiveThumb
                    ? "Drop the files here ..."
                    : "Drag 'n' drop some files here, or click to select files"}
                </Box>
                <FormHelperText error>
                  {errors.path_thumb_template}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5">Upload SVG File</Typography>
                <Typography mb={5}>
                  Pastikan kode SVG telah diberikan id untuk editor
                </Typography>
                {SVGFile ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>{SVGFile.name}</Typography>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ borderRadius: "100px", color: "white" }}
                      onClick={() => {
                        hapusSvg();
                      }}
                    >
                      Batal
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "20px",
                        borderWidth: 2,
                        borderRadius: 2,
                        borderColor: "#eeeeee",
                        borderStyle: "dashed",
                        backgroundColor: "#fafafa",
                        color: "#bdbdbd",
                        outline: "none",
                        transition: "border .24s ease-in-out",
                        width: "100%",
                        mt: 5,
                      }}
                      {...getRootSVGProps({ stylesvg })}
                    >
                      <input {...getInputSVGProps()} />
                      {isDragAcceptSVG && <p>File will be accepted</p>}
                      {isDragRejectSVG && <p>File will be rejected</p>}
                      {isDragActiveSVG
                        ? "Drop the files here ..."
                        : "Drag 'n' drop some files here, or click to select files"}
                    </Box>
                    <FormHelperText error>
                      {errors.path_image_template}
                    </FormHelperText>
                  </Box>
                )}

                <Button
                  endIcon={<SaveAs />}
                  loadingPosition="end"
                  variant="contained"
                  sx={{ borderRadius: "100px", color: "white", mt: 4 }}
                  onClick={() => {
                    submitFile();
                  }}
                >
                  Simpan
                </Button>
              </Grid>
            </Grid>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%"
              }}
            >
              <Typography my={2}>Template Default</Typography>
              {SVG && (
                <SvgLoader svgXML={SVG} ref={svgRef} id="svg">
                  <SvgProxy selector="#pengirim" font-size={sponsorFontSize}>
                    {data.nama_sponsor}
                  </SvgProxy>
                  <SvgProxy selector="#keterangan_pengirim">
                    {data.keterangan_pengirim}
                  </SvgProxy>
                  <SvgProxy selector="#logo" href={logo} />
                  <SvgProxy selector="#baris_1">{data.baris_1}</SvgProxy>
                  <SvgProxy selector="#baris_2">{data.baris_2}</SvgProxy>
                </SvgLoader>
              )}

              {selectedTemplate ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    onClick={() => {
                      setSelectedTemplate(null);
                      setSVG();
                      openModal();
                    }}
                    variant="contained"
                    size="small"
                    sx={{
                      color: "white",
                      borderRadius: "999px",
                      textTransform: "none",
                    }}
                  >
                    Ubah
                  </Button>
                </Box>
              ) : (
                <Button
                  onClick={() => {
                    openModal();
                  }}
                  variant="contained"
                  size="small"
                  sx={{
                    color: "white",
                    borderRadius: "999px",
                    textTransform: "none",
                  }}
                >
                  Pilih Template
                </Button>
              )}
            </Box>
          )}

         

          <Typography variant="h5" textAlign="center" my={2}>
            Wilayah Sponsor
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
            <Typography>Universal</Typography>
            <Switch
              checked={universal}
              onChange={handleChangeUniversal}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>

          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel id="demo-multiple-chip-label">Sponsor Kota</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              disabled={universal}
              value={selectedKota}
              onChange={handleChange}
              open={openKota}
              onClick={toggleKota}
              input={
                <OutlinedInput id="select-multiple-chip" label="Sponsor Kota" />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {dataKota
                    .filter((item) => selected.includes(item.pk_id_kota))
                    .map((value) => (
                      <Chip key={value.nama_kota} label={value.nama_kota} onDelete={() => {
                          handleDeleteKota(value.pk_id_kota);
                        }}/>
                    ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {dataKota.map((kota) => (
                <MenuItem
                  key={kota.pk_id_kota}
                  value={kota.pk_id_kota}
                  style={getStyles(kota.nama_kota, dataKota, theme)}
                >
                  {kota.nama_kota}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel id="demo-multiple-chip-label">
              Sponsor Rumah Duka
            </InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              disabled={universal}
              value={selectedRD}
              onChange={handleChangeRD}
              open={openRD}
              onClick={toggleRD}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Sponsor Rumah Duka"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {dataRD
                    .filter((item) => selected.includes(item.pk_id_rd))
                    .map((value) => (
                      <Chip key={value.nama_rd} label={value.nama_rd} onDelete={() => {
                          handleDeleteRD(value.pk_id_rd);
                        }}/>
                    ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {dataRD.map((RD) => (
                <MenuItem
                  key={RD.pk_id_rd}
                  value={RD.pk_id_rd}
                  style={getStyles(RD.nama_rd, dataRD, theme)}
                >
                  {RD.nama_rd}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel id="demo-multiple-chip-label">
              Sponsor Ruangan
            </InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              disabled={universal}
              value={selectedRuangan}
              onChange={handleChangeRuangan}
              open={openRuangan}
              onClick={toggleRuangan}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Sponsor Ruangan"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {dataRuangan
                    .filter((item) => selected.includes(item.pk_id_ruangan))
                    .map((value) => (
                      <Chip
                        key={value.nama_ruangan}
                        label={"(" + value.nama_rd + ") " + value.nama_ruangan}
                        onDelete={() => {
                          handleDeleteRuangan(value.pk_id_ruangan);
                        }}
                      />
                    ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {dataRuangan.map((Ruangan) => (
                <MenuItem
                  key={Ruangan.pk_id_ruangan}
                  value={Ruangan.pk_id_ruangan}
                  style={getStyles(Ruangan.nama_ruangan, dataRuangan, theme)}
                >
                  {"(" + Ruangan.nama_rd + ") " + Ruangan.nama_ruangan}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h5" textAlign="center" my={2}>
            Tanggal Berlaku
          </Typography>
          <Grid container spacing={2}>
            <Grid key="tanggal_mulai" item xs={12} md={6}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                id="tanggal_mulai"
                label="Mulai pada"
                format="DD MMM YYYY"
                onChange={(e) =>
                  setData({
                    ...data,
                    tanggal_mulai: e.format("YYYY/MM/DD"),
                  })
                }
                error={!!errors.tanggal_mulai}
              />
              <FormHelperText error>{errors.tanggal_mulai}</FormHelperText>
            </Grid>
            <Grid key="tanggal_berakhir" item xs={12} md={6}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                id="tanggal_berakhir"
                label="Berakhir pada"
                format="DD MMM YYYY"
                onChange={(e) =>
                  setData({
                    ...data,
                    tanggal_berakhir: e.format("YYYY/MM/DD"),
                  })
                }
                error={!!errors.tanggal_berakhir}
              />
              <FormHelperText error>{errors.tanggal_berakhir}</FormHelperText>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              mt: 5,
            }}
          >
            <LoadingButton
              endIcon={<Handshake />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                handleLoading();
                checkValidate();
              }}
              variant="contained"
              sx={{
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                mt: 3,
              }}
            >
              Simpan
            </LoadingButton>
          </Box>
        </Box>
      </Box>

      <TemplateModal data={dataTemplate} setClose={closeModal} modal={modal} />
    </Layout>
  );
}

export default SponsorAddForm;
