export function base64ToFile(base64String) {
    const j = base64String.indexOf("base64,");
    const byteCharacters = atob(base64String.slice(j + 7)); // Decode the Base64 string
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    const file = new Blob([byteArray], { type: "image/png" });

    return file;
  }