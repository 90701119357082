import React from "react";
import Layout from "../../Components/Layout";
import {
  Box,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Sidebar from "../../Components/Sidebar";
import Paper from "@mui/material/Paper";
import "../../Helper/format/currency";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";
import { useState } from "react";
import { ConfirmationNumber, Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import DokuPayment from "../../Components/Modal/DokuPayment";

function subtotal(items) {
  return items.map(({ harga }) => harga).reduce((sum, i) => sum + i, 0);
}

function PaymentPage() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    user,
    dataPesan,
    setToOpenResponse,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //==================================================Agent Saldo
  const [saldo, setSaldo] = useState();
  const getSaldo = async () => {
    const url =
      process.env.REACT_APP_BASE_URL +
      `api/agent/agent/saldo/${user.fk_id_agent}`;

    try {
      const response = await axios.get(url);
      setSaldo(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer("Terjadi error saat mengirim pesan, Mohon  coba lagi");
      }
      openErrorBox();
    }
  };

  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  const [loadingStsVoucher, setLoadingStsVoucher] = useState(false);
  function handleLoadingVoucher() {
    setLoadingStsVoucher(true);
  }
  function handleCloseLoadingVoucher() {
    setLoadingStsVoucher(false);
  }

  //=========================================================authorize

  const history = useHistory();

  const auth = () => {
    if (!loginToken) {
      history.push("/");
    }

    if (!dataPesan) {
      history.push("/template/selected-template");
    } else {
      const detail = [{ deskripsi: "Ucapan Duka", harga: dataPesan.harga }];
      if (user.discount > 0) {
        const disc = ((dataPesan.harga * user.discount) / 100) * -1;
        detail.push({ deskripsi: "Discount Agent", harga: disc });
      }
      detail.push({ deskripsi: "Discount Lain-Lain", harga: dataPesan.disc });
      setRows(detail);
      setInitialRows(detail);
    }
  };
  //=========================================================copy
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    const textField = document.createElement("textarea");
    textField.innerText = 7612270123;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    document.body.removeChild(textField);
    setCopied(true);
  };
  //=========================================  Handling Error Submit
  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const invoiceSubtotal = subtotal(rows);

  //=======================================================================Check Voucher
  const [kodeVoucher, setKodeVoucher] = useState();

  const CheckVoucher = async () => {
    const url =
      process.env.REACT_APP_BASE_URL + "api/voucher/voucher/" + kodeVoucher;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${loginToken}`,
        },
      });
      if (response.data.jenis_potongan) {
        let harga;
        if (response.data.jenis_potongan === "persentase") {
          harga = (dataPesan.harga * -1 * response.data.nominal) / 100;
        }
        if (response.data.jenis_potongan === "rupiah") {
          harga = response.data.nominal * -1;
        }

        if (harga < 0) {
          const addVoucher = [
            ...initialRows,
            { deskripsi: response.data.nama_voucher, harga: harga },
          ];
          setRows(addVoucher);
        }
      } else {
        setErrorServer("Voucher tidak tersedia");

        openErrorBox();
      }
      handleCloseLoadingVoucher();
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data voucher, Mohon  coba lagi"
        );
      }
      openErrorBox();
      handleCloseLoadingVoucher();
    }
  };
  //===============================================Doku Payment
  const [dokuPaymentUrl, setDokuPaymentUrl] = useState("");
  const [open, setOpen] = useState(false);
  const ClosePayment = () => {
    setOpen(false);
  };
  //=============================================Confirm
  useEffect(() => {
    if (dataKonfirm === true) {
      if (dataPesan) {
        Confim();
      }
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm();
  }, [dataKonfirm]);

  const Confim = async () => {
    const formData = new FormData();
    formData.append("fk_id_user", dataPesan.fk_id_user);
    formData.append("fk_id_mendiang", dataPesan.fk_id_mendiang);
    formData.append("disc", dataPesan.disc);
    formData.append("status_pembayaran", true);
    formData.append("kode_voucher", kodeVoucher);
    if (dataPesan.uploaded_ucapan) {
      formData.append(
        "uploaded_ucapan",
        dataPesan.uploaded_ucapan,
        `_${dataPesan.pk_id_mendiang}.jpg`
      );
    }
    if (dataPesan.pesan_user.pengirim) {
      formData.append("pesan_user.pengirim", dataPesan.pesan_user.pengirim);
    }
    if (dataPesan.pesan_user.fk_id_template) {
      formData.append(
        "pesan_user.fk_id_template",
        dataPesan.pesan_user.fk_id_template
      );
    }
    if (dataPesan.pesan_user.keterangan_pengirim) {
      formData.append(
        "pesan_user.keterangan_pengirim",
        dataPesan.pesan_user.keterangan_pengirim
      );
    }
    if (dataPesan.pesan_user.keterangan_penerima) {
      formData.append(
        "pesan_user.keterangan_penerima",
        dataPesan.pesan_user.keterangan_penerima
      );
    }
    if (dataPesan.pesan_user.pesan) {
      formData.append("pesan_user.pesan", dataPesan.pesan_user.pesan);
    }
    if (dataPesan.pesan_user.logo) {
      formData.append(
        "pesan_user.logo",
        dataPesan.pesan_user.logo,
        `_${dataPesan.pk_id_mendiang}.jpg`
      );
    }

    const url = process.env.REACT_APP_BASE_URL + "api/Keranjang/keranjang";
    await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then((response) => {
        if (user.fk_id_agent > 0) {
          setToOpenResponse({
            pathImage: "Pesan.png",
            tittle: "Pesan Ditayangkan",
            massage:
              "Terima Kasih atas pembayarannya, Silahkan cek pesan pada tab pesanku",
            pathButton: "/pesan/pesanku",
            nameButton: "Pesanku",
          });
        } else {
          setOpen(true);
          setDokuPaymentUrl(response.data);
          // setToOpenResponse({
          //   pathImage: "Pesan.png",
          //   tittle: "Pesan Tersimpan",
          //   massage:
          //     "Silahkan lakukan pembayaran dan konfirmasi sebelum waktu yang telah ditentukan",
          //   pathButton: "/pesan/keranjang",
          //   nameButton: "Keranjang",
          // });
        }
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat mengirim pesan, Mohon  coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };

  useEffect(() => {
    auth();
    getSaldo();
  }, []);

  return (
    <Layout>
      <Sidebar />
      {dokuPaymentUrl && (
        <DokuPayment
          open={open}
          closeModal={ClosePayment}
          paymentUrl={dokuPaymentUrl}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />

          <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
            Payment
          </Typography>
          {saldo > 0 ? (
            <Typography textAlign="center">
              Pembayaran dilakukan dengan pengurangan saldo, Saldo Tersedia{" "}
              {parseInt(saldo).currency()}
            </Typography>
          ) : null}

          <TableContainer sx={{ my: 3 }} component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell>Keterangan</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">Harga</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.deskripsi}>
                    <TableCell>{row.deskripsi}</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      {parseInt(row.harga).currency()}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell>Total</TableCell>
                  <TableCell align="right">
                    {invoiceSubtotal < 0
                      ? parseInt(0).currency()
                      : parseInt(invoiceSubtotal).currency()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {!user.discount && (
            <Box sx={{ display: "flex", my: 2, width: "100%", gap: 3 }}>
              <TextField
                fullWidth
                required
                size="small"
                id="kode_voucher"
                name="kode_voucher"
                onChange={(e) => {
                  setKodeVoucher(e.target.value);
                }}
                label="Masukan kode voucher"
                variant="outlined"
              />

              <LoadingButton
                endIcon={<ConfirmationNumber />}
                // disabled={disabled}
                loading={loadingStsVoucher}
                loadingPosition="end"
                onClick={() => {
                  CheckVoucher();
                  handleLoadingVoucher();
                }}
                variant="contained"
                sx={{
                  px: 4,
                  borderRadius: "999px",
                  textTransform: "none",
                  fontWeight: "400",
                  marginRight: "20px",
                  color: "white",
                  whiteSpace: "nowrap",
                }}
              >
                Cek Voucher
              </LoadingButton>
            </Box>
          )}
          {saldo > 0 ? (
            <Typography textAlign="center">
              Saldo tersisa jika konfirmasi pembayaran :{" "}
              {parseInt(saldo - invoiceSubtotal).currency()}
            </Typography>
          ) : null}
          <LoadingButton
            endIcon={<Send />}
            // disabled={disabled}
            loading={loadingSts}
            loadingPosition="end"
            onClick={() => {
              handleLoading();
              setToOpenKonfirm();
            }}
            variant="contained"
            sx={{
              borderRadius: "999px",
              textTransform: "none",
              fontWeight: "400",
              marginRight: "20px",
              color: "white",
              mt: 3,
            }}
          >
            Konfirmasi
          </LoadingButton>
        </Box>
      </Box>
      <Snackbar
        open={copied}
        autoHideDuration={3000}
        onClose={() => {
          setCopied(false);
        }}
        message="Copied to clipboard"
      />
    </Layout>
  );
}

export default PaymentPage;
