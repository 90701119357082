import React from "react";
import { Typography, Button, Box } from "@mui/material";
import NotFoundImage from "../Assets/Response/Error.png"; // Ganti dengan path gambar Anda

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <img src={NotFoundImage} alt="Not Found" width="30%" />
      <Typography variant="h3" gutterBottom>
        404 - Not Found
      </Typography>
      <Typography variant="body1" gutterBottom>
        The page you are looking for does not exist.
      </Typography>
      {/* Contoh tombol kembali ke halaman utama */}
      <Button
        variant="contained"
        sx={{ borderRadius: "100px", color: "white" }}
        color="primary"
        href="/"
      >
        Kembali Ke Beranda
      </Button>
    </Box>
  );
};

export default NotFound;
