import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Typography,
  Fade,
  Grid,
  Popper,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import axios from "axios";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import "../../Helper/format/currency";
import { Sort } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import ErrorBox from "../../Components/ErrorBox";
import {

  StyledTableCell,
  StyledTableRow,
} from "../../Helper/style/style";

function Komisi() {
  //==================================================Context
  const masukContext = useGlobal();
  const { loginToken, user, openErrorBox, setErrorServer } = masukContext;
  //=========================================================authorize
  const history = useHistory();

  const auth = () => {
    if (!loginToken) {
      history.push("/");
    }
  };
  //==============================================================Get Data
  const [data, setData] = useState([]);
  //Get all Rumah Duka
  const getKomisi = async (/*userID*/) => {
    try {
      const url = process.env.REACT_APP_BASE_URL + `api/Komisi/komisi`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
      setData(
        response.data.map((e) => {
          return {
            nama_rd: e.nama_rd,
            fk_id_rd: e.fk_id_rd,
            pk_id_pk: e.pk_id_pk,
            jumlah_pesan: e.jumlah_pesan,
            total_pembayaran: e.total_pembayaran,
            tanggal_pk: e.tanggal_pk,
          };
        })
      );
      setSearchData(
        response.data.map((e) => {
          return {
            nama_rd: e.nama_rd,
            fk_id_rd: e.fk_id_rd,
            pk_id_pk: e.pk_id_pk,
            jumlah_pesan: e.jumlah_pesan,
            total_pembayaran: e.total_pembayaran,
            tanggal_pk: e.tanggal_pk,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data komisi, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  //Get Rumah Duka
  const getKomisiRD = async (fk_id_rd) => {
    try {
      const url =
        process.env.REACT_APP_BASE_URL + `api/Komisi/komisi/rd/${fk_id_rd}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
      setData(
        response.data.map((e) => {
          return {
            nama_rd: e.nama_rd,
            fk_id_rd: e.fk_id_rd,
            pk_id_pk: e.pk_id_pk,
            jumlah_pesan: e.jumlah_pesan,
            total_pembayaran: e.total_pembayaran,
            tanggal_pk: e.tanggal_pk,
          };
        })
      );
      setSearchData(
        response.data.map((e) => {
          return {
            nama_rd: e.nama_rd,
            fk_id_rd: e.fk_id_rd,
            pk_id_pk: e.pk_id_pk,
            jumlah_pesan: e.jumlah_pesan,
            total_pembayaran: e.total_pembayaran,
            tanggal_pk: e.tanggal_pk,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data komisi, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  useEffect(() => {
    if (user.role === "super") {
      getKomisi(/*usrid*/);
    }
    if (user.pk_id_rd > 0) {
      getKomisiRD(user.fk_id_rd);
    }
    auth();
  }, [user]);

  const [selectedRD, setselectedRD] = useState();
  const [detail, setDetail] = useState([]);

  // Get Data Commission from Selected Rumah Duka
  const getDetail = async (fk_id_pk, fk_id_rd) => {
    const url =
      process.env.REACT_APP_BASE_URL + `api/komisi/komisi/detail/${fk_id_pk}`;
    const url2 =
      process.env.REACT_APP_BASE_URL +
      `api/RumahDuka/RumahDukaById?id=${fk_id_rd}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
      const response2 = await axios.get(url2, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
      setDetail(
        response.data.map((e) => {
          return {
            nama_mendiang: e.nama_mendiang,
            username_pengirim: e.username_pengirim,
            pk_id_pesan: e.pk_id_pesan,
            fk_id_user: e.fk_id_user,
            kode_invoice: e.kode_invoice,
            no_urut: e.no_urut,
            image_pesan_path: e.image_pesan_path,
            takedown: e.takedown,
            tanggal_pembayaran: e.tanggal_pembayaran,
            harga: e.harga,
            fk_id_pk: e.fk_id_pk,
          };
        })
      );
      setselectedRD(response2.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data detail, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  //================================================================view modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const Date_ = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  //=============================================================pooper
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPop, setOpenPop] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPop((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  // ===================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState({
    tanggal_awal: "",
    tanggal_akhir: "",
  });
  function searchFunction() {
    return data.filter((searchText) => {
      const pesanDate = new Date(searchText.tanggal_pk);
      let filteredText;
      if (inputSearchData.tanggal_awal && !inputSearchData.tanggal_akhir) {
        filteredText = pesanDate >= new Date(inputSearchData.tanggal_awal);
      }
      if (!inputSearchData.tanggal_awal && inputSearchData.tanggal_akhir) {
        filteredText = pesanDate <= new Date(inputSearchData.tanggal_akhir);
      }
      if (inputSearchData.tanggal_awal && inputSearchData.tanggal_akhir) {
        filteredText =
          pesanDate >= new Date(inputSearchData.tanggal_awal) &&
          pesanDate <= new Date(inputSearchData.tanggal_akhir);
      }
      return filteredText;
    });
  }

  useEffect(() => {
    if (inputSearchData.tanggal_akhir && inputSearchData.tanggal_awal) {
      setSearchData(searchFunction());
    }
  }, [inputSearchData]);

  return (
    <Layout>
      <Sidebar />
      <Popper
        open={openPop}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ p: 2 }}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                id="tanggal_awal"
                label="Tanggal Awal"
                format="DD MMM YYYY"
                onChange={(e) =>
                  setInputSearchData({
                    ...inputSearchData,
                    tanggal_awal: e.format("YYYY/MM/DD"),
                  })
                }
              />
              <Box mt={2} />
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                id="tanggal_akhir"
                label="Tanggal Akhir"
                format="DD MMM YYYY"
                onChange={(e) =>
                  setInputSearchData({
                    ...inputSearchData,
                    tanggal_akhir: e.format("YYYY/MM/DD"),
                  })
                }
              />
            </Paper>
          </Fade>
        )}
      </Popper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 5,
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" mr={2}>
                  Daftar Komisi
                </Typography>
              </Grid>
            </Grid>
            <Box>
              <Button
                variant="contained"
                color="ok"
                sx={{
                  color: "white",
                  borderRadius: "100px",
                  whiteSpace: "nowrap",
                }}
                size="small"
                startIcon={<Sort />}
                onClick={handleClick("bottom-end")}
              >
                Pilih Periode
              </Button>
            </Box>
          </Box>
          <TableContainer sx={{ my: 5 }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell align="center">
                    Nama Rumah Duka
                  </StyledTableCell>
                  <StyledTableCell align="center">Tanggal</StyledTableCell>
                  <StyledTableCell align="center">Jumlah Pesan</StyledTableCell>
                  <StyledTableCell align="center">Total Komisi</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row) => (
                  <StyledTableRow key={row.pk_id_pk}>
                    <StyledTableCell component="th" scope="row">
                      {row.pk_id_pk}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.nama_rd}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {new Date(row.tanggal_pk).toLocaleString(
                        undefined,
                        Date_
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.jumlah_pesan}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.total_pembayaran.currency()}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        color="ok"
                        variant="contained"
                        size="small"
                        sx={{ m: 1, color: "white" }}
                        onClick={() => {
                          handleOpen();
                          getDetail(row.pk_id_pk, row.fk_id_rd);
                        }}
                      >
                        Detail
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
          <DialogTitle id="scroll-dialog-title">
            <Typography variant="h5" mr={2}>
              Detail Komisi
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <TableContainer sx={{ my: 5 }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ID Invoice</StyledTableCell>
                    <StyledTableCell align="center">Tanggal</StyledTableCell>
                    <StyledTableCell align="center">Pengirim</StyledTableCell>
                    <StyledTableCell align="center">Harga</StyledTableCell>
                    <StyledTableCell align="center">Komisi</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detail.map((row) => (
                    <StyledTableRow key={row.pk_id_pesan}>
                      <StyledTableCell component="th" scope="row">
                        {row.kode_invoice + " - " + row.no_urut}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.tanggal_pembayaran).toLocaleString(
                          undefined,
                          Date_
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.username_pengirim}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.harga.currency()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {Math.round(
                          (row.harga / 100) * selectedRD.commission
                        ).currency()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
      </Dialog>
    </Layout>
  );
}

export default Komisi;
