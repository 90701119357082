import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";
import { Close, Person, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { styleBoxModal } from "../../Helper/style/style";

function Register() {
  //==================================================modal
  const registerContext = useGlobal();
  const {
    openRegister,
    setToCloseRegister,
    setToOpenMasuk,
    setToOpenResponse,
    openErrorBox,
    setErrorServer,
  } = registerContext;

  //========================================To Login
  const toLogin = () => {
    setToCloseRegister();
    setToOpenMasuk();
  };
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //========================================handling input

  const initialState = {
    username: "",
    email: "",
    tlp: "",
    password: "",
    konfirmasi_password: "",
  };
  const [data, setData] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };
  //==============================================================validation
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    const validationErrors = {};
    if (!data.username) {
      validationErrors.username = "Masukan Username";
    }
    if (!data.tlp) {
      validationErrors.tlp = "Masukan Nomor Telepon";
    } else {
      const valid = /^[0-9]{1,13}$/.test(data.tlp);
      !valid && (validationErrors.tlp = "Telepon Tidak Valid");
    }
    if (!data.email) {
      validationErrors.email = "Masukan Email";
    } else {
      const valid =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          data.email
        );
      !valid && (validationErrors.email = "Email Tidak Valid");
    }
    if (!data.password) {
      validationErrors.password = "Masukan Password";
    } else {
      const valid =
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,}$/.test(
          data.password
        );
      !valid &&
        (validationErrors.password =
          "Password minimal 8 huruf dengan 1 angka dan 1 huruf");
    }
    if (!data.konfirmasi_password) {
      validationErrors.konfirmasi_password = "Masukan konfirmasi password";
    } else {
      const valid = data.password === data.konfirmasi_password;
      !valid && (validationErrors.konfirmasi_password = "Password tidak cocok");
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      handleSubmit();
    }
  };

  //=============================================================== checkbox
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  //==================================================Handle submit
  const handleSubmit = async () => {
    if (!checked) {
      openErrorBox();
      setErrorServer("Harap setujui Syarat dan Ketentuan");
      handleCloseLoading();
      return;
    }
    const url = process.env.REACT_APP_BASE_URL + "api/user/RegisterUser";
    await axios
      .post(url, data)
      .then(() => {
        setToCloseRegister();
        setToOpenResponse({
          pathImage: "EmailSend.png",
          tittle: "Registrasi Berhasil",
          massage: "Silahkan cek email anda untuk aktivasi akun.",
          nameButton: "Tutup",
        });
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat registrasi, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };

  return (
    <Modal
      open={openRegister}
      onClose={() => {
        setToCloseRegister();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleBoxModal}>
        <Box
          sx={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "white",
            position: "absolute",
            right: "-10px",
            top: "-10px",
            border: "solid",
            borderRadius: "50px",
            borderColor: "primary.main",
          }}
        >
          <Typography
            sx={{ color: "primary.main" }}
            onClick={() => {
              setToCloseRegister();
            }}
          >
            <Close />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ErrorBox sx={{ mb: "3" }} />

          <Typography variant="h4">Register</Typography>
          <TextField
            fullWidth
            required
            size="small"
            id="username"
            name="username"
            value={data.username}
            onChange={handleInputChange}
            label="Username"
            variant="outlined"
            sx={{ my: 1 }}
            error={!!errors.username}
            helperText={errors.username}
          />

          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                size="small"
                id="email"
                name="email"
                value={data.email}
                onChange={handleInputChange}
                label="Email"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                size="small"
                id="tlp"
                name="tlp"
                value={data.tlp}
                onChange={handleInputChange}
                label="No Whatsapp"
                variant="outlined"
                error={!!errors.tlp}
                helperText={errors.tlp}
              />
            </Grid>
          </Grid>
          <FormControl
            sx={{ mt: 1 }}
            fullWidth
            variant="outlined"
            error={!!errors.konfirmasi_password}
          >
            <InputLabel
              error={!!errors.konfirmasi_password}
              size="small"
              htmlFor="outlined-adornment-password"
            >
              Password
            </InputLabel>
            <OutlinedInput
              fullWidth
              required
              size="small"
              id="password"
              name="password"
              value={data.password}
              onChange={handleInputChange}
              label="Password"
              variant="outlined"
              error={!!errors.password}
              helperText={errors.password}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    //onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>{errors.password}</FormHelperText>
          </FormControl>
          <FormControl
            sx={{ mt: 1 }}
            fullWidth
            variant="outlined"
            error={!!errors.konfirmasi_password}
            helperText={errors.konfirmasi_password}
          >
            <InputLabel
              error={!!errors.konfirmasi_password}
              size="small"
              htmlFor="outlined-adornment-password"
            >
              Konfirmasi Password
            </InputLabel>
            <OutlinedInput
              fullWidth
              required
              size="small"
              id="konfirmasi_password"
              name="konfirmasi_password"
              value={data.konfirmasi_password}
              onChange={handleInputChange}
              label="Konfirmasi Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    //onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>{errors.konfirmasi_password}</FormHelperText>
          </FormControl>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Saya setuju dengan Syarat dan Ketentuan</Typography>
            </Box>
            <LoadingButton
              endIcon={<Person />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                handleLoading();
                checkValidate();
              }}
              variant="contained"
              sx={{
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                my: 1,
              }}
            >
              Submit
            </LoadingButton>
            <Box sx={{ display: "flex" }}>
              <Typography>Saya sudah memiliki Akun.</Typography>
              <Typography
                color="primary"
                onClick={() => {
                  toLogin();
                }}
                sx={{ cursor: "pointer", ml: 1 }}
                underline="none"
              >
                Masuk.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default Register;
