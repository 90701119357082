import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  Fade,
  TextField,
  MenuItem,
  Grid,
  Popper,
  Paper,
  TableSortLabel,
  Toolbar,
  alpha,
  Tooltip,
  IconButton,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Send,
  Sort,
} from "@mui/icons-material";
import jwt from "jwt-decode";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import "../../Helper/format/currency";
import ErrorBox from "../../Components/ErrorBox";

//===================================================================== Data Grid
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id_invoice",
    numeric: false,
    disablePadding: false,
    label: "ID Invoice",
  },
  {
    id: "tanggal_pembayaran",
    numeric: false,
    disablePadding: false,
    label: "Tanggal",
  },
  {
    id: "username_pengirim",
    numeric: false,
    disablePadding: false,
    label: "Pengirim",
  },
  {
    id: "harga",
    numeric: true,
    disablePadding: false,
    label: "Harga",
  },
  {
    id: "commission",
    numeric: true,
    disablePadding: false,
    label: "Komisi",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, amount } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Invoice
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              Total Komisi {amount.currency()}
            </Typography>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
//====================================================================== end

function Report() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    user,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //=========================================================authorize

  const history = useHistory();

  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role != "super" && token.role != "admin") {
      history.push("/");
    }
  };

  //=============================================================pooper
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPop, setOpenPop] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPop((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //==============================================================Get Data Pesan
  const [data, setData] = useState([]);

  const getDaftarPesan = async (fk_id_rd) => {
    const url =
      process.env.REACT_APP_BASE_URL + `api/pesan/pesan/rd/${fk_id_rd}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
      setData(
        response.data.map((e) => {
          return {
            nama_mendiang: e.nama_mendiang,
            username_pengirim: e.username_pengirim,
            pk_id_pesan: e.pk_id_pesan,
            fk_id_user: e.fk_id_user,
            kode_invoice: e.kode_invoice,
            no_urut: e.no_urut,
            image_pesan_path: e.image_pesan_path,
            takedown: e.takedown,
            tanggal_pembayaran: e.tanggal_pembayaran,
            harga: e.harga,
            fk_id_pk: e.fk_id_pk,
          };
        })
      );
      setSearchData(
        response.data.map((e) => {
          return {
            nama_mendiang: e.nama_mendiang,
            username_pengirim: e.username_pengirim,
            pk_id_pesan: e.pk_id_pesan,
            fk_id_user: e.fk_id_user,
            kode_invoice: e.kode_invoice,
            no_urut: e.no_urut,
            image_pesan_path: e.image_pesan_path,
            takedown: e.takedown,
            tanggal_pembayaran: e.tanggal_pembayaran,
            harga: e.harga,
            fk_id_pk: e.fk_id_pk,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data pesan, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  //===============================================================Get data RD
  const [dataRD, setDataRD] = useState([]);

  const getDaftarRD = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/RumahDuka/RumahDuka";
    try {
      const response = await axios.get(url);
      setDataRD(
        response.data.map((e) => {
          return {
            pk_id_rd: e.pk_id_rd,
            nama_rd: e.nama_rd,
            alamat: e.alamat,
            commission: e.commission,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  const getRD = async (fk_id_rd) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/RumahDuka/RumahDukaById?id=" +
      fk_id_rd;
    try {
      const response = await axios.get(url);
      setSelectedRD(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  useEffect(() => {
    if (user.fk_id_rd > 0) {
      getDaftarPesan(user.fk_id_rd);
      getRD(user.fk_id_rd);
    } else {
      getDaftarRD();
    }
    auth();
  }, [user]);
  //====================================================Select RD
  const initialRD = {
    pk_id_rd: 0,
    nama_rd: "",
    commission: 0,
    fk_id_pk: 0,
  };

  const [selectedRD, setSelectedRD] = useState(initialRD);

  const selectFilter = (pk_id_rd) => {
    const matchRD = dataRD.find((item) => item.pk_id_rd === pk_id_rd);
    if (matchRD) {
      setSelectedRD(matchRD);
    } else {
      setSelectedRD(initialRD);
    }
  };

  useEffect(() => {
    if (selectedRD.pk_id_rd > 0) {
      getDaftarPesan(selectedRD.pk_id_rd);
    }
  }, [selectedRD]);

  // ===================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState({
    tanggal_awal: "",
    tanggal_akhir: "",
  });
  function searchFunction() {
    return data.filter((searchText) => {
      const pesanDate = new Date(searchText.tanggal_pembayaran);
      let filteredText;
      if (inputSearchData.tanggal_awal && !inputSearchData.tanggal_akhir) {
        filteredText = pesanDate >= new Date(inputSearchData.tanggal_awal);
      }
      if (!inputSearchData.tanggal_awal && inputSearchData.tanggal_akhir) {
        filteredText = pesanDate <= new Date(inputSearchData.tanggal_akhir);
      }
      if (inputSearchData.tanggal_awal && inputSearchData.tanggal_akhir) {
        filteredText =
          pesanDate >= new Date(inputSearchData.tanggal_awal) &&
          pesanDate <= new Date(inputSearchData.tanggal_akhir);
      }
      return filteredText;
    });
  }

  useEffect(() => {
    if (inputSearchData.tanggal_akhir || inputSearchData.tanggal_awal) {
      setSearchData(searchFunction());
    }
  }, [inputSearchData]);

  //====================================================================Data Grid
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchData.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(searchData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, searchData]
  );
  //======================================================================data view
  const Date_ = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  const [amount, setAmount] = useState([]);
  const [waitingInv, setWaitingInv] = useState([]);
  const total = () => {
    const waitingData = searchData.filter((pesan) => pesan.fk_id_pk === 0);
    setWaitingInv(waitingData.map((waiting) => waiting.pk_id_pesan));
    const newAmount = waitingData.reduce((accumulator, currentData) => {
      return (
        accumulator +
        Math.round((currentData.harga / 100) * selectedRD.commission)
      );
    }, 0);
    setAmount(newAmount);
  };

  useEffect(() => {
    total();
  }, [searchData]);

  //==================================================================handele bayar
  useEffect(() => {
    if (dataKonfirm === true) {
      if (waitingInv.length > 0) {
        handleSubmit();
      } else {
        handleCloseLoading();
      }
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm();
  }, [dataKonfirm]);

  const handleSubmit = async () => {
    const dataKomisi = {
      total_pembayaran: amount,
      fk_id_rd: selectedRD.pk_id_rd,
      pk_id_pesan: waitingInv,
    };
    const url = process.env.REACT_APP_BASE_URL + "api/komisi/komisi";
    try {
      await axios
        .post(url, dataKomisi, {
          headers: {
            Authorization: `bearer ${loginToken}`,
          },
        })
        .then(() => {
          history.push("/komisi");
        });
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer("Terjadi error saat membayar komisi, Mohon coba lagi");
      }
      openErrorBox();
    } finally {
      handleCloseLoading();
    }
  };

  return (
    <Layout>
      <Sidebar />
      <Popper
        open={openPop}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ p: 2 }}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                id="tanggal_awal"
                label="Tanggal Awal"
                format="DD MMM YYYY"
                onChange={(e) =>
                  setInputSearchData({
                    ...inputSearchData,
                    tanggal_awal: e.format("YYYY/MM/DD"),
                  })
                }
              />
              <Box mt={2} />
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                id="tanggal_akhir"
                label="Tanggal Akhir"
                format="DD MMM YYYY"
                onChange={(e) =>
                  setInputSearchData({
                    ...inputSearchData,
                    tanggal_akhir: e.format("YYYY/MM/DD"),
                  })
                }
              />
            </Paper>
          </Fade>
        )}
      </Popper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 5,
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" mr={2}>
                  Daftar Laporan
                </Typography>
              </Grid>
              {user.fk_id_rd === 0 && (
                <Grid item>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    id="kota"
                    name="pk_id_rd"
                    onChange={(e) => {
                      selectFilter(e.target.value);
                    }}
                    label="Pilih Rumah Duka"
                    variant="outlined"
                    select
                    defaultValue="0"
                  >
                    <MenuItem value="0">Pilih Rumah Duka...</MenuItem>
                    {dataRD.map((option) => (
                      <MenuItem key={option.pk_id_rd} value={option.pk_id_rd}>
                        {option.nama_rd}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
            </Grid>
            <Box>
              <Button
                variant="contained"
                color="ok"
                sx={{
                  color: "white",
                  borderRadius: "100px",
                  whiteSpace: "nowrap",
                }}
                size="small"
                startIcon={<Sort />}
                onClick={handleClick("bottom-end")}
              >
                Pilih Periode
              </Button>
            </Box>
          </Box>
          {/* -------------------Data Table-------------------- */}
          <Paper sx={{ width: "100%", my: 2 }}>
            <EnhancedTableToolbar
              numSelected={waitingInv?waitingInv.length:0}
              amount={amount}
            />
            <TableContainer>
              <Table aria-labelledby="tableTitle" size="small">
                <EnhancedTableHead
                  numSelected={waitingInv?waitingInv.length:0}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={searchData.length}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow key={row.pk_id_pesan}>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.kode_invoice + " - " + row.no_urut}
                        </TableCell>
                        <TableCell>
                          {new Date(row.tanggal_pembayaran).toLocaleString(
                            undefined,
                            Date_
                          )}
                        </TableCell>
                        <TableCell>{row.username_pengirim}</TableCell>
                        <TableCell align="right">
                          {row.harga.currency()}
                        </TableCell>
                        <TableCell align="right">
                          {Math.round(
                            (row.harga / 100) * selectedRD.commission
                          ).currency()}
                        </TableCell>
                        <TableCell align="right">
                          {row.fk_id_pk ? "Paid" : "Waiting"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={searchData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
            {amount > 0 && (
              <Typography color="inherit" variant="subtitle1">
                Total Komisi {amount.currency()}
              </Typography>
            )}
            {user.role === "super" && (
              <LoadingButton
                variant="contained"
                color="ok"
                sx={{
                  color: "white",
                  borderRadius: "100px",
                  whiteSpace: "nowrap",
                  ml: 2,
                }}
                size="small"
                endIcon={<Send />}
                onClick={() => {
                  handleLoading();
                  setToOpenKonfirm();
                }}
                loading={loadingSts}
                loadingPosition="end"
                disabled={amount === 0}
              >
                Bayar
              </LoadingButton>
            )}
          </Box>

          {/* -------------------------Data Table End------------------------------ */}
        </Box>
      </Box>
    </Layout>
  );
}

export default Report;
