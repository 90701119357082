import React, { useRef } from "react";
import Layout from "../../Components/Layout";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { SvgLoader, SvgProxy } from "react-svgmt";
import Avatar from "react-avatar-edit";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import ErrorBox from "../../Components/ErrorBox";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import Sidebar from "../../Components/Sidebar";
import { LoadingButton } from "@mui/lab";
import { CheckCircleOutline } from "@mui/icons-material";
import { base64ToFile } from "../../Helper/image/imageFunction";
import {
  getFontSize,
  getMatrixTransform,
  getNewFontSize,
  getWidth,
  splitStringBySpace,
} from "../../Helper/image/useImageEditor";

function TemplateEditor() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    user,
    setToOpenMasuk,
    sendDataPesan,
    openErrorBox,
    setErrorServer,
  } = masukContext;

  const history = useHistory();
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState();
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //=======================================================================get data template
  const SvgPath = sessionStorage.getItem("selected_template");
  const idTemplate = sessionStorage.getItem("selected_template_id");
  const svgUrl =
    process.env.REACT_APP_BASE_URL + "api/Template/file?path_image=" + SvgPath;
  const [SVG, setSVG] = useState();

  const [ifPesan, setIfPesan] = useState(false);
  const [ifLogo, setIfLogo] = useState(false);
  const [ifKeterangan_pengirim, setIfKeterangan_pengirim] = useState(false);
  const [ifKeterangan_penerima, setIfKeterangan_penerima] = useState(false);

  const getSvg = () => {
    try {
      axios.get(svgUrl).then((response) => {
        setIfPesan(response.data.includes('id="pesan"'));
        setIfLogo(response.data.includes('id="logo"'));
        setIfKeterangan_pengirim(
          response.data.includes('id="keterangan_pengirim"')
        );
        setIfKeterangan_penerima(
          response.data.includes('id="keterangan_penerima"')
        );
        setSVG(response.data);
      });
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  //============================================get Template Data
  const [template, setTemplate] = useState({
    max_width_message: 0,
    max_width_mendiang: 0,
    max_width_sender: 0,
    transform_logo: false,
  });
  const getTemplateDetail = (pk_id_template) => {
    if (pk_id_template) {
      const getUrl =
        process.env.REACT_APP_BASE_URL +
        "api/Template/TemplateById?id=" +
        pk_id_template;
      axios
        .get(getUrl)
        .then((response) => {
          setTemplate(response.data);
        })
        .catch((error) => {
          if (error.response.data.length < 100) {
            setErrorServer(error.response.data);
          } else {
            setErrorServer(
              "Terjadi error saat mengambil data template, Mohon coba lagi"
            );
          }
          openErrorBox();
        });
    }
  };

  //=======================================================================get data mendiang
  const pk_id_mendiang = sessionStorage.getItem("selected_mendiang");

  const initialStateKartu = {
    pengirim: "",
    ucapan: "",
    baris_1: "",
    baris_2: "",
    nama_mendiang: "",
    keterangan_penerima: "",
    keterangan_pengirim: "",
    foto_mendiang: "",
    pesan: "",
    fk_id_template: idTemplate,
  };
  const [data, setData] = useState(initialStateKartu);
  const [logo, setLogo] = useState("");
  const [base64StringAvt, setBase64StringAvt] = useState();

  const getMendiang = async (/*userID*/) => {
    if (!pk_id_mendiang) {
      history.push("/daftar-mendiang");
    }
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/Mendiang/MendiangById?id=" +
      pk_id_mendiang;

    try {
      const mendiang = await axios.get(url);
      setData({
        ...data,
        nama_mendiang: mendiang.data.nama_mendiang,
        // foto_mendiang: base64,
      });
      const replacedText = mendiang.data.path_image;
      const url2 =
        process.env.REACT_APP_BASE_URL +
        "api/mendiang/avatar?path_image=" +
        replacedText;
      await axios.get(url2).then((response) => {
        setBase64StringAvt(`data:image/png;base64,${response.data}`);
      });
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data mendiang, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  useEffect(() => {
    getMendiang();
    getSvg();
    getTemplateDetail(idTemplate);
  }, []);

  //=========================================================================handling input
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleInputChangePesan = (e) => {
    const value = e.target.value;
    const splitValue = splitStringBySpace(value);
    const stringsArray = splitValue.split("\n");
    setData({
      ...data,
      pesan: value,
      baris_1: stringsArray[0],
      baris_2: stringsArray[1] ? stringsArray[1] : "",
    });
  };

  const cropAvatar = (view) => {
    setLogo(view);
  };
  //===================================================================Handling Dynamic Editor
  const svgElementRef = useRef(null);

  const [MAX_FONT_SIZE, setMAX_FONT_SIZE] = useState(0);
  const MIN_FONT_SIZE = 40;
  const [MAX_DETAIL_FONT_SIZE, setMAX_DETAIL_FONT_SIZE] = useState(0);
  const MIN_DETAIL_FONT_SIZE = 30;
  const [MAX_MESSAGE_FONT_SIZE, setMAX_MESSAGE_FONT_SIZE] = useState(0);
  const MIN_MESSAGE_FONT_SIZE = 30;

  const handleSVGReady = (svg) => {
    let fontSize;
    while (!fontSize) {
      svgElementRef.current = svg;
      fontSize = getFontSize("#pengirim", svgElementRef);
      const detailFontSize = getFontSize("#keterangan_pengirim", svgElementRef);
      const messageFontSize = getFontSize("#baris_1", svgElementRef);
      setMAX_FONT_SIZE(fontSize);
      setMAX_DETAIL_FONT_SIZE(detailFontSize);
      setMAX_MESSAGE_FONT_SIZE(messageFontSize);
      setMendiangFontSize(fontSize);
      setDetailMendiangFontSize(detailFontSize);
      setPengirimFontSize(fontSize);
      setDetailPengirimFontSize(detailFontSize);
      setPesanFontSize(messageFontSize);
    }
  };
  const [maxInputDetailMendiang, setMaxInputDetailMendiang] = useState(999);
  const [maxInputPengirim, setMaxInputPengirim] = useState(999);
  const [maxInputDetailPengirim, setMaxInputDetailPengirim] = useState(999);
  const [maxInputMessage, setMaxInputMessage] = useState(999);

  const [mendiangFontSize, setMendiangFontSize] = useState();
  const [detailMendiangFontSize, setDetailMendiangFontSize] = useState();
  const [pengirimFontSize, setPengirimFontSize] = useState();
  const [detailPengirimFontSize, setDetailPengirimFontSize] = useState();
  const [pesanFontSize, setPesanFontSize] = useState();
  const [logoTransform, setLogoTransform] = useState("");

  useEffect(() => {
    if (svgElementRef.current && detailMendiangFontSize) {
      const element = svgElementRef.current.querySelector(
        "#keterangan_penerima"
      );
      const detailMendiangWidth = getWidth(element);
      setDetailMendiangFontSize(
        getNewFontSize(
          detailMendiangWidth,
          template.max_width_mendiang,
          75,
          detailMendiangFontSize,
          MIN_DETAIL_FONT_SIZE,
          MAX_DETAIL_FONT_SIZE
        )
      );
      if (
        detailMendiangWidth > template.max_width_mendiang &&
        detailMendiangFontSize < MIN_DETAIL_FONT_SIZE + 1
      ) {
        if (maxInputDetailMendiang === 999) {
          setMaxInputDetailMendiang(data.keterangan_penerima.length);
        }
      } else {
        setMaxInputDetailMendiang(999);
      }
    }
  }, [data.keterangan_penerima, detailMendiangFontSize]);
  useEffect(() => {
    if (svgElementRef.current && mendiangFontSize) {
      const element = svgElementRef.current.querySelector("#nama_mendiang");
      const mendiangWidth = getWidth(element);
      setMendiangFontSize(
        getNewFontSize(
          mendiangWidth,
          template.max_width_mendiang,
          100,
          mendiangFontSize,
          MIN_FONT_SIZE,
          MAX_FONT_SIZE
        )
      );
    }
  }, [data.nama_mendiang, mendiangFontSize]);
  useEffect(() => {
    if (svgElementRef.current && pesanFontSize) {
      const elementBaris1 = svgElementRef.current.querySelector("#baris_1");
      const elementBaris2 = svgElementRef.current.querySelector("#baris_2");

      let pesan1Width = getWidth(elementBaris1);
      let pesan2Width = getWidth(elementBaris2);
      let newFontSize;
      if (pesan1Width > pesan2Width) {
        newFontSize = getNewFontSize(
          pesan1Width,
          template.max_width_message,
          75,
          pesanFontSize,
          MIN_MESSAGE_FONT_SIZE
        );
      } else {
        newFontSize = getNewFontSize(
          pesan2Width,
          template.max_width_message,
          75,
          pesanFontSize,
          MIN_MESSAGE_FONT_SIZE
        );
      }

      setPesanFontSize(newFontSize);

      if (
        pesan2Width > template.max_width_message &&
        pesanFontSize < MIN_MESSAGE_FONT_SIZE + 1
      ) {
        if (maxInputMessage === 999) {
          setMaxInputMessage(data.pesan.length);
        }
      } else {
        setMaxInputMessage(999);
      }
    }
  }, [data.pesan, pesanFontSize]);

  useEffect(() => {
    if (svgElementRef.current && pengirimFontSize) {
      const elementPengirim = svgElementRef.current.querySelector("#pengirim");
      const elementDetail = svgElementRef.current.querySelector(
        "#keterangan_pengirim"
      );
      const elementLogo = svgElementRef.current.querySelector("#logo");

      const senderWidth = getWidth(elementPengirim);
      const senderDetailWidth = getWidth(elementDetail);

      setPengirimFontSize(
        getNewFontSize(
          senderWidth,
          template.max_width_sender,
          100,
          pengirimFontSize,
          MIN_FONT_SIZE,
          MAX_FONT_SIZE
        )
      );
      setDetailPengirimFontSize(
        getNewFontSize(
          senderDetailWidth,
          template.max_width_sender,
          75,
          detailPengirimFontSize,
          MIN_DETAIL_FONT_SIZE,
          MAX_DETAIL_FONT_SIZE
        )
      );

      if (
        senderWidth > template.max_width_sender &&
        pengirimFontSize < MIN_FONT_SIZE + 1
      ) {
        if (maxInputPengirim === 999) {
          setMaxInputPengirim(data.pengirim.length);
        }
      } else {
        setMaxInputPengirim(999);
      }

      if (
        senderDetailWidth > template.max_width_sender &&
        detailPengirimFontSize < MIN_DETAIL_FONT_SIZE + 1
      ) {
        if (maxInputDetailPengirim === 999) {
          setMaxInputDetailPengirim(data.keterangan_pengirim.length);
        }
      } else {
        setMaxInputDetailPengirim(999);
      }

      if (template.transform_logo) {
        if (elementLogo && elementPengirim && elementDetail) {
          setLogoTransform(
            getMatrixTransform(
              elementLogo,
              elementPengirim,
              elementDetail,
              senderWidth,
              senderDetailWidth
            )
          );
        }
      }
    }
  }, [
    data.pengirim,
    pengirimFontSize,
    logo,
    data.keterangan_pengirim,
    detailPengirimFontSize,
    template,
  ]);

  //========================================validasi
  const initialState = {
    pengirim: "",
  };
  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    if (!loginToken) {
      handleCloseLoading();
      setToOpenMasuk();
      return;
    }

    const validationErrors = {};

    if (!data.pengirim) {
      validationErrors.pengirim = "Masukan Pengirim";
    }
    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setLoadingSts(false);
      handleCloseLoading();
      setErrors(validationErrors);
    } else {
      handleLoading();
      // Add logic to handle form submission (e.g., sending data to a server)
      handleSubmit();
    }
  };
  //==========================================================Handle Submit

  const handleSubmit = async () => {
    const file = logo ? base64ToFile(logo) : null;

    const disc = (Math.floor(Math.random() * 900) + 100) * -1;

    const url =
      process.env.REACT_APP_BASE_URL +
      "api/mendiang/harga/ucapan/" +
      pk_id_mendiang;
    axios
      .get(url)
      .then((response) => {
        sendDataPesan({
          fk_id_user: user.pk_id_user,
          fk_id_mendiang: pk_id_mendiang,
          disc: disc,
          harga: response.data,
          pesan_user: {
            logo: file,
            pengirim: data.pengirim,
            keterangan_penerima: data.keterangan_penerima,
            keterangan_pengirim: data.keterangan_pengirim,
            pesan: data.pesan,
            fk_id_template: data.fk_id_template,
          },
        });
        history.push(`/pesan/payment/${pk_id_mendiang}`);
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat menyimpan pesan, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };

  const renderPreview = () => {
    if (template.max_width_sender !== 0 && SVG) {
      return (
        <SvgLoader svgXML={SVG} onSVGReady={handleSVGReady} id="svg">
          {data.pengirim ? (
            pengirimFontSize ? (
              <SvgProxy
                selector="#pengirim"
                font-size={pengirimFontSize + "px"}
                visibility="visible"
              >
                {data.pengirim}
              </SvgProxy>
            ) : (
              <SvgProxy selector="#pengirim" visibility="visible">
                {data.pengirim}
              </SvgProxy>
            )
          ) : (
            <SvgProxy selector="#pengirim" visibility="hidden" />
          )}
          {data.keterangan_pengirim ? (
            detailPengirimFontSize ? (
              <SvgProxy
                visibility="visible"
                selector="#keterangan_pengirim"
                font-size={detailPengirimFontSize + "px"}
              >
                {data.keterangan_pengirim}
              </SvgProxy>
            ) : (
              <SvgProxy visibility="visible" selector="#keterangan_pengirim">
                {data.keterangan_pengirim}
              </SvgProxy>
            )
          ) : (
            <SvgProxy visibility="hidden" selector="#keterangan_pengirim" />
          )}
          {data.keterangan_penerima ? (
            detailMendiangFontSize ? (
              <SvgProxy
                visibility="visible"
                selector="#keterangan_penerima"
                font-size={detailMendiangFontSize + "px"}
              >
                {data.keterangan_penerima}
              </SvgProxy>
            ) : (
              <SvgProxy visibility="visible" selector="#keterangan_penerima">
                {data.keterangan_penerima}
              </SvgProxy>
            )
          ) : (
            <SvgProxy visibility="hidden" selector="#keterangan_penerima" />
          )}
          {mendiangFontSize ? (
            <SvgProxy
              selector="#nama_mendiang"
              font-size={mendiangFontSize + "px"}
            >
              {data.nama_mendiang}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#nama_mendiang">{data.nama_mendiang}</SvgProxy>
          )}
          {logoTransform ? (
            <SvgProxy selector="#logo" href={logo} transform={logoTransform} />
          ) : (
            <SvgProxy selector="#logo" href={logo} />
          )}
          <SvgProxy selector="#foto_mendiang" href={base64StringAvt} />
          {data.baris_1 ? (
            pesanFontSize ? (
              <SvgProxy
                selector="#baris_1"
                font-size={pesanFontSize + "px"}
                visibility="visible"
              >
                {data.baris_1}
              </SvgProxy>
            ) : (
              <SvgProxy selector="#baris_1" visibility="visible">
                {data.baris_1}
              </SvgProxy>
            )
          ) : (
            <SvgProxy selector="#baris_1" visibility="hidden" />
          )}
          {data.baris_2 ? (
            pesanFontSize ? (
              <SvgProxy
                selector="#baris_2"
                font-size={pesanFontSize + "px"}
                visibility="visible"
              >
                {data.baris_2}
              </SvgProxy>
            ) : (
              <SvgProxy selector="#baris_2" visibility="visible">
                {data.baris_2}
              </SvgProxy>
            )
          ) : (
            <SvgProxy selector="#baris_2" visibility="hidden" />
          )}
        </SvgLoader>
      );
    }
  };

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", mt: 7 }}>
            <ErrorBox />

            <Typography variant="h4">Edit Tamplate</Typography>
            <Typography>
              Kosongkan input jika ingin dikirim secara default
            </Typography>
            <Typography color="red">
              Harap perhatikan preview gambar, preview merupakan hasil akhir
              gambar ucapan.
            </Typography>
            <Grid mt={3} container spacing={2}>
              <Grid item xs={12} md={4}>
                {ifKeterangan_penerima && (
                  <TextField
                    size="small"
                    fullWidth
                    id="keterangan_penerima"
                    name="keterangan_penerima"
                    onChange={handleInputChange}
                    variant="outlined"
                    label="Keterangan Penerima"
                    sx={{ mt: 2 }}
                    helperText="e.g: Keluarga dari (nama keluarga), Suami dari, dll "
                    inputProps={{
                      maxLength: maxInputDetailMendiang, // Set the maximum character limit
                    }}
                  />
                )}
                {ifLogo && (
                  <Box>
                    <Typography sx={{ mt: 2 }}>Logo Perusahaan</Typography>
                    <Avatar width={150} height={150} onCrop={cropAvatar} />
                  </Box>
                )}
                {ifKeterangan_pengirim && (
                  <TextField
                    size="small"
                    fullWidth
                    id="keterangan_pengirim"
                    name="keterangan_pengirim"
                    onChange={handleInputChange}
                    variant="outlined"
                    label="Keterangan Pengirim"
                    sx={{ mt: 2 }}
                    helperText="e.g: Direksi dan Staff, Keluarga Besar, dll"
                    inputProps={{
                      maxLength: maxInputDetailPengirim, // Set the maximum character limit
                    }}
                  />
                )}
                <TextField
                  size="small"
                  fullWidth
                  id="pengirim"
                  name="pengirim"
                  onChange={handleInputChange}
                  variant="outlined"
                  label="Pengirim"
                  sx={{ mt: 2 }}
                  inputProps={{
                    maxLength: maxInputPengirim, // Set the maximum character limit
                  }}
                  error={!!errors.pengirim}
                  helperText={errors.pengirim}
                />
                {ifPesan && (
                  <Box>
                    <TextField
                      size="small"
                      fullWidth
                      id="pesan"
                      name="pesan"
                      onChange={handleInputChangePesan}
                      variant="outlined"
                      multiline
                      label="Pesan"
                      sx={{ mt: 2 }}
                      inputProps={{
                        maxLength: maxInputMessage, // Set the maximum character limit
                      }}
                    />
                  </Box>
                )}
                <LoadingButton
                  endIcon={<CheckCircleOutline />}
                  // disabled={disabled}
                  loading={loadingSts}
                  loadingPosition="end"
                  variant="contained"
                  onClick={() => {
                    checkValidate();
                  }}
                  sx={{ color: "white", mt: 3, borderRadius: "100px" }}
                >
                  Konfirmasi
                </LoadingButton>
              </Grid>
              <Grid item xs={12} md={8}>
                <div>
                  <div id="svg-container">{renderPreview()}</div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default TemplateEditor;
