import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Typography,
  Modal,
  Fade,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import { Link as DirectLink } from "react-router-dom";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { Close, Edit, Search } from "@mui/icons-material";
import jwt from "jwt-decode";
import ErrorBox from "../../Components/ErrorBox";
import {
  styleBoxModal,
  StyledTableCell,
  StyledTableRow,
} from "../../Helper/style/style";

function DaftarPesan() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    user,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //=========================================================authorize

  const history = useHistory();

  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role != "super" && token.role != "admin") {
      history.push("/");
    }
  };
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //==============================================================Get Data
  const [data, setData] = useState([]);

  const getDaftarPesan = async (/*userID*/) => {
    let url;
    if (user.fk_id_rd) {
      url =
        process.env.REACT_APP_BASE_URL + `api/pesan/pesan/rd/${user.fk_id_rd}`;
    }
    if (user.role === "super") {
      url = process.env.REACT_APP_BASE_URL + "api/pesan/pesan";
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
      setData(
        response.data.map((e) => {
          return {
            nama_mendiang: e.nama_mendiang,
            username_pengirim: e.username_pengirim,
            pk_id_pesan: e.pk_id_pesan,
            fk_id_user: e.fk_id_user,
            kode_invoice: e.kode_invoice,
            no_urut: e.no_urut,
            image_pesan_path: e.image_pesan_path,
            takedown: e.takedown,
          };
        })
      );
      setSearchData(
        response.data.map((e) => {
          return {
            nama_mendiang: e.nama_mendiang,
            username_pengirim: e.username_pengirim,
            pk_id_pesan: e.pk_id_pesan,
            fk_id_user: e.fk_id_user,
            kode_invoice: e.kode_invoice,
            no_urut: e.no_urut,
            image_pesan_path: e.image_pesan_path,
            takedown: e.takedown,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data pesan, Mohon  coba lagi"
        );
      }
      openErrorBox();
    }
  };
  //=====================================================handleInput takedown
  const [modalTakedown, setModalTakedown] = React.useState(false);
  const handleOpenTakedown = () => setModalTakedown(true);
  const handleCloseTakedown = () => setModalTakedown(false);

  const [takedownData, setTakedownData] = useState({ takedown: false });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTakedownData({
      ...takedownData,
      [name]: value,
    });
  };

  //=====================================================takedown
  useEffect(() => {
    if (dataKonfirm === true) {
      if (takedownData.reason) {
        takedown();
      }
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setTakedownData({ takedown: false });
    setDataKonfirm();
  }, [dataKonfirm]);

  const takedown = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/pesan/pesan";
    try {
      await axios.put(url, takedownData, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
      const updatedPesanList = data.map((pesan) => {
        if (pesan.pk_id_pesan === takedownData.pk_id_pesan) {
          return { ...pesan, takedown: takedownData.takedown };
        }
        return pesan;
      });

      setData(updatedPesanList);
      // setData(data.filter((item) => item.pk_id_pesan != takedownData.pk_id_pesan ));
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer("Terjadi error saat takedown pesan, Mohon  coba lagi");
      }
      openErrorBox();
    } finally {
      handleCloseLoading();
      setTakedownData({ takedown: false });
      handleCloseTakedown();
    }
  };

  useEffect(() => {
    getDaftarPesan(/*usrid*/);
    auth();
  }, [user]);
  //================================================================view modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showImg, setShowImg] = useState();
  // ===================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");
  function searchFunction(query) {
    query = query.toLowerCase(); // Convert the query to lowercase for case-insensitive search

    return data.filter((searchText) => {
      const filteredText = searchText.username_pengirim
        .toLowerCase()
        .includes(query);
      const filteredText2 = (
        searchText.kode_invoice +
        " - " +
        searchText.no_urut
      )
        .toLowerCase()
        .includes(query);
      return filteredText || filteredText2;
    });
  }

  const handleInputChangeSearch = (e) => {
    const { name, value } = e.target;
    // setInputSearchData(value)
    setInputSearchData({
      ...inputSearchData,
      [name]: value,
    });
    setSearchData(searchFunction(value));
  };

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 5,
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" mr={2}>
                  Daftar Ucapan
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  placeholder="Search…"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  name="searchText"
                  onChange={handleInputChangeSearch}
                  value={inputSearchData.searchText}
                />
              </Grid>
            </Grid>
            <Box>
              <Button
                component={DirectLink}
                to="/daftar-mendiang"
                variant="contained"
                sx={{ borderRadius: "100px", color: "white" }}
              >
                Tambah
              </Button>
            </Box>
          </Box>
          <TableContainer sx={{ my: 5 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Invoice ID</StyledTableCell>
                  <StyledTableCell align="center">
                    Nama Pengirim
                  </StyledTableCell>
                  <StyledTableCell align="center">Kepada</StyledTableCell>

                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.kode_invoice + " - " + row.no_urut}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.username_pengirim}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.nama_mendiang}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Button
                        color="ok"
                        variant="contained"
                        size="small"
                        sx={{ m: 1, color: "white" }}
                        onClick={() => {
                          setShowImg(row.image_pesan_path);
                          handleOpen();
                        }}
                      >
                        Lihat Ucapan
                      </Button>
                      <Button
                        color={row.takedown ? "success" : "error"}
                        variant="contained"
                        size="small"
                        sx={{ m: 1, color: "white" }}
                        onClick={() => {
                          setTakedownData({
                            ...takedownData,
                            pk_id_pesan: row.pk_id_pesan,
                            takedown: !row.takedown,
                            admin_takedown: user.pk_id_user,
                          });
                          handleOpenTakedown();
                        }}
                      >
                        {row.takedown ? "UP" : "Takedown"}
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleBoxModal}>
            <img
              src={process.env.REACT_APP_BASE_URL + showImg}
              style={{ width: "100%" }}
              alt=""
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalTakedown}
        onClose={handleCloseTakedown}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalTakedown}>
          <Box sx={styleBoxModal}>
            <Box
              sx={{
                width: "25px",
                height: "25px",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                backgroundColor: "white",
                position: "absolute",
                right: "-10px",
                top: "-10px",
                border: "solid",
                borderRadius: "50px",
                borderColor: "primary.main",
              }}
            >
              <Typography
                sx={{ color: "primary.main" }}
                onClick={() => {
                  handleCloseTakedown();
                }}
              >
                <Close />
              </Typography>
            </Box>
            <Typography>
              {!takedownData.takedown ? "UP Pesan" : "Takedown"}
            </Typography>
            <TextField
              fullWidth
              required
              size="small"
              id="reason"
              name="reason"
              onChange={handleInputChange}
              label="Masukan alasan "
              variant="outlined"
              sx={{ mt: 3 }}
            />
            <LoadingButton
              endIcon={<Edit />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                handleLoading();
                setToOpenKonfirm();
              }}
              variant="contained"
              sx={{
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                mt: 3,
              }}
            >
              {takedownData.takedown ? "Takedown" : "UP"}
            </LoadingButton>
          </Box>
        </Fade>
      </Modal>
    </Layout>
  );
}

export default DaftarPesan;
