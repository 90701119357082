import { Box, Typography } from '@mui/material'
import React from 'react'

function Footer() {
  
  return (
    <Box bgcolor="primary.main" sx={{height:40, width:'100%', display:'flex', justifyContent:'end' , alignItems:'center',zIndex:100 }}>
      <Typography sx={{color:'white', mx:3}}>
      Copyright &copy; 2023 InMemoriam.id
      </Typography>
    </Box>
  )
}

export default Footer