import React, { useEffect, useRef, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";
import Frame from "../../Assets/Avatar_frame.webp";
import Avatar from "react-avatar-edit";
import defAvatar from "../../Assets/AvatarDefault.svg";
import { DatePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import jwt from "jwt-decode";
import { LoadingButton } from "@mui/lab";
import { Person } from "@mui/icons-material";
import { base64ToFile } from "../../Helper/image/imageFunction";
import { styleBoxModal } from "../../Helper/style/style";
import {
  convertDateFormat,
  convertDateFormatView,
  defineTimezone,
  getIndonesianFormattedDateTime,
} from "../../Helper/format/date";
import { SvgLoader, SvgProxy } from "react-svgmt";
import moment from "moment";
import { getWidth } from "../../Helper/image/useImageEditor";
function EditMendiang() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    user,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //=========================================================authorize
  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role !== "admin" && token.role !== "super") {
      history.push("/");
    }
  };
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //============================================================================ROW ACARA
  const reqAcara = [
    "Masuk Peti",
    "Tutup Peti",
    "Kebaktian",
    "Kebaktian Pelepasan",
  ];

  const [acaraEntries, setAcaraEntries] = useState([
    { nama_acara: "", tanggal_acara: "" },
  ]);

  const handleInputChangeAcara = (index, value, name) => {
    const updatedAcaraEntries = [...acaraEntries];
    updatedAcaraEntries[index][name] = value;

    setAcaraEntries(updatedAcaraEntries);
  };
  const handleAddRow = () => {
    setAcaraEntries([...acaraEntries, { nama_acara: "", tanggal_acara: "" }]);
    if (acaraEntries.length > 4 && textKeluarga[0] !== "") {
      setErrors({
        ...errors,
        acara: "Jumlah acara yang akan ditampilkan pada twibbon hanya 5",
      });
    }
  };

  const handleRemoveRow = (index) => {
    const updatedAcaraEntries = [...acaraEntries];
    updatedAcaraEntries.splice(index, 1);
    setAcaraEntries(updatedAcaraEntries);
  };
  //============================================getdata
  const params = useParams();
  const initialStateMendiang = {
    path_image: "",
    nama_mendiang: "",
    tanggal_wafat: "",
    fk_id_rd: 0,
    fk_id_ruangan: 0,
    metode_jenazah: "Pemakaman",
    keterangan_mendiang: "",
    keluarga: "",
  };
  const [data, setData] = useState(initialStateMendiang);

  const getMendiang = async (/*userID*/) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/Mendiang/MendiangById?id=" +
      params.id;
    try {
      const response = await axios.get(url);
      setData(response.data);
      getRDID(response.data.fk_id_rd);
      setTextKeluarga(response.data.keluarga.split("\n"));
      setInputKeluarga(response.data.keluarga);

      if (response.data.acara) {
        setAcaraEntries(response.data.acara);
      }

      const ruangan = {
        new_in: convertDateFormat(response.data.tanggal_masuk),
        new_out: convertDateFormat(response.data.tanggal_keluar),
        fk_id_rd: response.data.fk_id_rd,
      };
      getRuangan(ruangan);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data mendiang, Mohon  coba lagi"
        );
      }
      openErrorBox();
    }
  };
  //============================================modal Upload
  const [modal, setModal] = useState(false);
  const handleClose = () => {
    setModal(false);
  };
  const handleOpen = () => {
    setModal(true);
  };

  //-----------------------------------------------responsive
  const [show, setShow] = useState(0);
  useEffect(() => {
    const calculate_contaner = () => {
      const containerWidth = window.innerWidth;
      const containerHeight = window.innerHeight;
      if (containerHeight > containerWidth) {
        setShow((containerWidth * 60) / 100);
      } else {
        setShow((containerHeight * 50) / 100);
      }
    };
    calculate_contaner();
    // Attach the event listener for window resize
    window.addEventListener("resize", calculate_contaner);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", calculate_contaner);
    };
  }, []);
  //========================================handling avatar
  const [upImg, setUpImg] = useState();
  const [cropImg, setCropImg] = useState(null);

  const closeAvatar = () => {
    setCropImg(null);
  };
  const cropAvatar = (view) => {
    setCropImg(view);
  };
  const saveImg = () => {
    setUpImg(cropImg);
    setModal(false);
  };

  const avatarShow = () => {
    if (!!cropImg) {
      return cropImg;
    } else if (!!data.path_image) {
      return process.env.REACT_APP_BASE_URL + data.path_image;
    } else {
      return defAvatar;
    }
  };

  //========================================get rd & ruangan
  const [dataRD, setDataRD] = useState([]);
  const [selectedRD, setSelectedRD] = useState();
  const getRDID = async (id) => {
    const url =
      process.env.REACT_APP_BASE_URL + "api/RumahDuka/RumahDukaById?id=" + id;
    try {
      const response = await axios.get(url);
      setSelectedRD(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  const getRD = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/RumahDuka/RumahDuka";
    try {
      const response = await axios.get(url);
      setDataRD(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data rumah duka, Mohon  coba lagi"
        );
      }
      openErrorBox();
    }
  };

  const [dataRuangan, setDataRuangan] = useState([]);

  const getRuangan = async (value) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      `api/Ruangan/Ruangan/tersedia?new_in=${value.new_in}&new_out=${value.new_out}&fk_id_rd=${value.fk_id_rd}`;

    try {
      const response = await axios.get(url);
      setDataRuangan(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data ruanagn, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  useEffect(() => {
    getRD();
    getMendiang();
    auth();
  }, []);

  //============================================================================Get Twibbon
  const [svgTwibbon, setSvgTwibbon] = useState();
  const [twibbon, setTwibbon] = useState();
  console.log(twibbon);
  const getTwibbon = async (pk_id_resume_template) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      `api/resumetemplate/file?pk_id_resume_template=${pk_id_resume_template}&is_twibbon_tv=true`;
    try {
      const response = await axios.get(url);
      setSvgTwibbon(response.data);
    } catch (error) {
      setErrorServer(
        "Terjadi error saat pengambilan data twibbon, Mohon coba lagi"
      );

      openErrorBox();
    }
  };

  const getTwibbonById = async (pk_id_resume_template) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      `api/resumetemplate/resume-templatebyid?id=${pk_id_resume_template}`;
    try {
      const response = await axios.get(url);
      setTwibbon(response.data);
    } catch (error) {
      setErrorServer(
        "Terjadi error saat pengambilan data twibbon, Mohon coba lagi"
      );
      console.log(error);
      openErrorBox();
    }
  };

  useEffect(() => {
    if (selectedRD) {
      getTwibbon(selectedRD.fk_id_resume_template);
      getTwibbonById(selectedRD.fk_id_resume_template);
    }
  }, [selectedRD]);
  //========================================handling input

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  //==============================================================validation
  const initialState = {
    nama_mendiang: "",
    tanggal_lahir: "",
    tanggal_wafat: "",
    tanggal_masuk: "",
    tanggal_keluar: "",
    fk_id_ruangan: "",
    metode_jenazah: "",
  };
  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    const validationErrors = {};
    if (!data.nama_mendiang) {
      validationErrors.nama_mendiang = "Masukan Nama Mendiang";
    }
    if (!data.tanggal_lahir) {
      validationErrors.tanggal_lahir = "Masukan Tanggal Lahir";
    }
    if (!data.tanggal_wafat) {
      validationErrors.tanggal_wafat = "Masukan Tanggal Wafat";
    }
    if (!data.tanggal_masuk) {
      validationErrors.tanggal_masuk = "Masukan Tanggal Masuk";
    }
    if (!data.tanggal_keluar) {
      validationErrors.tanggal_keluar = "Masukan Tanggal Keluar";
    }
    if (!data.fk_id_ruangan) {
      validationErrors.fk_id_ruangan = "Pilih Ruangan";
    }
    if (!data.metode_jenazah) {
      validationErrors.metode_jenazah = "Pilih Dimakamkan / Dikremasi";
    }
    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      setToOpenKonfirm();
    }
  };
  //========================================handling submit
  useEffect(() => {
    if (dataKonfirm === true) {
      if (data.nama_mendiang) {
        handleSubmit();
      }
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm();
  }, [dataKonfirm]);

  const [header, setHeader] = useState("application/x-www-form-urlencoded");

  const handleSubmit = async () => {
    console.log(data);
    let file = "";
    const formData = new FormData();
    if (!!upImg) {
      file = base64ToFile(upImg);
      formData.append("file", file, `${data.nama_mendiang}.png`);
      setHeader("multipart/form-data");
    }
    formData.append("pk_id_mendiang", data.pk_id_mendiang);
    formData.append("grenate_last_update", data.grenate_last_update);
    formData.append("nama_mendiang", data.nama_mendiang.trim());
    formData.append("tanggal_lahir", data.tanggal_lahir);
    formData.append("tanggal_wafat", data.tanggal_wafat);
    formData.append("path_image", data.path_image);
    formData.append("path_image_resume", data.path_image_resume);
    formData.append("path_image_resume_tv", data.path_image_resume_tv);
    formData.append("fk_id_rd", data.fk_id_rd);
    formData.append("fk_id_ruangan", data.fk_id_ruangan);
    formData.append("tanggal_masuk", data.tanggal_masuk);
    formData.append("tanggal_keluar", data.tanggal_keluar);
    formData.append("metode_jenazah", data.metode_jenazah);
    formData.append("fk_id_avatar", data.fk_id_avatar);
    formData.append("fk_id_user", user.pk_id_user);
    formData.append("path_html", data.path_html);
    formData.append("path_qr", data.path_qr);
    if (data.keluarga) {
      formData.append("keluarga", data.keluarga);
    }
    if (data.tempat_terakhir) {
      formData.append("tempat_terakhir", data.tempat_terakhir);
    }
    if (data.keterangan_mendiang) {
      formData.append("keterangan_mendiang", data.keterangan_mendiang);
    }
    const dataAcara = acaraEntries.filter(
      (item) => item.namaAcara !== "" && item.tanggalAcara !== ""
    );
    if (dataAcara.length > 0) {
      dataAcara.forEach((acara, index) => {
        if (acara.nama_acara && acara.tanggal_acara) {
          formData.append(`acara[${index}].nama_acara`, acara.nama_acara);
          formData.append(`acara[${index}].tanggal_acara`, acara.tanggal_acara);
        }
      });
    }
    if (data.tempat_wafat) {
      formData.append("tempat_wafat", data.tempat_wafat);
    }

    const url = process.env.REACT_APP_BASE_URL + "api/Mendiang/mendiang";
    await axios
      .put(url, formData, {
        headers: {
          "Content-Type": header,
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        history.push(`/daftar-mendiang`);
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat edit mendiang, Mohon  coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };
  //===============================================count age
  const getAge = () => {
    const tanggal_lahir = moment(data.tanggal_lahir, "YYYY-MM-DD hh:mm");
    const tanggal_wafat = moment(data.tanggal_wafat, "YYYY-MM-DD hh:mm");
    return tanggal_wafat.diff(tanggal_lahir, "years");
  };
  //===================================================get ruangan
  const getRoom = () => {
    const ruangan = dataRuangan.filter(
      (ruangan) => ruangan.pk_id_ruangan === data.fk_id_ruangan
    )[0];
    return `Ruangan ${ruangan.nama_ruangan} di lantai ${ruangan.lantai}`;
  };

  //===================================================familly
  const svgElementRef = useRef(null);

  const handleSVGReady = (svg) => {
    svgElementRef.current = svg;
  };

  const [inputKeluarga, setInputKeluarga] = useState("");
  const [textKeluarga, setTextKeluarga] = useState([""]);
  const handleInputChangeKeluarga = (e) => {
    const value = e.target.value;
    setInputKeluarga(value);
    const newValue = addEnter(value);
    setTextKeluarga(newValue.split("\n"));
    setData({ ...data, keluarga: newValue });
  };

  const addEnter = (value) => {
    if (svgElementRef) {
      const linesWithNewline = value.split("\n");
      let formattedLines = [];
      const elementBaris9 =
        svgElementRef.current.querySelector("#baris_keluarga_9");

      linesWithNewline.forEach((line) => {
        const words = line.split(" ");
        let currentLine = "";
        const localLines = [];

        // Iterate over each word and check width
        words.forEach((word) => {
          const tempLine = currentLine + word + " ";
          elementBaris9.textContent = tempLine;
          if (getWidth(elementBaris9) > twibbon.max_width_tv) {
            localLines.push(currentLine.trim());
            currentLine = word + " ";
          } else {
            currentLine = tempLine;
          }
          // Break if we exceed 9 lines overall
        });

        localLines.push(currentLine.trim());

        // Push the lines with words and account for the \n character
        formattedLines = [...formattedLines, ...localLines];
      });
      if (formattedLines.length > 9) {
        setErrors({
          ...errors,
          keluarga:
            "Input melebihi batas maksimum, teks yang melebihi batas tidak akan tersimpan",
        });
      } else {
        if (errors.keluarga) {
          setErrors((prevState) => {
            // Create a new object by spreading the previous state
            const { keluarga: removed, ...newState } = prevState;
            return newState; // Return the new object without the removed key
          });
        }
      }
      // Remove extra empty lines and limit the total to 9
      return formattedLines.slice(0, 9).join("\n");
    }
  };
  //=================================================
  const renderPreview = () => {
    if (svgTwibbon) {
      return (
        <SvgLoader svgXML={svgTwibbon} onSVGReady={handleSVGReady} id="svg">
          {data.tanggal_lahir && data.tanggal_wafat ? (
            <SvgProxy selector="#usia" visibility="visible">
              {`Usia ${getAge()} tahun`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#usia" visibility="hidden" />
          )}
          {selectedRD ? (
            <SvgProxy selector="#semayam" visibility="visible">
              {`Disemayamkan di Rumah Duka ${selectedRD.nama_rd}`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#semayam" visibility="hidden" />
          )}
          {data.fk_id_ruangan && dataRuangan ? (
            <SvgProxy selector="#ruangan" visibility="visible">
              {getRoom()}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#ruangan" visibility="hidden" />
          )}
          {data.tanggal_wafat && selectedRD ? (
            <SvgProxy selector="#tanggal_wafat" visibility="visible">
              {`Pada hari ${getIndonesianFormattedDateTime(
                data.tanggal_wafat
              )} ${defineTimezone(selectedRD.utc_difference_time)}`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#tanggal_wafat" visibility="hidden" />
          )}
          {data.tanggal_keluar && selectedRD ? (
            <SvgProxy selector="#tanggal_keluar" visibility="visible">
              {`Pada hari ${getIndonesianFormattedDateTime(
                data.tanggal_keluar
              )} ${defineTimezone(selectedRD.utc_difference_time)}`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#tanggal_keluar" visibility="hidden" />
          )}
          {data.metode_jenazah ? (
            <SvgProxy selector="#tempat_terakhir" visibility="visible">
              {`${data.metode_jenazah} di ${
                data.tempat_terakhir ? data.tempat_terakhir : "laksanakan"
              }`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#tempat_terakhir" visibility="hidden" />
          )}
          {selectedRD.nama_rd ? (
            <SvgProxy selector="#nama_rd" visibility="visible">
              {selectedRD.nama_rd}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#nama_rd" visibility="hidden" />
          )}
          {data.nama_mendiang ? (
            <SvgProxy selector="#nama_mendiang" visibility="visible">
              {data.nama_mendiang}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#nama_mendiang" visibility="hidden" />
          )}
          {data.keterangan_mendiang ? (
            <SvgProxy selector="#keterangan_mendiang" visibility="visible">
              {data.keterangan_mendiang}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#keterangan_mendiang" visibility="hidden" />
          )}
          {cropImg || data.path_image ? (
            <SvgProxy
              selector="#foto_mendiang"
              href={avatarShow()}
              visibility="visible"
            ></SvgProxy>
          ) : (
            <SvgProxy selector="#foto_mendiang" visibility="hidden" />
          )}
          {selectedRD ? (
            <SvgProxy
              href={process.env.REACT_APP_BASE_URL + selectedRD.path_image}
              selector="#logo"
              visibility="visible"
            ></SvgProxy>
          ) : (
            <SvgProxy selector="#logo" visibility="hidden" />
          )}
          {Array.from({ length: 12 }).map((_, index) => (
            <div key={index}>
              {index <
              (textKeluarga[0] !== "" && acaraEntries.length > 5
                ? 5
                : acaraEntries.length) ? (
                <SvgProxy
                  key={index}
                  selector={`#acara${index + 1}`}
                  visibility="visible"
                >
                  {`${
                    acaraEntries[index].nama_acara
                  } : ${getIndonesianFormattedDateTime(
                    acaraEntries[index].tanggal_acara
                  )} ${defineTimezone(selectedRD.utc_difference_time)}`}
                </SvgProxy>
              ) : (
                <SvgProxy
                  key={index}
                  selector={`#acara${index + 1}`}
                  visibility="hidden"
                />
              )}
            </div>
          ))}
          {acaraEntries?.length === 0 ? (
            <SvgProxy selector={`#tittle_jadwal`} visibility="hidden" />
          ) : (
            <SvgProxy selector={`#tittle_jadwal`} visibility="visible" />
          )}
          {textKeluarga[0] === "" ? (
            <div>
              <SvgProxy selector={`#daftar_keluarga`} visibility="hidden" />
              <SvgProxy selector={`#ucapan_akhir`} visibility="visible" />
            </div>
          ) : (
            <div>
              <SvgProxy selector={`#daftar_keluarga`} visibility="visible" />
              <SvgProxy selector={`#ucapan_akhir`} visibility="hidden" />
            </div>
          )}

          {Array.from({ length: 9 }).map((_, index) => (
            <div key={index}>
              {index < textKeluarga.length ? (
                <SvgProxy
                  key={index}
                  selector={`#baris_keluarga_${index + 1}`}
                  visibility={textKeluarga[index] === "" ? "hidden" : "visible"}
                >
                  {textKeluarga[index]}
                </SvgProxy>
              ) : (
                <SvgProxy
                  key={index}
                  selector={`#baris_keluarga_${index + 1}`}
                  visibility="hidden"
                />
              )}
            </div>
          ))}

          {acaraEntries.length > 10 || textKeluarga[0] !== "" ? (
            <SvgProxy selector={`#tittle`} visibility="hidden" />
          ) : (
            <SvgProxy selector={`#tittle`} visibility="visible" />
          )}
        </SvgLoader>
      );
    }
  };
  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Typography variant="h4" mt={4}>
            Form Edit Mendiang
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 5,
            }}
          >
            <Typography variant="h5">Preview Profile</Typography>
            <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
              <Box
                sx={{
                  width: 300,
                  height: 300,
                  borderRadius: 300,
                  position: "absolute",
                  overflow: "hidden",
                }}
              >
                <img
                  src={avatarShow()}
                  width={300}
                  height={300}
                  alt="nama mendiang"
                />
              </Box>
              <Box position="relative">
                <img src={Frame} width={305} alt="nama_mendiang" />
              </Box>
            </Box>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{ color: "white" }}
            >
              {" "}
              Upload Foto
            </Button>
          </Box>

          <TextField
            fullWidth
            required
            size="small"
            id="nama_mendiang"
            name="nama_mendiang"
            onChange={handleInputChange}
            label="Masukan nama mendiang"
            variant="outlined"
            value={data.nama_mendiang}
            sx={{ mt: 3 }}
            error={!!errors.nama_mendiang}
            helperText={errors.nama_mendiang}
          />
          <TextField
            fullWidth
            required
            size="small"
            id="keterangan_mendiang"
            name="keterangan_mendiang"
            onChange={handleInputChange}
            label="Masukan keterangan mendiang"
            variant="outlined"
            value={data.keterangan_mendiang}
            sx={{ mt: 3 }}
            error={!!errors.keterangan_mendiang}
            helperText={errors.keterangan_mendiang}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              mt: 2,
              gap: 2,
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                id="tanggal_lahir"
                label="Lahir"
                openTo="year"
                disableFuture
                format="DD MMM YYYY"
                value={dayjs(data.tanggal_lahir)}
                onChange={(e) =>
                  setData({
                    ...data,
                    tanggal_lahir: e.format("YYYY/MM/DD"),
                  })
                }
              />
              <FormHelperText error>{errors.tanggal_lahir}</FormHelperText>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <MobileDateTimePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                name="tanggal_wafat"
                id="tanggal_wafat"
                disableFuture
                label="Wafat"
                format="DD MMM YYYY hh:mm A"
                value={dayjs(data.tanggal_wafat)}
                ampm={false}
                onChange={(e) =>
                  setData({
                    ...data,
                    tanggal_wafat: e.format("YYYY/MM/DD hh:mm A"),
                  })
                }
              />
              <FormHelperText error>{errors.tanggal_wafat}</FormHelperText>
            </Box>
          </Box>
          <TextField
            fullWidth
            required
            size="small"
            id="tempat_wafat"
            name="tempat_wafat"
            onChange={handleInputChange}
            label="Tempat wafat mendiang"
            variant="outlined"
            value={data.tempat_wafat || " "}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            required
            size="small"
            id="rumah_duka"
            name="fk_id_rd"
            onChange={handleInputChange}
            label="Pilih Rumah Duka"
            variant="outlined"
            select
            disabled
            value={data.fk_id_rd}
            sx={{ mt: 3 }}
          >
            <MenuItem value="0">Pilih Rumah Duka...</MenuItem>
            {dataRD.map((option) => (
              <MenuItem key={option.pk_id_rd} value={option.pk_id_rd}>
                {option.nama_rd}
              </MenuItem>
            ))}
          </TextField>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              mt: 2,
              gap: 2,
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <MobileDateTimePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                name="tanggal_masuk"
                id="tanggal_masuk"
                label="Masuk Ruangan"
                format="DD MMM YYYY hh:mm A"
                ampm={false}
                value={dayjs(data.tanggal_masuk)}
                onChange={(e) => {
                  setData({
                    ...data,
                    tanggal_masuk: e.format("YYYY/MM/DD hh:mm A"),
                  });
                  if (data.tanggal_keluar) {
                    const new_in = convertDateFormat(
                      e.format("YYYY/MM/DD hh:mm A")
                    );
                    const new_out = convertDateFormat(data.tanggal_keluar);
                    const ruangan = {
                      new_in: new_in,
                      new_out: new_out,
                      fk_id_rd: data.fk_id_rd,
                    };
                    getRuangan(ruangan);
                  }
                }}
              />
              <FormHelperText error>{errors.tanggal_masuk}</FormHelperText>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <MobileDateTimePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                name="tanggal_keluar"
                id="tanggal_keluar"
                label="Keluar Ruangan"
                format="DD MMM YYYY hh:mm A"
                ampm={false}
                value={dayjs(data.tanggal_keluar)}
                onChange={(e) => {
                  setData({
                    ...data,
                    tanggal_keluar: e.format("YYYY/MM/DD hh:mm A"),
                  });
                  const new_in = convertDateFormat(data.tanggal_masuk);
                  const new_out = convertDateFormat(
                    e.format("YYYY/MM/DD hh:mm A")
                  );
                  const ruangan = {
                    new_in: new_in,
                    new_out: new_out,
                    fk_id_rd: data.fk_id_rd,
                  };
                  getRuangan(ruangan);
                }}
              />
              <FormHelperText error>{errors.tanggal_keluar}</FormHelperText>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <TextField
                fullWidth
                required
                size="small"
                id="ruangan"
                name="fk_id_ruangan"
                onChange={handleInputChange}
                label="Ruangan Tersedia"
                variant="outlined"
                select
                value={data.fk_id_ruangan}
                error={!!errors.fk_id_ruangan}
              >
                <MenuItem value="0">Pilih Ruangan...</MenuItem>
                {dataRuangan.map((option) => (
                  <MenuItem
                    disabled={option.tanggal_masuk ? true : false}
                    key={option.pk_id_ruangan}
                    value={option.pk_id_ruangan}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {option.nama_ruangan}
                      {option.tanggal_masuk && (
                        <Typography fontSize={10}>
                          Dipesan {convertDateFormatView(option.tanggal_masuk)}{" "}
                          - {convertDateFormatView(option.tanggal_keluar)}
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText error>{errors.fk_id_ruangan}</FormHelperText>
            </Box>
          </Box>
          <Typography variant="h5" sx={{ mt: 3 }}>
            Informasi Acara
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography mr={2}>Jenazah di :</Typography>

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="metode_jenazah"
              row
              value={data.metode_jenazah}
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="Krematorium"
                control={<Radio size="small" />}
                label="Kremasi"
              />
              <FormControlLabel
                value="Pemakaman"
                control={<Radio size="small" />}
                label="Makamkan"
              />
            </RadioGroup>
          </Box>
          <FormHelperText error>{errors.metode_jenazah}</FormHelperText>
          <TextField
            fullWidth
            required
            size="small"
            id="tempat_terakhir"
            name="tempat_terakhir"
            onChange={handleInputChange}
            label="Tempat terakhir mendiang"
            variant="outlined"
            value={data.tempat_terakhir || " "}
            sx={{ mt: 2 }}
          />
          <Button
            color="success"
            variant="contained"
            size="small"
            sx={{ borderRadius: "99px", mt: 3, mb: 1, color: "white" }}
            onClick={() => {
              handleAddRow();
            }}
          >
            Tambah Detail Acara
          </Button>
          {acaraEntries.map((entry, index) => (
            <Grid
              key={index}
              container
              spacing={1}
              alignItems={"center"}
              mt={2}
            >
              <Grid item xs={9} md={5}>
                <Autocomplete
                  id="nama_acara_autocompleate"
                  freeSolo
                  value={entry.nama_acara}
                  onChange={(e) =>
                    handleInputChangeAcara(
                      index,
                      e.target.textContent,
                      "nama_acara"
                    )
                  }
                  options={reqAcara.map((option) => option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      id="nama_acara"
                      name="nama_acara"
                      label="Nama Acara"
                      variant="outlined"
                      value={entry.nama_acara}
                      onChange={(e) =>
                        handleInputChangeAcara(
                          index,
                          e.target.value,
                          "nama_acara"
                        )
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9} md={4}>
                <MobileDateTimePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  name="tanggal_acara"
                  id="tanggal_acara"
                  label="Tanggal Acara"
                  format="DD MMM YYYY hh:mm A"
                  ampm={false}
                  value={dayjs(entry.tanggal_acara)}
                  minDateTime={dayjs(data.tanggal_masuk)}
                  maxDateTime={dayjs(data.tanggal_keluar).add(1, "day")}
                  onChange={(e) =>
                    handleInputChangeAcara(
                      index,
                      e.format("YYYY/MM/DD hh:mm A"),
                      "tanggal_acara"
                    )
                  }
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: "99px",
                      m: 1,
                      color: "white",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      handleRemoveRow(index);
                    }}
                  >
                    Hapus Acara
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ))}
          <TextField
            size="small"
            fullWidth
            id="keluarga"
            name="keluarga"
            onChange={handleInputChangeKeluarga}
            variant="outlined"
            multiline
            label="Keluarga yang ditinggalkan"
            sx={{ mt: 2 }}
            error={!!errors.keluarga}
            helperText={errors.keluarga}
            value={inputKeluarga}
            // inputProps={{
            //   maxLength: maxInputMessage, // Set the maximum character limit
            // }}
          />
          <Box width={"100%"} mt={3}>
            <div id="svg-container">{renderPreview()}</div>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              mt: 5,
            }}
          >
            <LoadingButton
              endIcon={<Person />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              variant="contained"
              sx={{ borderRadius: "100px", color: "white" }}
              onClick={() => {
                handleLoading();
                checkValidate();
              }}
            >
              Simpan
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={styleBoxModal}>
          <Typography id="modal-modal-title" variant="h6" mb={3}>
            Upload Photo Profile
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              width={show}
              height={show}
              onClose={closeAvatar}
              onCrop={cropAvatar}
            />
          </Box>
          <Button
            onClick={saveImg}
            variant="contained"
            sx={{ color: "white", mt: 3 }}
          >
            Simpan
          </Button>
        </Box>
      </Modal>
    </Layout>
  );
}

export default EditMendiang;
