import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link as DirectLink } from "react-router-dom";
import HeaderBtnLogin from "./HeaderBtnLogin";
import HeaderBtnLogout from "./HeaderBtnLogout";
import Logo from "../../Assets/Logo.svg";
import { useGlobal } from "../../Helper/Context/GlobalContext.js";
import ModalRespose from "../../Page/Response/ModalResponse";
import Confirm from "../Confirm";

function Header() {
  const registerContext = useGlobal();
  const {
    setOpenSidebar,
    dataResponse,
    openResponse,
    setToCloseResponse,
    openKonfirm,
    setToCloseKonfirm,
  } = registerContext;
  return (
    <AppBar position="sticky" component="nav">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <ModalRespose
          openResponse={openResponse}
          setToCloseResponse={setToCloseResponse}
          data={dataResponse}
        />
        <Confirm
          openKonfirm={openKonfirm}
          setToCloseKonfirm={setToCloseKonfirm}
        />
        <Box
          sx={{
            display: "flex",
            color: "black",
            m: 1,
            textDecoration: "none",
            alignItems: "center",
          }}
          onClick={() => {
            setOpenSidebar();
          }}
        >
          <img src={Logo} alt="logo bela sungkawa" width={30} />

          <Typography
            variant="h6"
            fontFamily="Affinity Future"
            color={"white"}
            ml={1}
          >
            InMemoriam.id
          </Typography>
        </Box>
        {sessionStorage.getItem("token") ? (
          <HeaderBtnLogin />
        ) : (
          <HeaderBtnLogout />
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
