export function convertDateFormat(inputDate) {
    // Parse the input date string
    const parsedDate = new Date(inputDate);

    // Check if the parsed date is valid
    if (isNaN(parsedDate)) {
      return "Invalid Date";
    }

    // Format the date in the desired format "YYYY-MM-DDTHH:mm:ss"
    const formattedDate = `${parsedDate.getFullYear()}-${String(
      parsedDate.getMonth() + 1
    ).padStart(2, "0")}-${String(parsedDate.getDate()).padStart(
      2,
      "0"
    )}T${String(parsedDate.getHours()).padStart(2, "0")}:${String(
      parsedDate.getMinutes()
    ).padStart(2, "0")}:${String(parsedDate.getSeconds()).padStart(2, "0")}`;

    return formattedDate;
  }
  export function convertDateFormatView(inputDate) {
    // Parse the input date string
    
    const parsedDate = new Date(inputDate);

    // Check if the parsed date is valid
    if (isNaN(parsedDate)) {
      return "Invalid Date";
    }

    // Format the date in the desired format "YYYY/MM/DD hh:mm"
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return formattedDate;
  }

  export const getIndonesianFormattedDateTime = (selectedDate) => { 
    const options = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Asia/Jakarta', // Set the timezone to Indonesia's timezone
    };
    if(selectedDate){
      return indonesianFormatDate(selectedDate,options);
    }else{
      return ""
    }

  };
  export const getIndonesianFormattedDate = (selectedDate) => {
    const options = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'Asia/Jakarta', // Set the timezone to Indonesia's timezone
    };
    if(selectedDate){
      return indonesianFormatDate(selectedDate,options);
    }else{
      return ""
    }
  };

  export const getIndonesianFormattedDateOnly = (selectedDate) => {
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'Asia/Jakarta', // Set the timezone to Indonesia's timezone
    };
    if(selectedDate){
      return indonesianFormatDate(selectedDate,options);
    }else{
      return ""
    }
  };

  function indonesianFormatDate(selectedDate, options){
    const inputDate = new Date(selectedDate);
    const indonesianFormattedDate = new Intl.DateTimeFormat('id-ID', options).format(inputDate);
    return indonesianFormattedDate;
  }

  export function defineTimezone(utc_difference_time){
    
    switch (utc_difference_time) {
        case 7:
            return "WIB";
        case 8:
            return "WITA";
        case 9:
            return "WIT";
        default:
            return " ";
    }
}
  