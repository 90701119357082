import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../Components/Layout";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ErrorBox from "../../Components/ErrorBox";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { SaveAs } from "@mui/icons-material";
import jwt from "jwt-decode";
import { baseStyle } from "../../Helper/style/style";
//===============================================style dropzone

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
//========================================end dropzone style
function FormTambahTemplate() {
  //==================================================Context
  const masukContext = useGlobal();
  const { loginToken, openErrorBox, setErrorServer } = masukContext;
  //=========================================================authorize

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role != "super") {
      history.push("/");
    }
  };
  //=======================================dropzone handle svg
  const [SVGFile, setSVGFile] = useState();

  const {
    getRootProps: getRootSVGProps,
    getInputProps: getInputSVGProps,
    isFocused: isFocusedSVG,
    isDragAccept: isDragAcceptSVG,
    isDragReject: isDragRejectSVG,
    isDragActive: isDragActiveSVG,
    acceptedFiles: acceptedFilesSVG,
  } = useDropzone({ accept: { "image/svg+xml": [".svg"] } });

  const stylesvg = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedSVG ? focusedStyle : {}),
      ...(isDragAcceptSVG ? acceptStyle : {}),
      ...(isDragRejectSVG ? rejectStyle : {}),
    }),
    [isFocusedSVG, isDragAcceptSVG, isDragRejectSVG]
  );

  useEffect(() => {
    setSVGFile(acceptedFilesSVG[0]);
  }, [acceptedFilesSVG]);

  const hapusSvg = () => {
    setSVGFile();
  };

  //=======================================dropzone handle thumb
  const [file, setFile] = useState(null);
  const {
    getRootProps: getRootThumbProps,
    getInputProps: getInputThumbProps,
    isFocused: isFocusedThumb,
    isDragAccept: isDragAcceptThumb,
    isDragReject: isDragRejectThumb,
    isDragActive: isDragActiveThumb,
    acceptedFiles: acceptedFilesThumb,
  } = useDropzone({ accept: { "image/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedThumb ? focusedStyle : {}),
      ...(isDragAcceptThumb ? acceptStyle : {}),
      ...(isDragRejectThumb ? rejectStyle : {}),
    }),
    [isFocusedThumb, isDragAcceptThumb, isDragRejectThumb]
  );
  const createLink = () => {
    if (acceptedFilesThumb.length !== 0) {
      setFile(URL.createObjectURL(acceptedFilesThumb[0]));
    }
  };
  useEffect(() => {
    createLink();
  }, [acceptedFilesThumb]);
  //========================================data category
  const dataReligi = ["General", "Kristen", "Budha", "Hindu", "Islam"];
  const dataBahasa = ["Indonesia", "English"];
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //========================================edit handle
  const params = useParams();
  const getData = () => {
    if (params.id) {
      const getUrl =
        process.env.REACT_APP_BASE_URL +
        "api/Template/TemplateById?id=" +
        params.id;
      axios
        .get(getUrl)
        .then((response) => {
          setData(response.data);
          setFile(
            process.env.REACT_APP_BASE_URL +
              response.data.path_thumb_template.replace(/ /g, "%20")
          );
        })
        .catch((error) => {
          if (error.response.data.length < 100) {
            setErrorServer(error.response.data);
          } else {
            setErrorServer(
              "Terjadi error saat mengambil data template, Mohon coba lagi"
            );
          }
          openErrorBox();
        });
    }
  };

  useEffect(() => {
    getData();
    auth();
  }, []);

  //========================================handling input

  const initialState = {
    nama_template: "",
    religi: 0,
    bahasa: 0,
    max_width_mendiang: 1200,
    max_width_sender: 1200,
    max_width_message: 1200,
    transform_logo: false,
  };
  const [data, setData] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleChangeLogo = (event) => {
    setData({ ...data, transform_logo: event.target.checked });
  };
  //==============================================================validation

  const [errors, setErrors] = useState({
    nama_template: "",
    deskripsi: "",
  });

  const checkValidate = (action_button) => {
    const validationErrors = {};
    if (!SVGFile && !data.path_image_template) {
      validationErrors.path_image_template = "Masukan svg";
    }
    if (!file && !data.path_thumb_template) {
      validationErrors.path_thumb_template = "Masukan Thumbnail";
    }
    if (!data.nama_template) {
      validationErrors.nama_template = "Masukan Nama Template";
    }
    if (!data.religi) {
      validationErrors.religi = "Masukan Religi";
    }
    if (!data.bahasa) {
      validationErrors.bahasa = "Masukan Bahasa";
    }
    if (!data.max_width_mendiang || data.max_width_mendiang == 0) {
      validationErrors.max_width_mendiang =
        "Masukan maksimal lebar tulisan mendiang";
    }
    if (!data.max_width_sender || data.max_width_sender == 0) {
      validationErrors.max_width_sender =
        "Masukan maksimal lebar tulisan pengirim";
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      action_button();
    }
  };

  //=========================================handle submit edit
  const handleSubmitEdit = async () => {
    const formData = new FormData();
    if (SVGFile) {
      formData.append("svg", SVGFile);
    } else {
      formData.append("path_image_template", data.path_image_template);
    }
    if (acceptedFilesThumb[0]) {
      formData.append("thumb", acceptedFilesThumb[0]);
    } else {
      formData.append("path_thumb_template", data.path_thumb_template);
    }
    formData.append("nama_template", data.nama_template.trim());
    formData.append("pk_id_template", data.pk_id_template);
    formData.append("religi", data.religi);
    formData.append("bahasa", data.bahasa);
    formData.append("max_width_mendiang", data.max_width_mendiang);
    formData.append("max_width_sender", data.max_width_sender);
    formData.append("transform_logo", data.transform_logo);
    formData.append("max_width_message", data.max_width_message);

    const url = process.env.REACT_APP_BASE_URL + "api/template/template";
    await axios
      .put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        history.push(`/template/daftar`);
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat menambah, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };
  //=========================================handle submit

  const handleSubmit = async () => {
    if (acceptedFilesThumb.length === 0 || acceptedFilesSVG.length === 0) {
      setErrorServer("Foto Belum di Upload");
      openErrorBox();
      return;
    }

    // const file = new Blob(acceptedFiles[0], { type: 'image/png' });

    const formData = new FormData();
    formData.append("svg", acceptedFilesSVG[0]);
    formData.append("thumb", acceptedFilesThumb[0]);
    formData.append("nama_template", data.nama_template.trim());
    formData.append("religi", data.religi);
    formData.append("bahasa", data.bahasa);
    formData.append("max_width_mendiang", data.max_width_mendiang);
    formData.append("max_width_sender", data.max_width_sender);
    formData.append("transform_logo", data.transform_logo);
    formData.append("max_width_message", data.max_width_message);

    const url = process.env.REACT_APP_BASE_URL + "api/template/template";
    await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        history.push(`/template/daftar`);
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer(
            "Terjadi error saat menambahkan template, Mohon coba lagi"
          );
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", mt: 7 }}>
            <ErrorBox />

            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5">Upload Thumbnail</Typography>
                <Typography mb={5}>
                  Eksistensi file jpg/png dengan maks 200kb
                </Typography>
                {file ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img width={"80%"} src={file} alt="uploaded file" />
                  </Box>
                ) : (
                  <Box sx={{}} />
                )}
                <Box my={3} />
                <Box {...getRootThumbProps({ style })} className="dropzone">
                  <input type="file" {...getInputThumbProps()} />
                  {isDragAcceptThumb && <p>All files will be accepted</p>}
                  {isDragRejectThumb && <p>Some files will be rejected</p>}
                  {isDragActiveThumb
                    ? "Drop the files here ..."
                    : "Drag 'n' drop some files here, or click to select files"}
                </Box>
                <FormHelperText error>
                  {errors.path_thumb_template}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5">Upload SVG File</Typography>
                <Typography mb={5}>
                  Pastikan kode SVG telah diberikan id untuk editor
                </Typography>
                {SVGFile ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>{SVGFile.name}</Typography>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ borderRadius: "100px", color: "white" }}
                      onClick={() => {
                        hapusSvg();
                      }}
                    >
                      Batal
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "20px",
                        borderWidth: 2,
                        borderRadius: 2,
                        borderColor: "#eeeeee",
                        borderStyle: "dashed",
                        backgroundColor: "#fafafa",
                        color: "#bdbdbd",
                        outline: "none",
                        transition: "border .24s ease-in-out",
                        width: "100%",
                        mt: 5,
                      }}
                      {...getRootSVGProps({ stylesvg })}
                    >
                      <input {...getInputSVGProps()} />
                      {isDragAcceptSVG && <p>File will be accepted</p>}
                      {isDragRejectSVG && <p>File will be rejected</p>}
                      {isDragActiveSVG
                        ? "Drop the files here ..."
                        : "Drag 'n' drop some files here, or click to select files"}
                    </Box>
                    <FormHelperText error>
                      {errors.path_image_template}
                    </FormHelperText>
                  </Box>
                )}

                <TextField
                  fullWidth
                  size="small"
                  id="nama_template"
                  name="nama_template"
                  value={data.nama_template}
                  onChange={handleInputChange}
                  label="Judul template"
                  variant="outlined"
                  sx={{ mt: 3 }}
                  error={!!errors.nama_template}
                  helperText={errors.nama_template}
                />

                <Box sx={{ display: "flex", width: "100%", gap: "10px" }}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    id="religi"
                    name="religi"
                    onChange={handleInputChange}
                    label="Pilih Religi"
                    variant="outlined"
                    select
                    value={data.religi}
                    sx={{ mt: 3 }}
                    error={!!errors.religi}
                    helperText={errors.religi}
                  >
                    <MenuItem value="0">Pilih Religi...</MenuItem>
                    {dataReligi.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    fullWidth
                    required
                    size="small"
                    id="bahasa"
                    name="bahasa"
                    onChange={handleInputChange}
                    label="Pilih Bahasa"
                    variant="outlined"
                    select
                    value={data.bahasa}
                    sx={{ mt: 3 }}
                    error={!!errors.bahasa}
                    helperText={errors.bahasa}
                  >
                    <MenuItem value="0">Pilih Bahasa...</MenuItem>
                    {dataBahasa.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                    <Typography>Logo dapat digeser</Typography>
                    <Switch
                      checked={data.transform_logo || false}
                      onChange={handleChangeLogo}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  required
                  size="small"
                  id="max_width_mendiang"
                  name="max_width_mendiang"
                  onChange={handleInputChange}
                  label="Maksimal lebar tulisan mendiang"
                  variant="outlined"
                  value={data.max_width_mendiang || ""}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  error={!!errors.max_width_mendiang}
                  helperText={errors.max_width_mendiang}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  required
                  size="small"
                  id="max_width_sender"
                  name="max_width_sender"
                  onChange={handleInputChange}
                  label="Maksimal lebar tulisan pengirim"
                  variant="outlined"
                  value={data.max_width_sender || ""}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  error={!!errors.max_width_sender}
                  helperText={errors.max_width_sender}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  required
                  size="small"
                  id="max_width_message"
                  name="max_width_message"
                  onChange={handleInputChange}
                  label="Maksimal lebar tulisan pesan"
                  variant="outlined"
                  value={data.max_width_message || ""}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  error={!!errors.max_width_message}
                  helperText={errors.max_width_message}
                />
              </Grid>
            </Grid>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
              <LoadingButton
                endIcon={<SaveAs />}
                loading={loadingSts}
                loadingPosition="end"
                variant="contained"
                sx={{ borderRadius: "100px", color: "white", mt: 4 }}
                onClick={() => {
                  handleLoading();
                  params.id
                    ? checkValidate(handleSubmitEdit)
                    : checkValidate(handleSubmit);
                }}
              >
                Simpan
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default FormTambahTemplate;
