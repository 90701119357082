import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Typography,
  Modal,
  Fade,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import { Link as DirectLink } from "react-router-dom";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import "../../Helper/format/currency";
import { Search } from "@mui/icons-material";
import ErrorBox from "../../Components/ErrorBox";
import {
  styleBoxModal,
  StyledTableCell,
  StyledTableRow,
} from "../../Helper/style/style";

function PesanKu() {
  //==================================================Context
  const masukContext = useGlobal();
  const { loginToken, user, openErrorBox, setErrorServer } = masukContext;
  //=========================================================authorize
  const history = useHistory();

  const auth = () => {
    if (!loginToken) {
      history.push("/");
    }
  };
  //==============================================================Get Data
  const [data, setData] = useState([]);

  const getDaftarPesan = async (/*userID*/) => {
    if(user.pk_id_user){
      try {
        const url =
          process.env.REACT_APP_BASE_URL +
          `api/pesan/pesan/user/${user.pk_id_user}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `bearer ${loginToken}`,
          },
        });
        setData(
          response.data.map((e) => {
            return {
              nama_mendiang: e.nama_mendiang,
              username_pengirim: e.username_pengirim,
              pk_id_pesan: e.pk_id_pesan,
              fk_id_user: e.fk_id_user,
              kode_invoice: e.kode_invoice,
              no_urut: e.no_urut,
              image_pesan_path: e.image_pesan_path,
              harga: e.harga,
            };
          })
        );
        setSearchData(
          response.data.map((e) => {
            return {
              nama_mendiang: e.nama_mendiang,
              username_pengirim: e.username_pengirim,
              pk_id_pesan: e.pk_id_pesan,
              fk_id_user: e.fk_id_user,
              kode_invoice: e.kode_invoice,
              no_urut: e.no_urut,
              image_pesan_path: e.image_pesan_path,
              harga: e.harga,
            };
          })
        );
      } catch (error) {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer(
            "Terjadi error saat pengambilan data pesan, Mohon coba lagi"
          );
        }
        openErrorBox();
      }
    }
  };

  useEffect(() => {
    getDaftarPesan(/*usrid*/);
    auth();
  }, [user]);
  // ===================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");
  function searchFunction(query) {
    query = query.toLowerCase(); // Convert the query to lowercase for case-insensitive search

    return data.filter((searchText) => {
      const filteredText = searchText.username_pengirim
        .toLowerCase()
        .includes(query);
      const filteredText2 = (
        searchText.kode_invoice +
        " - " +
        searchText.no_urut
      )
        .toLowerCase()
        .includes(query);
      return filteredText || filteredText2;
    });
  }

  const handleInputChangeSearch = (e) => {
    const { name, value } = e.target;
    // setInputSearchData(value)
    setInputSearchData({
      ...inputSearchData,
      [name]: value,
    });
    setSearchData(searchFunction(value));
  };

  //================================================================view modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showImg, setShowImg] = useState();

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 5,
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" mr={2}>
                  Daftar Pesanku
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  placeholder="Search…"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  name="searchText"
                  onChange={handleInputChangeSearch}
                  value={inputSearchData.searchText}
                />
              </Grid>
            </Grid>
            <Box>
              <Button
                component={DirectLink}
                to="/daftar-mendiang"
                variant="contained"
                sx={{ borderRadius: "100px", color: "white" }}
              >
                Tambah
              </Button>
            </Box>
          </Box>
          <TableContainer sx={{ my: 5 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Invoice ID</StyledTableCell>
                  <StyledTableCell align="center">
                    Nama Pengirim
                  </StyledTableCell>
                  <StyledTableCell align="center">Kepada</StyledTableCell>
                  <StyledTableCell align="center">Harga</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.kode_invoice + " - " + row.no_urut}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.username_pengirim}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.nama_mendiang}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.harga.currency()}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Button
                        color="ok"
                        variant="contained"
                        size="small"
                        sx={{ m: 1, color: "white" }}
                        onClick={() => {
                          setShowImg(row.image_pesan_path);
                          handleOpen();
                        }}
                      >
                        Lihat Ucapan
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleBoxModal}>
            <img
              src={process.env.REACT_APP_BASE_URL + showImg}
              style={{ width: "100%" }}
              alt=""
            />
          </Box>
        </Fade>
      </Modal>
    </Layout>
  );
}

export default PesanKu;
