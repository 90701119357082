import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Sidebar from "./Sidebar";
import { Box } from "@mui/material";
export default function Layout({ children }) {
  return (
    <>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100vh",
          boxSizing: "border-box",
        }}
      >
        <Box>
          <Header />
          <Box sx={{ mb: 3 }}>{children}</Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}
