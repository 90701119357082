import React, { useEffect, useRef, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";
import Frame from "../../Assets/Avatar_frame.webp";
import Avatar from "react-avatar-edit";
import defAvatar from "../../Assets/AvatarDefault.svg";
import { DatePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import jwt from "jwt-decode";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { Download } from "@mui/icons-material";
import dayjs from "dayjs";
import { styleBoxModal } from "../../Helper/style/style";
import {
  convertDateFormat,
  defineTimezone,
  getIndonesianFormattedDateTime,
} from "../../Helper/format/date";
import { SvgLoader, SvgProxy } from "react-svgmt";
import ResumeTemplateModal from "../../Components/ResumeTemplate/ResumeTemplateModal";
import moment from "moment";
import domtoimage from "dom-to-image";
import { base64ToFile } from "../../Helper/image/imageFunction";
import { getWidth } from "../../Helper/image/useImageEditor";
function CustomTwibbon() {
  //==================================================Context
  const masukContext = useGlobal();
  const { loginToken, user, openErrorBox, setErrorServer } = masukContext;
  //=========================================================authorize

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role !== "admin" && token.role !== "super") {
      history.push("/");
    }
  };

  useEffect(() => {
    if (user.role === "admin") {
      getRD(user.fk_id_rd);
    } else {
      getListRD();
    }
  }, [user]);
  //-----------------------------------------------responsive
  const [show, setShow] = useState(0);
  useEffect(() => {
    const calculate_contaner = () => {
      const containerWidth = window.innerWidth;
      const containerHeight = window.innerHeight;
      if (containerHeight > containerWidth) {
        setShow((containerWidth * 60) / 100);
      } else {
        setShow((containerHeight * 50) / 100);
      }
    };
    calculate_contaner();
    // Attach the event listener for window resize
    window.addEventListener("resize", calculate_contaner);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", calculate_contaner);
    };
  }, []);
  //================================================SELECT TWIBBON TEMPLATE
  const [modalResume, setModalResume] = useState(false);
  const [selectedTemplateResume, setSelectedTemplateResume] = useState(false);
  const closeModalResume = (id) => {
    setModalResume(false);
    setData({
      ...data,
      fk_id_resume_template: id,
    });
    if (id) {
      setSelectedTemplateResume(
        dataTemplateResume.filter((item) => item.pk_id_resume_template == id)[0]
      );
    }
  };
  const openModalResume = () => {
    setModalResume(true);
  };
  const [dataTemplateResume, setDataTemplateResume] = useState([]);

  const getDaftarTemplateResume = async (/*userID*/) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/ResumeTemplate/resume-template/" +
      selectedRD.pk_id_rd;
    try {
      const response = await axios.get(url);
      setDataTemplateResume(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data resume template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  //============================================ svg
  const [svgTwibbon, setSvgTwibbon] = useState();
  const svgUrl =
    process.env.REACT_APP_BASE_URL +
    "api/ResumeTemplate/file?pk_id_resume_template=" +
    selectedTemplateResume.pk_id_resume_template +
    "&is_tv_twibbon=false";

  useEffect(() => {
    if (selectedTemplateResume) {
      getSvg();
    }
  }, [selectedTemplateResume]);

  const getSvg = () => {
    try {
      axios.get(svgUrl).then((response) => {
        setSvgTwibbon(response.data);
      });
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  //============================================modal Upload
  const [modal, setModal] = useState(false);
  const handleClose = () => {
    setModal(false);
  };
  const handleOpen = () => {
    setModal(true);
  };

  //========================================handling avatar
  const [upImg, setUpImg] = useState();
  const [cropImg, setCropImg] = useState(null);

  const closeAvatar = () => {
    setCropImg(null);
  };
  const cropAvatar = (view) => {
    setCropImg(view);
  };
  const saveImg = () => {
    setUpImg(cropImg);
    setModal(false);
  };

  const avatarShow = () => {
    return !!upImg ? upImg : defAvatar;
  };

  //========================================get rd
  const [dataRD, setDataRD] = useState([]);
  const [selectedRD, setSelectedRD] = useState({ pk_id_rd: 0 });

  const getListRD = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/RumahDuka/RumahDuka";
    try {
      const response = await axios.get(url);
      setDataRD(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  const getRD = async (id_rd) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/RumahDuka/RumahDukaById?id=" +
      id_rd;
    try {
      const response = await axios.get(url);
      setSelectedRD(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  const handleRDChange = (id_rd) => {
    setSelectedRD(dataRD.filter((RD) => RD.pk_id_rd === id_rd)[0]);
  };

  useEffect(() => {
    if (selectedRD.pk_id_rd > 0) {
      getAvatarRD(selectedRD.path_image);
    }
    getDaftarTemplateResume();
  }, [selectedRD]);

  const getAvatarRD = async (path_image) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/RumahDuka/avatar?path_image=" +
      path_image;
    try {
      const response = await axios.get(url);
      setData({
        ...data,
        logo: `data:image/png;base64,${response.data}`,
        nama_rd: selectedRD.nama_rd,
      });
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data avatar rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  //============================================================================ROW ACARA
  const reqAcara = [
    "Masuk Peti",
    "Tutup Peti",
    "Kebaktian",
    "Kebaktian Pelepasan",
  ];

  const [acaraEntries, setAcaraEntries] = useState([]);
  const handleInputChangeAcara = (index, value, name) => {
    const updatedAcaraEntries = [...acaraEntries];

    updatedAcaraEntries[index][name] = value;

    setAcaraEntries(updatedAcaraEntries);
  };
  const handleAddRow = () => {
    setAcaraEntries([...acaraEntries, { nama_acara: "", tanggal_acara: "" }]);
  };

  const handleRemoveRow = (index) => {
    const updatedAcaraEntries = [...acaraEntries];
    updatedAcaraEntries.splice(index, 1);
    setAcaraEntries(updatedAcaraEntries);
  };

  //========================================handling input
  const initialState = {
    nama_mendiang: "",
    tanggal_wafat: "",
    tanggal_keluar: "",
    tanggal_masuk: "",
    metode_jenazah: "",
    tempat_terakhir: "",
    tempat_wafat: "",
    usia: 0,
    nama_rd: "",
    nama_ruangan: "",
    lantai: 0,
    foto_mendiang: "",
    logo: "",
    acara: "",
    keterangan_mendiang: "",
    tempat_bersemayam: "",
    timezone: "",
    keluarga: "",
  };
  const [data, setData] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }

  //==============================================================validation

  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    const validationErrors = {};
    if (!data.nama_mendiang) {
      validationErrors.nama_mendiang = "Masukan Nama Mendiang";
    }
    if (!data.tempat_bersemayam) {
      validationErrors.tempat_bersemayam = "Masukan Tempat Bersemayam";
    }
    if (!data.tanggal_lahir) {
      validationErrors.tanggal_lahir = "Masukan Tanggal Lahir";
    }
    if (!data.tanggal_wafat) {
      validationErrors.tanggal_wafat = "Masukan Tanggal Wafat";
    }
    if (!data.tanggal_masuk) {
      validationErrors.tanggal_masuk = "Masukan Tanggal Masuk";
    }
    if (!data.tanggal_keluar) {
      validationErrors.tanggal_keluar = "Masukan Tanggal Keluar";
    }
    if (!data.metode_jenazah) {
      validationErrors.metode_jenazah = "Pilih Dimakamkan / Dikremasi";
    }
    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      convertToBase64();
    }
  };
  //===============================================count age
  const getAge = () => {
    const tanggal_lahir = moment(data.tanggal_lahir, "YYYY-MM-DD hh:mm");
    const tanggal_wafat = moment(data.tanggal_wafat, "YYYY-MM-DD hh:mm");
    return tanggal_wafat.diff(tanggal_lahir, "years");
  };

  //===================================================familly
  const svgElementRef = useRef(null);

  const handleSVGReady = (svg) => {
    svgElementRef.current = svg;
  };

  const [textKeluarga, setTextKeluarga] = useState([""]);
  const handleInputChangeKeluarga = (e) => {
    const value = e.target.value;
    console.log(value);
    const newValue = addEnter(value);
    setTextKeluarga(newValue.split("\n"));
    setData({ ...data, keluarga: newValue });
  };

  const addEnter = (value) => {
    if (svgElementRef) {
      const linesWithNewline = value.split("\n");
      let formattedLines = [];
      const elementBaris9 =
        svgElementRef.current.querySelector("#baris_keluarga_9");

      linesWithNewline.forEach((line) => {
        const words = line.split(" ");
        console.log(words);
        let currentLine = "";
        const localLines = [];

        // Iterate over each word and check width
        words.forEach((word) => {
          const tempLine = currentLine + word + " ";
          elementBaris9.textContent = tempLine;
          if (getWidth(elementBaris9) > selectedTemplateResume.max_width_tv) {
            localLines.push(currentLine.trim());
            currentLine = word + " ";
          } else {
            currentLine = tempLine;
          }
          // Break if we exceed 9 lines overall
        });

        localLines.push(currentLine.trim());
        // Push the lines with words and account for the \n character
        formattedLines = [...formattedLines, ...localLines];
      });
      if (formattedLines.length > 9) {
        setErrors({
          ...errors,
          keluarga:
            "Input melebihi batas maksimum, teks yang melebihi batas tidak akan tersimpan",
        });
      } else {
        if (errors.keluarga) {
          setErrors((prevState) => {
            // Create a new object by spreading the previous state
            const { keluarga: removed, ...newState } = prevState;
            return newState; // Return the new object without the removed key
          });
        }
      }
      // Remove extra empty lines and limit the total to 9
      return formattedLines.slice(0, 9).join("\n");
    }
  };
  //=================================================
  const renderPreview = () => {
    if (svgTwibbon) {
      return (
        <SvgLoader svgXML={svgTwibbon} onSVGReady={handleSVGReady} id="svg">
          {data.tanggal_lahir && data.tanggal_wafat ? (
            <SvgProxy selector="#usia" visibility="visible">
              {`Usia ${getAge()} tahun`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#usia" visibility="hidden" />
          )}
          {data.tempat_bersemayam ? (
            <SvgProxy selector="#semayam" visibility="visible">
              {`Disemayamkan di Rumah Duka ${data.tempat_bersemayam}`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#semayam" visibility="hidden" />
          )}
          {data.nama_ruangan && data.lantai ? (
            <SvgProxy selector="#ruangan" visibility="visible">
              {`Ruangan ${data.nama_ruangan} di lantai ${data.lantai}`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#ruangan" visibility="hidden" />
          )}
          {data.tanggal_wafat && selectedRD ? (
            <SvgProxy selector="#tanggal_wafat" visibility="visible">
              {`Pada hari ${getIndonesianFormattedDateTime(
                data.tanggal_wafat
              )} ${data.timezone}`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#tanggal_wafat" visibility="hidden" />
          )}
          {data.tanggal_keluar && selectedRD ? (
            <SvgProxy selector="#tanggal_keluar" visibility="visible">
              {`Pada hari ${getIndonesianFormattedDateTime(
                data.tanggal_keluar
              )} ${data.timezone}`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#tanggal_keluar" visibility="hidden" />
          )}
          {data.metode_jenazah ? (
            <SvgProxy selector="#tempat_terakhir" visibility="visible">
              {`${data.metode_jenazah} di ${
                data.tempat_terakhir ? data.tempat_terakhir : "laksanakan"
              }`}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#tempat_terakhir" visibility="hidden" />
          )}
          {selectedRD.nama_rd ? (
            <SvgProxy selector="#nama_rd" visibility="visible">
              {selectedRD.nama_rd}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#nama_rd" visibility="hidden" />
          )}
          {data.nama_mendiang ? (
            <SvgProxy selector="#nama_mendiang" visibility="visible">
              {data.nama_mendiang}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#nama_mendiang" visibility="hidden" />
          )}
          {data.keterangan_mendiang ? (
            <SvgProxy selector="#keterangan_mendiang" visibility="visible">
              {data.keterangan_mendiang}
            </SvgProxy>
          ) : (
            <SvgProxy selector="#keterangan_mendiang" visibility="hidden" />
          )}
          {cropImg ? (
            <SvgProxy
              selector="#foto_mendiang"
              href={cropImg}
              visibility="visible"
            ></SvgProxy>
          ) : (
            <SvgProxy selector="#foto_mendiang" visibility="hidden" />
          )}

          <SvgProxy
            href={data.logo}
            selector="#logo"
            visibility="visible"
          ></SvgProxy>

          {Array.from({ length: 12 }).map((_, index) => (
            <div key={index}>
              {index <
              (textKeluarga[0] !== "" && acaraEntries.length > 5
                ? 5
                : acaraEntries.length) ? (
                <SvgProxy
                  key={index}
                  selector={`#acara${index + 1}`}
                  visibility="visible"
                >
                  {`${
                    acaraEntries[index].nama_acara
                  } : ${getIndonesianFormattedDateTime(
                    acaraEntries[index].tanggal_acara
                  )} ${data.timezone}`}
                </SvgProxy>
              ) : (
                <SvgProxy
                  key={index}
                  selector={`#acara${index + 1}`}
                  visibility="hidden"
                />
              )}
            </div>
          ))}
          {acaraEntries?.length === 0 ? (
            <SvgProxy selector={`#tittle_jadwal`} visibility="hidden" />
          ) : (
            <SvgProxy selector={`#tittle_jadwal`} visibility="visible" />
          )}
          {textKeluarga[0] === "" ? (
            <div>
              <SvgProxy selector={`#daftar_keluarga`} visibility="hidden" />
            </div>
          ) : (
            <div>
              <SvgProxy selector={`#daftar_keluarga`} visibility="visible" />
            </div>
          )}

          {Array.from({ length: 9 }).map((_, index) => (
            <div key={index}>
              {index < textKeluarga.length ? (
                <SvgProxy
                  key={index}
                  selector={`#baris_keluarga_${index + 1}`}
                  visibility={textKeluarga[index] === "" ? "hidden" : "visible"}
                >
                  {textKeluarga[index]}
                </SvgProxy>
              ) : (
                <SvgProxy
                  key={index}
                  selector={`#baris_keluarga_${index + 1}`}
                  visibility="hidden"
                />
              )}
            </div>
          ))}

          <SvgProxy selector={`#ucapan_akhir`} visibility="hidden" />
          <SvgProxy selector={`#qr_grup`} visibility="hidden" />
        </SvgLoader>
      );
    }
  };
  //========================================create image
  const convertToBase64 = () => {
    var node = document.getElementById("svg-container");
    const scale = 1190.55 / node.offsetWidth;
    var options = {
      quality: 0.5,
      height: 1683.61,
      width: node.offsetWidth * scale,
      style: {
        transform: "scale(" + scale + ")",
        transformOrigin: "top left",
        width: node.offsetWidth + "px",
        height: node.offsetHeight + "px",
      },
    };
    domtoimage
      .toJpeg(node, options)
      .then(function (dataUrl) {
        const image = base64ToFile(dataUrl);
        download(image);
      })
      .catch(function (error) {
        setErrorServer("oops, something went wrong!", error);
      });

    handleCloseLoading();
    setErrors(initialState);
  };

  const download = (blob) => {
    const imageUrl = window.URL.createObjectURL(blob);

    // Create an anchor element to trigger the download
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = data.nama_mendiang + ".jpg"; // Set the filename
    link.click();

    // Revoke the URL to release resources
    window.URL.revokeObjectURL(imageUrl);
  };
  //========================================handling submit

  useEffect(() => {
    auth();
    getDaftarTemplateResume();
  }, []);

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ mt: 4, textAlign: "center" }}>
            Form Custom Twibbon
          </Typography>
          <Typography
            variant="h6"
            sx={{ mt: 1, textAlign: "center", color: "red" }}
          >
            Data yang diinput tidak akan disimpan
          </Typography>
          <ErrorBox />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 5,
            }}
          >
            <Typography variant="h5">Preview Profile</Typography>
            <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
              <Box
                sx={{
                  width: 300,
                  height: 300,
                  borderRadius: 300,
                  position: "absolute",
                  overflow: "hidden",
                }}
              >
                <img
                  src={avatarShow()}
                  width={300}
                  height={300}
                  alt="nama mendiang"
                />
              </Box>
              <Box position="relative">
                <img src={Frame} width={305} alt="nama_mendiang" />
              </Box>
            </Box>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{ color: "white" }}
            >
              {" "}
              Upload Foto
            </Button>
          </Box>
          {user.role == "super" ? (
            <TextField
              fullWidth
              required
              size="small"
              id="nama_rd"
              name="nama_rd"
              onChange={(e) => {
                handleRDChange(e.target.value);
              }}
              label="Pilih Rumah Duka"
              variant="outlined"
              select
              defaultValue="0"
              sx={{ mt: 3 }}
            >
              <MenuItem value="0">Pilih Rumah Duka...</MenuItem>
              {dataRD.map((option) => (
                <MenuItem key={option.pk_id_rd} value={option.pk_id_rd}>
                  {option.nama_rd}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Box />
          )}
          <TextField
            fullWidth
            required
            size="small"
            id="nama_mendiang"
            name="nama_mendiang"
            onChange={handleInputChange}
            label="Masukan nama mendiang"
            variant="outlined"
            sx={{ mt: 3 }}
            error={!!errors.nama_mendiang}
            helperText={errors.nama_mendiang}
          />
          <TextField
            fullWidth
            required
            size="small"
            id="keterangan_mendiang"
            name="keterangan_mendiang"
            onChange={handleInputChange}
            label="Masukan keterangan mendiang"
            variant="outlined"
            sx={{ mt: 3 }}
            error={!!errors.keterangan_mendiang}
            helperText={errors.keterangan_mendiang}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              mt: 2,
              gap: 2,
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                id="tanggal_lahir"
                label="Lahir"
                openTo="year"
                disableFuture
                format="DD MMM YYYY"
                onChange={(e) =>
                  setData({
                    ...data,
                    tanggal_lahir: e.format("YYYY/MM/DD"),
                  })
                }
                error={!!errors.tanggal_lahir}
                helperText={errors.tanggal_lahir}
              />
              <FormHelperText error>{errors.tanggal_lahir}</FormHelperText>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <MobileDateTimePicker
                required
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                name="tanggal_wafat"
                id="tanggal_wafat"
                disableFuture
                label="Wafat"
                format="DD MMM YYYY hh:mm A"
                ampm={false}
                onChange={(e) => {
                  setData({
                    ...data,
                    tanggal_wafat: e.format("YYYY/MM/DD hh:mm A"),
                  });
                }}
              />
              <FormHelperText error>{errors.tanggal_wafat}</FormHelperText>
            </Box>
          </Box>
          <TextField
            fullWidth
            size="small"
            id="tempat_wafat"
            name="tempat_wafat"
            onChange={handleInputChange}
            label="Tempat wafat mendiang"
            variant="outlined"
            sx={{ mt: 2 }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              mt: 2,
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <TextField
              fullWidth
              required
              size="small"
              id="tempat_bersemayam"
              name="tempat_bersemayam"
              onChange={handleInputChange}
              label="Masukan Tempat Bersemayam"
              variant="outlined"
              sx={{ width: "70%" }}
              error={!!errors.tempat_bersemayam}
              helperText={errors.tempat_bersemayam}
            />
            <TextField
              size="small"
              id="timezone"
              name="timezone"
              onChange={handleInputChange}
              label="Pilih Zona Waktu"
              variant="outlined"
              select
              value={data.timezone}
              sx={{ width: "calc(30% - 18px)" }}
            >
              <MenuItem value="">Pilih Zona Waktu...</MenuItem>

              <MenuItem key={7} value={"WIB"}>
                WIB
              </MenuItem>
              <MenuItem key={8} value={"WITA"}>
                WITA
              </MenuItem>
              <MenuItem key={9} value={"WIT"}>
                WIT
              </MenuItem>
            </TextField>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: 2,
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <MobileDateTimePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  name="tanggal_masuk"
                  id="tanggal_masuk"
                  label="Masuk Ruangan"
                  format="DD MMM YYYY hh:mm A"
                  ampm={false}
                  required
                  onChange={(e) => {
                    setData({
                      ...data,
                      tanggal_masuk: e.format("YYYY/MM/DD hh:mm A"),
                    });
                    if (data.tanggal_keluar) {
                      const new_in = convertDateFormat(
                        e.format("YYYY/MM/DD hh:mm A")
                      );
                      const new_out = convertDateFormat(data.tanggal_keluar);
                      const ruangan = {
                        new_in: new_in,
                        new_out: new_out,
                        fk_id_rd: data.fk_id_rd,
                      };
                    }
                  }}
                />
                <FormHelperText error>{errors.tanggal_masuk}</FormHelperText>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <MobileDateTimePicker
                  required
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  name="tanggal_keluar"
                  id="tanggal_keluar"
                  label="Keluar Ruangan"
                  format="DD MMM YYYY hh:mm A"
                  minDateTime={dayjs(data.tanggal_masuk)}
                  ampm={false}
                  // minDate={data.tanggal_masuk}
                  disabled={!("tanggal_masuk" in data)}
                  onChange={(e) => {
                    setData({
                      ...data,
                      tanggal_keluar: e.format("YYYY/MM/DD hh:mm A"),
                    });
                    const new_in = convertDateFormat(data.tanggal_masuk);
                    const new_out = convertDateFormat(
                      e.format("YYYY/MM/DD hh:mm A")
                    );
                    const ruangan = {
                      new_in: new_in,
                      new_out: new_out,
                      fk_id_rd: data.fk_id_rd,
                    };
                  }}
                />
                <FormHelperText error>{errors.tanggal_keluar}</FormHelperText>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: 2,
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <TextField
                  size="small"
                  id="ruangan"
                  name="nama_ruangan"
                  onChange={handleInputChange}
                  label="Nama Ruangan"
                  variant="outlined"
                  error={!!errors.nama_ruangan}
                />
                <FormHelperText error>{errors.fk_id_ruangan}</FormHelperText>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <TextField
                  size="small"
                  id="lantai"
                  name="lantai"
                  onChange={handleInputChange}
                  label="Lantai Ruangan"
                  variant="outlined"
                />
              </Box>
            </Box>
          </Box>
          <Typography variant="h5" sx={{ mt: 3 }}>
            Informasi Acara
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography mr={2}>Jenazah di :</Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="metode_jenazah"
              row
              required
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="Krematorium"
                control={<Radio size="small" />}
                label="Kremasi"
              />
              <FormControlLabel
                value="Pemakaman"
                control={<Radio size="small" />}
                label="Makamkan"
              />
            </RadioGroup>
          </Box>
          <FormHelperText error>{errors.metode_jenazah}</FormHelperText>
          <TextField
            fullWidth
            size="small"
            id="tempat_terakhir"
            name="tempat_terakhir"
            onChange={handleInputChange}
            label="Tempat terakhir mendiang"
            variant="outlined"
            sx={{ mt: 2 }}
          />
          <Button
            color="success"
            variant="contained"
            size="small"
            sx={{ borderRadius: "99px", mt: 3, mb: 1, color: "white" }}
            onClick={() => {
              handleAddRow();
            }}
          >
            Tambah Detail Acara
          </Button>
          {acaraEntries.map((entry, index) => (
            <Grid
              key={index}
              container
              spacing={1}
              alignItems={"center"}
              mt={2}
            >
              <Grid item xs={9} md={5}>
                <Autocomplete
                  id="nama_acara_autocompleate"
                  freeSolo
                  value={entry.nama_acara}
                  onChange={(e) =>
                    handleInputChangeAcara(
                      index,
                      e.target.textContent,
                      "nama_acara"
                    )
                  }
                  options={reqAcara.map((option) => option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      id="nama_acara"
                      name="nama_acara"
                      label="Nama Acara"
                      variant="outlined"
                      value={entry.nama_acara}
                      onChange={(e) =>
                        handleInputChangeAcara(
                          index,
                          e.target.value,
                          "nama_acara"
                        )
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9} md={4}>
                <MobileDateTimePicker
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  name="tanggal_acara"
                  id="tanggal_acara"
                  label="Tanggal Acara"
                  format="DD MMM YYYY hh:mm A"
                  ampm={false}
                  value={dayjs(entry.tanggal_acara)}
                  minDateTime={dayjs(data.tanggal_masuk)}
                  maxDateTime={dayjs(data.tanggal_keluar).add(1, "day")}
                  onChange={(e) =>
                    handleInputChangeAcara(
                      index,
                      e.format("YYYY/MM/DD hh:mm A"),
                      "tanggal_acara"
                    )
                  }
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: "99px",
                      m: 1,
                      color: "white",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      handleRemoveRow(index);
                    }}
                  >
                    Hapus Acara
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ))}
          <TextField
            size="small"
            fullWidth
            id="keluarga"
            name="keluarga"
            onChange={handleInputChangeKeluarga}
            variant="outlined"
            multiline
            label="Keluarga yang ditinggalkan"
            sx={{ mt: 2 }}
            error={!!errors.keluarga}
            helperText={errors.keluarga}
            // inputProps={{
            //   maxLength: maxInputMessage, // Set the maximum character limit
            // }}
          />

          <Typography variant="h5" sx={{ mt: 3 }}>
            Preview Twibbon
          </Typography>

          <Button
            variant="contained"
            size="small"
            sx={{
              borderRadius: "99px",
              m: 1,
              color: "white",
              whiteSpace: "nowrap",
            }}
            onClick={openModalResume}
          >
            Pilih Template Twibbon
          </Button>
          <Box sx={{ width: "65%" }}>
            <div id="svg-container">{renderPreview()}</div>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              mt: 5,
            }}
          >
            <LoadingButton
              endIcon={<Download />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                handleLoading();
                checkValidate();
              }}
              variant="contained"
              sx={{
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                mt: 3,
              }}
            >
              Download Gambar
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      <ResumeTemplateModal
        data={dataTemplateResume}
        setClose={closeModalResume}
        modal={modalResume}
      />
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={styleBoxModal}>
          <Typography id="modal-modal-title" variant="h6" mb={3}>
            Upload Photo Profile
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              width={show}
              height={show}
              onClose={closeAvatar}
              onCrop={cropAvatar}
            />
          </Box>

          <Button
            onClick={saveImg}
            variant="contained"
            sx={{ color: "white", mt: 3 }}
          >
            Simpan
          </Button>
        </Box>
      </Modal>
    </Layout>
  );
}

export default CustomTwibbon;
