import { CloseOutlined } from "@mui/icons-material";
import { Alert, IconButton, Snackbar} from "@mui/material";
import React, { useEffect } from "react";
import { useGlobal } from "../Helper/Context/GlobalContext";

function ErrorBox() {
  //==================================================Context
  const globalContext = useGlobal();
  const { openError, closeErrorBox, errorServer } = globalContext;
  useEffect(() => {
    if(!errorServer){
      closeErrorBox();
    }
  }, [errorServer])
  
  return (
    <Snackbar open={openError} autoHideDuration={4000} onClose={closeErrorBox}>
      <Alert
        severity="error"
        variant="filled"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              closeErrorBox();
            }}
          >
            <CloseOutlined fontSize="inherit" />
          </IconButton>
        }
        sx={{ width: "100%" }}
      >
        {errorServer}
      </Alert>
    </Snackbar>
  );
}

export default ErrorBox;
