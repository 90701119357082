import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Snackbar,
  Tabs,
  Tab,
} from "@mui/material";
import { Link as DirectLink } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwt from "jwt-decode";
import SearchIcon from "@mui/icons-material/Search";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import ErrorBox from "../../Components/ErrorBox";
import {a11yProps,StyledTableCell,StyledTableRow} from "../../Helper/style/style"


function DaftarVoucher() {
  //==================================================Context
  const masukContext = useGlobal();
  const { loginToken,dataKonfirm, setDataKonfirm, setToOpenKonfirm,openErrorBox, setErrorServer } = masukContext;
  //=========================================================authorize

  const history = useHistory();
  const auth = () => {
    let token={role:''};
    if(loginToken){
      token = jwt(loginToken);
    }
    if (token.role != "super") {
      history.push("/");
    }
  };
  //============================================Tab Panel
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //===================================Get Data
  const [data, setData] = useState([]);

  const getDaftarVoucher = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/voucher/voucher";
    try {
      const response = await axios.get(url,{
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
      setData(
        response.data
      );
      setSearchData(
        response.data
      );
    } catch (error) {
      if(error.response.data.length<100){
        setErrorServer(error.response.data); 
      }else{
        setErrorServer("Terjadi error saat mengambil data rumah duka, Mohon coba lagi")
      }
      openErrorBox()
    }
  };

  useEffect(() => {
    getDaftarVoucher(/*usrid*/);
    auth();
  }, []);
  //====================================================================copy
  const [copied, setCopied] = useState(false);

  const handleCopyClick = (row) => {
    const textField = document.createElement('textarea');
    textField.innerText = `Selamat, Anda mendapatkan voucher sebesar ${row.jenis_potongan==="persentase"?row.nominal+"%":row.nominal.currency()}, Masukan kode berikut ${row.kode_voucher} pada halaman pembayaran.`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
    setCopied(true);
  };
  //================================================================Delete Data
  const [idData, setIdData] = useState()
  useEffect(() => {
    if(dataKonfirm===true){
      handleDelete(idData);
    }else if(dataKonfirm===false){
      
    }
    setDataKonfirm(null);
  }, [dataKonfirm])

  const handleDelete = async (idData) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/voucher/voucher?id=" +
      idData;
      try{
        setSearchData(data.filter((item) => item.pk_id_voucher !== idData));
        await axios.delete(url, {
          headers: {
            Authorization: `bearer ${loginToken}`,
          },
        });
      } 
      catch(error){

        if(error.response.data.length<100){
          setErrorServer(error.response.data); 
        }else{
          setErrorServer("Terjadi error saat menghapus voucher, Mohon coba lagi")
        }
        openErrorBox()
      }
      
  };

  // ===================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");
  function searchVoucher(query) {
    query = query.toLowerCase(); // Convert the query to lowercase for case-insensitive search

    return data.filter((voucher) => {
      const nama_voucher = voucher.nama_voucher
        .toLowerCase()
        .includes(query);
      return nama_voucher;
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setInputSearchData(value)
    setInputSearchData({
      ...inputSearchData,
      [name]: value,
    });
    setSearchData(searchVoucher(value));
  };
  const Date_ = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  //==========================================================
  const [expVoucher, setExpVoucher] = useState([]);
  const [usedVoucher, setUsedVoucher] = useState([]);
  const [availVoucher, setAvailVoucher] = useState([]);

  useEffect(() => {
    if (searchData) {
      const nowDate = new Date();
      setUsedVoucher(
        searchData.filter(
          (voucher) => voucher.used === true
        )
      );
      setExpVoucher(
        searchData.filter(
          (voucher) => new Date(voucher.exp_date) <= nowDate && voucher.used === false
        )
      );
      setAvailVoucher(
        searchData.filter(
          (voucher) => new Date(voucher.exp_date) >= nowDate && voucher.used === false
        )
      );
    }
  }, [searchData]);
  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
        <ErrorBox/>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 5,
            }}
          >
            <Grid container spacing={1} >
              <Grid item >
              <Typography variant="h5" mr={2} >
                Daftar Voucher
              </Typography>
              </Grid>
              <Grid item >
              <TextField
                size="small"
                placeholder="Search…"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                name="searchText"
                onChange={handleInputChange}
                value={inputSearchData.searchText}
              />
              </Grid>
            </Grid>
            <Box>
            <Button
              component={DirectLink}
              to="/voucher/create"
              variant="contained"
              sx={{ borderRadius: "100px", color: "white" }}
            >
              Tambah
            </Button>
            </Box>
          </Box>
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Voucher Tersedia" {...a11yProps(0)} />
              <Tab label="Voucher Terpakai" {...a11yProps(1)} />
              <Tab label="Voucher Kadaluarsa" {...a11yProps(2)} />
            </Tabs>
          </Box>

            <TableContainer hidden={value !== 0} sx={{ my: 5 }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>No</StyledTableCell>
                    <StyledTableCell align="center">
                      Nama Voucher
                    </StyledTableCell>
                    <StyledTableCell align="center">Nominal</StyledTableCell>
                    <StyledTableCell align="center">Expired Date</StyledTableCell>
                    <StyledTableCell align="center">Kode Voucher</StyledTableCell>
                    <StyledTableCell align="center">Dipakai Oleh</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {availVoucher.map((row, index) => (
                    <StyledTableRow key={row.pk_id_voucher}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.nama_voucher}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.jenis_potongan=="persentase"?row.nominal+"%":row.nominal.currency()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.exp_date).toLocaleString(undefined, Date_)}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.kode_voucher}</StyledTableCell>
                      <StyledTableCell align="center">{row.used?row.username:"Belum Dipakai"}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="ok"
                          variant="contained"
                          size="small"
                          sx={{ mx: 1, color: "white",fontSize:'10px' }}
                          onClick={()=>{handleCopyClick(row)}}
                        >
                          Copy Text
                        </Button>

                        <Button
                          color="error"
                          variant="contained"
                          size="small"
                          sx={{ mx: 1, color: "white", fontSize:'10px' }}
                          onClick={()=>{setToOpenKonfirm();setIdData(row.pk_id_voucher)}}
                        >
                          Delete
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          <TableContainer hidden={value !== 1} sx={{ my: 5 }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>No</StyledTableCell>
                    <StyledTableCell align="center">
                      Nama Voucher
                    </StyledTableCell>
                    <StyledTableCell align="center">Nominal</StyledTableCell>
                    <StyledTableCell align="center">Expired Date</StyledTableCell>
                    <StyledTableCell align="center">Kode Voucher</StyledTableCell>
                    <StyledTableCell align="center">Dipakai Oleh</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usedVoucher.map((row, index) => (
                    <StyledTableRow key={row.pk_id_voucher}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.nama_voucher}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.jenis_potongan=="persentase"?row.nominal+"%":row.nominal.currency()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.exp_date).toLocaleString(undefined, Date_)}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.kode_voucher}</StyledTableCell>
                      <StyledTableCell align="center">{row.used?row.username:"Belum Dipakai"}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="ok"
                          variant="contained"
                          size="small"
                          sx={{ mx: 1, color: "white",fontSize:'10px' }}
                          onClick={()=>{handleCopyClick(row)}}
                        >
                          Copy Text
                        </Button>

                        <Button
                          color="error"
                          variant="contained"
                          size="small"
                          sx={{ mx: 1, color: "white", fontSize:'10px' }}
                          onClick={()=>{setToOpenKonfirm();setIdData(row.pk_id_voucher)}}
                        >
                          Delete
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          <TableContainer hidden={value !== 2} sx={{ my: 5 }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>No</StyledTableCell>
                    <StyledTableCell align="center">
                      Nama Voucher
                    </StyledTableCell>
                    <StyledTableCell align="center">Nominal</StyledTableCell>
                    <StyledTableCell align="center">Expired Date</StyledTableCell>
                    <StyledTableCell align="center">Kode Voucher</StyledTableCell>
                    <StyledTableCell align="center">Dipakai Oleh</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expVoucher.map((row, index) => (
                    <StyledTableRow key={row.pk_id_voucher}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.nama_voucher}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.jenis_potongan=="persentase"?row.nominal+"%":row.nominal.currency()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.exp_date).toLocaleString(undefined, Date_)}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.kode_voucher}</StyledTableCell>
                      <StyledTableCell align="center">{row.used?row.username:"Belum Dipakai"}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="ok"
                          variant="contained"
                          size="small"
                          sx={{ mx: 1, color: "white",fontSize:'10px' }}
                          onClick={()=>{handleCopyClick(row)}}
                        >
                          Copy Text
                        </Button>

                        <Button
                          color="error"
                          variant="contained"
                          size="small"
                          sx={{ mx: 1, color: "white", fontSize:'10px' }}
                          onClick={()=>{setToOpenKonfirm();setIdData(row.pk_id_voucher)}}
                        >
                          Delete
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Box>
      </Box>
      <Snackbar
        open={copied}
        autoHideDuration={3000}
        onClose={()=>{setCopied(false)}}
        message="Copied to clipboard"
      />
    </Layout>
  );
}

export default DaftarVoucher;
