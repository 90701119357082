import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useState } from 'react'
import TemplateCard from './ResumeTemplateCard'
import { Close } from '@mui/icons-material';
import ResumeTemplateCard from './ResumeTemplateCard';


function ResumeTemplateModal({modal,setClose, data}) {

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth={'xl'}
        open={modal}  
        onClose={()=>{setClose(0)}}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box
          color="white"
          sx={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "white",
            position: "absolute",
            right: "0px",
            top: "0px",
            border: "solid",
            borderRadius: "50px",
            borderColor: "primary.main",
          }}
        >
          <Typography
            sx={{ color: "primary.main" }}
            onClick={() => {
              setClose(0);
            }}
          >
            <Close />
          </Typography>
        </Box>
        <DialogTitle id="scroll-dialog-title">Daftar Template</DialogTitle>
        <DialogContent dividers>
          <ResumeTemplateCard data={data} setClose={setClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ResumeTemplateModal