import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import MainCarousel from "./MainCarousel";
import { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { Download } from "@mui/icons-material";
function UcapanMendiang({ id,nama }) {
  //==================================================Context
  const masukContext = useGlobal();
  const { openErrorBox, setErrorServer } = masukContext;
  const history = useHistory();
  //==================================================get ucapan
  const [data, setData] = useState([]);
  const getUcapan = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/pesan/pesan/" + id;
    try {
      const response = await axios.get(url);
      setData(
        response.data.map((e) => {
          return {
            image_pesan_path: e.image_pesan_path,
            pk_id_pesan: e.pk_id_pesan,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data pesan, Mohon  coba lagi"
        );
      }
      openErrorBox();
    }
  };


    const handleDownload = async () => {
      try {
         const url_rar = process.env.REACT_APP_BASE_URL + "api/pesan/pesan/download/" + id;
        const response = await axios.get(url_rar,{responseType: 'blob'});

        // Create a temporary URL for the downloaded file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nama? `${nama}.zip`:'images.zip'); // Specify the filename here
        document.body.appendChild(link);
        
        // Initiate the download
        link.click();
  
        // Clean up
        link.parentNode.removeChild(link);

      } catch (error) {
        console.error('Error downloading file:', error);
        // Handle error cases here
      }
    };

  useEffect(() => {
    getUcapan();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {data.length > 0 && (
        <Box sx={{ width: "100%" }}>
          <MainCarousel slides={data} />
        </Box>
      )}

      <Button
        variant="contained"
        size="small"
        onClick={() => {
          handleDownload();
        }}
        endIcon={<Download />}
        sx={{
          color: "white",
          borderRadius: "99px",
          whiteSpace: "nowrap",
          my:2
        }}
      >
        Download Ucapan
      </Button>

      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5">Kirim karangan bungamu</Typography>
            <Typography mt={2}>
              Salah satu cara untuk menguatkan serta mendukung orang yang sedang
              berduka cita adalah dengan mengucapkan belasungkawa yang tulus
              seta berisi doa-doa atau harapan baik. Ucapan belasungkawa tidak
              hanya mendukung orang yang sedang berduka, tetapi juga bisa
              mendoakan orang yang telah meninggal dunia agar mendapatkan tempat
              terbaik di sisi Tuhan.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                history.push("/pesan/order");
                sessionStorage.setItem("selected_mendiang", id);
              }}
              sx={{
                borderRadius: "999px",
                color: "white",
              }}
            >
              Kirim Ucapan
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default UcapanMendiang;
