import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import { Box, Button } from "@mui/material";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import InfoMendiang from "../../Components/Mendiang/InfoMendiang";
import UcapanMendiang from "../../Components/Mendiang/UcapanMendiang";
import ErrorBox from "../../Components/ErrorBox";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import axios from "axios";

function DetailMendiang() {
  const params = useParams();
  //==================================================Context
  const masukContext = useGlobal();
  const { openErrorBox, setErrorServer } = masukContext;
  const initialStateMendiang = {
    path_image: "",
    path_image_resume: "",
    nama_mendiang: "",
    tanggal_wafat: "",
    acara: [],
  };
  const [data, setData] = useState(initialStateMendiang);
  const history = useHistory();
  const getMendiang = async () => {
    const url =
      process.env.REACT_APP_BASE_URL + "api/Mendiang/MendiangById?id=" + params.id;
    try {
      const response = await axios.get(url);

      setData(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data mendiang, Mohon  coba lagi"
        );
      }
      openErrorBox();
    }
  };
useEffect(() => {
    getMendiang();
  }, []);
  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <InfoMendiang data={data} />

          <UcapanMendiang id={params.id} nama={data.nama_mendiang} />
        </Box>
      </Box>
      <Box bgcolor="primary.main" position='fixed' sx={{width:'100%', height:"40px", bottom:0, zIndex:99, display:'flex', justifyContent:'center', alignItems:'center' }}>
        <Box>
          <Button         
            variant="contained"
            size="small"
            onClick={() => {
              history.push("/pesan/order");
              sessionStorage.setItem("selected_mendiang", params.id);
            }}
            sx={{
              borderRadius: "999px",
              color: "primary.main",
              bgcolor:"white"
            }}
          >
            Kirim Ucapan
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}

export default DetailMendiang;
