import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Typography,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Link as DirectLink } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwt from "jwt-decode";
import SearchIcon from "@mui/icons-material/Search";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import ErrorBox from "../../Components/ErrorBox";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../Helper/style/style";
function TambahRumahDuka() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //=========================================================authorize

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role != "super") {
      history.push("/");
    }
  };

  //===================================Get Data
  const [data, setData] = useState([]);

  const getDaftarRD = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/RumahDuka/RumahDuka";
    try {
      const response = await axios.get(url);
      setData(
        response.data.map((e) => {
          return {
            pk_id_rd: e.pk_id_rd,
            guid_rd: e.guid_rd,
            nama_rd: e.nama_rd,
            alamat: e.alamat,
            tlp: e.tlp,
            nama_kota: e.nama_kota,
            nama_prov: e.nama_prov,
            fk_id_avatar: e.fk_id_avatar,
          };
        })
      );
      setSearchData(
        response.data.map((e) => {
          return {
            pk_id_rd: e.pk_id_rd,
            guid_rd: e.guid_rd,
            nama_rd: e.nama_rd,
            alamat: e.alamat,
            tlp: e.tlp,
            nama_kota: e.nama_kota,
            nama_prov: e.nama_prov,
            fk_id_avatar: e.fk_id_avatar,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  useEffect(() => {
    getDaftarRD(/*usrid*/);
    auth();
  }, []);

  //================================================================Delete Data
  const [idData, setIdData] = useState();
  useEffect(() => {
    if (dataKonfirm === true) {
      handleDelete(idData);
    } else if (dataKonfirm === false) {
    }
    setDataKonfirm(null);
  }, [dataKonfirm]);
  const handleDelete = async (idData) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/RumahDuka/RumahDuka?id=" +
      idData.id +
      "&idavt=" +
      idData.idavt;
    try {
      setSearchData(data.filter((item) => item.pk_id_rd !== idData.id));
      await axios.delete(url, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat menghapus rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  // ===================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");
  function searchRD(query) {
    query = query.toLowerCase(); // Convert the query to lowercase for case-insensitive search

    return data.filter((rd) => {
      const nama_rd = rd.nama_rd.toLowerCase().includes(query);
      return nama_rd;
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setInputSearchData(value)
    setInputSearchData({
      ...inputSearchData,
      [name]: value,
    });
    setSearchData(searchRD(value));
  };

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 5,
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" mr={2}>
                  Daftar Rumah Duka
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  placeholder="Search…"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  name="searchText"
                  onChange={handleInputChange}
                  value={inputSearchData.searchText}
                />
              </Grid>
            </Grid>
            <Box>
              <Button
                component={DirectLink}
                to="/form_tambah_rd"
                variant="contained"
                sx={{ borderRadius: "100px", color: "white" }}
              >
                Tambah
              </Button>
            </Box>
          </Box>
          <TableContainer sx={{ my: 5 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>No</StyledTableCell>
                  <StyledTableCell align="center">
                    Nama Rumah Duka
                  </StyledTableCell>
                  <StyledTableCell align="center">TV Token</StyledTableCell>
                  <StyledTableCell align="center">Alamat</StyledTableCell>
                  <StyledTableCell align="center">Telepon</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <StyledTableRow key={row.pk_id_rd}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.nama_rd}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.guid_rd}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.alamat},{row.nama_kota},{row.nama_prov}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.tlp}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        color="ok"
                        variant="contained"
                        size="small"
                        sx={{ mx: 1, color: "white", fontSize: "10px" }}
                        component={DirectLink}
                        to={`/view_rd/${row.pk_id_rd}`}
                      >
                        View
                      </Button>
                      <Button
                        color="success"
                        variant="contained"
                        size="small"
                        sx={{ mx: 1, color: "white", fontSize: "10px" }}
                        component={DirectLink}
                        to={`/edit_rd/${row.pk_id_rd}`}
                      >
                        Edit
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        size="small"
                        sx={{ mx: 1, color: "white", fontSize: "10px" }}
                        onClick={() => {
                          setToOpenKonfirm();
                          setIdData({
                            id: row.pk_id_rd,
                            idavt: row.fk_id_avatar,
                          });
                        }}
                      >
                        Delete
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Layout>
  );
}

export default TambahRumahDuka;
