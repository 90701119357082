import { Box, Button, Card, CardActions, CardMedia, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link as DirectLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGlobal } from "../../Helper/Context/GlobalContext";
function TemplateCard({ user, data, deleteData, setClose }) {
  //------------------------------------------------------error handle
  const handleBoxClick = () => {
    // Navigate to another page when the box is clicked
    history.push("/template/selected-template");
  };
  const handleButtonClick = (e) => {
    // Prevent the click event from propagating to the box
    e.stopPropagation();
  };
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    openErrorBox,
    setErrorServer,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
  } = masukContext;

  //=========================================================go to editor
  const history = useHistory();

  //------------------------------------------------------handle Delete
  const [idTemplate, setIdTemplate] = useState();
  const handleDeleteTemplate = (id) => {
    setToOpenKonfirm();
    setIdTemplate(id);
  };
  useEffect(() => {
    if (dataKonfirm === true) {
      handleDelete(idTemplate);
    } else if (dataKonfirm === false) {
      setIdTemplate();
    }
    setDataKonfirm(null);
  }, [dataKonfirm]);

  const handleDelete = async (id) => {
    const url =
      process.env.REACT_APP_BASE_URL + "api/template/template?id=" + id;
    await axios
      .delete(url, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(deleteData(id))
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer(
            "Terjadi error saat delete template, Mohon  coba lagi"
          );
        }
        openErrorBox();
      });
  };

  return (
    <Grid container spacing={2}>
      {data.map((row) => (
        <Grid key={row.pk_id_template} item xs={12} md={4}>
          <Card
            sx={{ maxWidth: 345 }}
            onClick={() => {
              sessionStorage.setItem(
                "selected_template",
                row.path_image_template
              );
              sessionStorage.setItem(
                "selected_template_id",
                row.pk_id_template
              );
              if (!setClose) {
                handleBoxClick();
              } else {
                setClose();
              }
            }}
          >
            <CardMedia
              component="img"
              alt="templates"
              image={process.env.REACT_APP_BASE_URL + row.path_thumb_template}
              loading="lazy"
            />
            {user === "super" && (
              <CardActions>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{ color: "white", borderRadius: "100px" }}
                      size="small"
                      component={DirectLink}
                      to={"/template/edit/" + row.pk_id_template}
                      onClick={(e) => {
                        handleButtonClick(e);
                      }}
                    >
                      Edit
                    </Button>

                    <Button
                      variant="contained"
                      color="error"
                      sx={{ color: "white", borderRadius: "100px" }}
                      size="small"
                      onClick={(e) => {
                        handleButtonClick(e);
                        handleDeleteTemplate(row.pk_id_template);
                      }}
                    >
                      Hapus
                    </Button>
                  </Box>
                </Box>
              </CardActions>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default TemplateCard;
