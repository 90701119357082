import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import jwt from "jwt-decode";
import { useGlobal } from "../Helper/Context/GlobalContext.js";
import { Link as DirectLink } from "react-router-dom";

function Sidebar() {
  const sidebarContext = useGlobal();
  const { open, setCloseSidebar, loginToken } = sidebarContext;

  var item = [
    {
      name: "Beranda",
      link: "/",
    },
    {
      name: "Daftar Mendiang",
      link: "/daftar-mendiang",
    },
  ];
  const [menu, setMenu] = useState(item);

  const auth = () => {
    if (loginToken) {
      const user = jwt(loginToken);
      if (user.role === "admin") {
        setMenu(
          (item = [
            {
              name: "Beranda",
              link: "/",
            },
            {
              name: "Daftar Mendiang",
              link: "/daftar-mendiang",
            },
            {
              name: "Riwayat Mendiang",
              link: "/riwayat-mendiang",
            },
            {
              name: "Daftar Ucapan",
              link: "/daftar_pesan",
            },
            {
              name: "Laporan",
              link: "/daftar_laporan",
            },
            {
              name: "Rekap Komisi",
              link: "/komisi",
            },
          ])
        );
      } else if (user.role === "super") {
        setMenu(
          (item = [
            {
              name: "Beranda",
              link: "/",
            },
            {
              name: "Daftar Rumah Duka",
              link: "/tambah_rd",
            },
            {
              name: "Daftar Mendiang",
              link: "/daftar-mendiang",
            },
            {
              name: "Riwayat Mendiang",
              link: "/riwayat-mendiang",
            },
            {
              name: "Daftar User",
              link: "/users",
            },
            {
              name: "Daftar Template",
              link: "/template/daftar",
            },
            {
              name: "Daftar Resume Template",
              link: "/resume-template/daftar",
            },
            {
              name: "Daftar Ucapan",
              link: "/daftar_pesan",
            },
            {
              name: "Daftar All Keranjang",
              link: "/pesan/all-keranjang",
            },
            {
              name: "Laporan",
              link: "/daftar_laporan",
            },
            {
              name: "Rekap Komisi",
              link: "/komisi",
            },
            {
              name: "Voucher",
              link: "/voucher",
            },
          ])
        );
      } else {
        setMenu(
          (item = [
            {
              name: "Beranda",
              link: "/",
            },
            {
              name: "Daftar Mendiang",
              link: "/daftar-mendiang",
            },
            {
              name: "Hubungi Kami",
              link: "/hubungi-kami",
            },
          ])
        );
      }
    }
  };

  useEffect(() => {
    auth();
  }, [loginToken]);

  return (
    <Box style={{ zIndex: 999 }}>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => {
          setCloseSidebar();
        }}
      >
        <Box p={2} width={250} role="presentation">
          <Typography
            variant="h5"
            component="div"
            fontFamily="Affinity Future"
            color={"primary"}
          >
            InMemoriam
          </Typography>
        </Box>
        <Divider />
        <List>
          {menu.map((item) => (
            <ListItem key={item.name} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => {
                  setCloseSidebar();
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                component={DirectLink}
                to={item.link}
              >
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}

export default Sidebar;
