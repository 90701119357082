import {
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Slider,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import Example from "../../Assets/ex_card.webp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";
import { Link as DirectLink } from "react-router-dom";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import Backdrop from "@mui/material/Backdrop";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../Components/cropImage";
import { baseStyle } from "../../Helper/style/style";
import "react-easy-crop/react-easy-crop.css";
//===============================================style dropzone

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
//========================================end dropzone style
function KirimUcapanMendiang() {
  const id = sessionStorage.getItem("selected_mendiang");

  //=======================================dropzone handle

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    acceptedFiles,
  } = useDropzone({ accept: { "image/*": [] } });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const history = useHistory();
  useEffect(() => {
    if (acceptedFiles.length !== 0) {
      handleImageDrop(acceptedFiles[0]);
    }
  }, [acceptedFiles]);
  //==========================================================Modal if not login
  const sidebarContext = useGlobal();
  const {
    setToOpenMasuk,
    user,
    loginToken,
    dataPesan,
    sendDataPesan,
    openErrorBox,
    setErrorServer,
  } = sidebarContext;
  //================================================================================ modal crop
  const [openCrop, setOpenCrop] = React.useState(false);
  const handleOpen = () => setOpenCrop(true);
  const handleClose = () => setOpenCrop(false);

  //================================================================================= cropping
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleImageDrop = (acceptedFiles) => {
    const selectedImage = URL.createObjectURL(acceptedFiles);
    setImage(selectedImage);
    handleOpen();
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
      setCroppedImage(croppedImage);
    } catch (e) {}
  }, [croppedAreaPixels, 0]);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const CROP_AREA_ASPECT = 16 / 9;
  //=====================================================Submit Handle

  const handleSubmit = async () => {
    //cek ucapan sudah di upload
    if (!croppedImage) {
      setErrorServer("Foto Belum di Upload");
      openErrorBox();
      return;
    }
    //cek sudah login
    if (!loginToken) {
      setToOpenMasuk();
      return;
    }

    const response = await fetch(croppedImage);
    const blob = await response.blob();

    const disc = (Math.floor(Math.random() * 900) + 100) * -1;

    const url =
      process.env.REACT_APP_BASE_URL + "api/mendiang/harga/ucapan/" + id;
    axios
      .get(url)
      .then((response) => {
        sendDataPesan({
          ...dataPesan,
          uploaded_ucapan: blob,
          fk_id_user: user.pk_id_user,
          fk_id_mendiang: id,
          disc: disc,
          harga: response.data,
        });
        history.push(`/pesan/payment/${id}`);
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat mengirim pesan, Mohon coba lagi");
        }
        openErrorBox();
      });
  };

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "start",
            }}
          >
            <ErrorBox />

            <Typography mt={5} variant="h5">
              Upload Foto Ucapan Mendiang
            </Typography>
            <Typography mb={3}>
              Eksistensi file jpg/png dengan maks 1Mb, Ukuran ideal 1920 x
              1080px
            </Typography>

            {croppedImage && (
              <div>
                <Typography variant="h5">Preview Cropped Image</Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <img width={"80%"} src={croppedImage} alt="uploaded file" />
                </Box>
              </div>
            )}
            {!croppedImage && (
              <Box {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragAccept && <p>All files will be accepted</p>}
                {isDragReject && <p>Some files will be rejected</p>}
                {isDragActive
                  ? "Drop the files here ..."
                  : "Drag 'n' drop some files here, or click to select files"}
              </Box>
            )}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                my: 3,
              }}
            >
              {croppedImage && (
                <div>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ color: "white", borderRadius: "99px", mr: 1 }}
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    Edit Croping
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{ color: "white", borderRadius: "99px", mr: 1 }}
                    onClick={() => {
                      setCroppedImage();
                    }}
                  >
                    Hapus Gambar
                  </Button>
                </div>
              )}
              <Button
                variant="contained"
                size="small"
                sx={{ color: "white", borderRadius: "99px" }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Kirim Ucapan
              </Button>
            </Box>
            <Grid container>
              <Grid item xs={12} md={5}>
                <Box>
                  <Typography variant="h5">
                    Belum punya gambar karangan bunga?
                  </Typography>
                  <Typography my={2}>
                    Buat karangan buangamu disini dengan template yang beragam.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ color: "white", borderRadius: "99px" }}
                    component={DirectLink}
                    to={`/template/daftar`}
                  >
                    Buat Disini
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={Example} alt="examlpe" style={{ width: "60%" }} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCrop}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openCrop}>
          <Box sx={style}>
            {image ? (
              <Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: "100px",
                  }}
                >
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={CROP_AREA_ASPECT}
                    onCropChange={onCropChange}
                    onZoomChange={onZoomChange}
                    onCropComplete={onCropComplete}
                    disableAutomaticStylesInjection={true}
                  />
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                    bottom: "0",
                    left: "50%",
                    width: "100%",
                    transform: "translateX(-50%)",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                  }}
                >
                  <Box
                    sx={{
                      width: "80%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Slider
                      maxWidth="30%"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e, zoom) => onZoomChange(zoom)}
                    />
                    <Box
                      sx={{
                        ml: 4,
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          showCroppedImage();
                          handleClose();
                        }}
                        sx={{
                          mr: 1,
                          whiteSpace: "nowrap",
                          color: "white",
                          borderRadius: "100px",
                        }}
                      >
                        Crop & Resize
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          handleClose();
                        }}
                        sx={{
                          whiteSpace: "nowrap",
                          color: "white",
                          borderRadius: "100px",
                        }}
                      >
                        Batal
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box />
            )}
          </Box>
        </Fade>
      </Modal>
    </Layout>
  );
}

export default KirimUcapanMendiang;