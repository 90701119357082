import React, { createContext, useContext, useEffect, useState } from "react";
import jwt from "jwt-decode";
import axios from "axios";
export const globalContext = createContext();
export const useGlobal = () => {
  return useContext(globalContext);
};

function GlobalContext({ children }) {
  //================================================Sidebar Handle
  const [open, setOpen] = useState(false);
  const setOpenSidebar = () => {
    setOpen(true);
  };
  const setCloseSidebar = () => {
    setOpen(false);
  };

  //===============================================RegisterModal
  const [openRegister, setOpenRegister] = useState(false);
  const setToOpenRegister = () => {
    setOpenRegister(true);
  };
  const setToCloseRegister = () => {
    setOpenRegister(false);
  };
  //===============================================MasukModal
  const [openMasuk, setOpenMasuk] = useState(false);
  const setToOpenMasuk = () => {
    setOpenMasuk(true);
  };
  const setToCloseMasuk = () => {
    setOpenMasuk(false);
  };
  //===============================================GantiModal
  const [openGantiPW, setOpenGantiPW] = useState(false);
  const setToOpenGantiPW = () => {
    setOpenGantiPW(true);
  };
  const setToCloseGantiPW = () => {
    setOpenGantiPW(false);
  };
  //===============================================GantiModal
  const [dataKonfirm, setDataKonfirm] = useState();
  const [openKonfirm, setOpenKonfirm] = useState(false);
  const setToOpenKonfirm = () => {
    setOpenKonfirm(true);
  };
  const setToCloseKonfirm = (data) => {
    setOpenKonfirm(false);
    if (data) {
      setDataKonfirm(data);
    } else {
      setDataKonfirm(false);
    }
  };
  //===============================================ResponseModal
  const initialResponse = {
    pathImage: "",
    tittle: "",
    massage: "",
    pathButton: "",
    nameButton: "",
  };
  const [dataResponse, setDataResponse] = useState(initialResponse);
  const [openResponse, setOpenResponse] = useState(false);
  const setToOpenResponse = (data) => {
    setDataResponse(data);
    setOpenResponse(true);
  };
  const setToCloseResponse = () => {
    setOpenResponse(false);
  };

  //================================================Detail Mendiang Handle
  const [detail, setDetail] = useState(false);
  const DetailUcapan = () => {
    setDetail(false);
  };
  const DetailMetode = () => {
    setDetail(true);
  };
  //================================================Login Handle
  const [loginToken, setLoginToken] = useState(sessionStorage.getItem("token"));
  const setToken = (token) => {
    setLoginToken(token);
  };
  //=================================================Userlogin
  const initialUserLogin = {
    fk_id_rd: "",
    username: "",
    email: "",
    tlp: "",
    pk_id_user: "",
    role: "",
    token: "",
    fk_id_agent: 0,
    discount: 0,
    saldo: 0,
    fk_id_sponsor: 0,
  };
  //===============================================ResponseModal
  const initialRuanganTemplate = {
    pk_id_rd: "",
    fk_id_template: "",
    universal_template: "",
  };
  const [DataRuanganTemplate, setDataRuanganTemplate] = useState(initialRuanganTemplate);
  const setToRuanganTemplate = (data) => {
    setDataRuanganTemplate(data);
  };
  const wipeToRuanganTemplate = () => {
    setDataRuanganTemplate(initialRuanganTemplate);
  };
  const userHandle = async () => {
    if (loginToken) {
      const user = jwt(loginToken);
      if (user) {
        setInterval(function () {
          checkTokenExpiration(loginToken);
        }, 5 * 60 * 1000);
        const url =
          process.env.REACT_APP_BASE_URL +
          "api/user/getuserbyid/" +
          user[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication"
          ];
        await axios
          .get(url, {
            headers: {
              Authorization: `bearer ${loginToken}`,
            },
          })
          .then((response) => {
            if (response.data) {
              setUser(response.data);
            } else {
              setUser(initialUserLogin);
            }
          })
          .catch((error) => {
            console.log(error);
            if (
              typeof error.response.data === "string" &&
              error.response.data !== ""
            ) {
              if (error.response.data.length < 100) {
                setErrorServer(error.response.data);
              } else {
                setErrorServer(
                  "Terjadi error saat pengambilan data user, Mohon coba lagi"
                );
              }
              openErrorBox();
            }
          });
      }
    }
  };

  const [user, setUser] = useState(initialUserLogin);
  const setUserLogin = (user) => {
    setUser(user);
  };
  const setUserLogout = () => {
    setUser(initialUserLogin);
  };

  useEffect(() => {
    userHandle();
  }, [loginToken]);

  function checkTokenExpiration(loginToken) {

    const decodedToken = jwt(loginToken);
    const currentTimestamp = Math.floor(Date.now() / 1000); // Waktu saat ini dalam UNIX timestamp (detik)
    const expirationTimestamp = decodedToken.exp; // Waktu kedaluwarsa dalam JWT (UNIX timestamp)

    if (currentTimestamp >= expirationTimestamp) {

      setUserLogout();
      setToken(null);
      sessionStorage.removeItem("token");
      
      setToOpenResponse({
        pathImage: "ResetPW.png",
        tittle: "Sesi Berakhir",
        massage: "Sesi login anda berakhir, login kembali untuk melanjutkan",
        nameButton: "Tutup",
      });
    }
  }

  //=======================================================form data pesan
  const initialPesan = {
    uploaded_ucapan:"",
    fk_id_user: "",
    fk_id_mendiang: "",
    disc: "",
    harga: "",
    pesan_user: {
      logo: "",
      pengirim: "",
      keterangan_penerima: "",
      keterangan_pengirim: "",
      pesan: "",
      fk_id_template: "",
    }
  };

  const [dataPesan, setDataPesan] = useState(initialPesan);
  const sendDataPesan = (data) => {
    setDataPesan(data);
  };

  //=======================================================Error handling
  const [openError, setOpenError] = useState(false);
  const [errorServer, setErrorServer] = useState("Error");
  const closeErrorBox = () => {
    setOpenError(false);
  };
  const openErrorBox = () => {
    setOpenError(true);
  };

  return (
    <globalContext.Provider
      value={{
        setOpenSidebar,
        setCloseSidebar,
        open,
        detail,
        DetailUcapan,
        DetailMetode,
        openRegister,
        setToOpenRegister,
        setToCloseRegister,
        openMasuk,
        setToOpenMasuk,
        setToCloseMasuk,
        openGantiPW,
        setToOpenGantiPW,
        setToCloseGantiPW,
        dataKonfirm,
        setDataKonfirm,
        openKonfirm,
        setToOpenKonfirm,
        setToCloseKonfirm,
        dataResponse,
        openResponse,
        setToOpenResponse,
        setToCloseResponse,
        loginToken,
        setToken,
        user,
        setUserLogin,
        setUserLogout,
        dataPesan,
        sendDataPesan,
        openError,
        closeErrorBox,
        openErrorBox,
        errorServer,
        setErrorServer,
        DataRuanganTemplate,
        setToRuanganTemplate,
        wipeToRuanganTemplate
      }}
    >
      {children}
    </globalContext.Provider>
  );
}

export default GlobalContext;
