import { CheckCircleOutline, Close } from "@mui/icons-material";
import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { styleBoxModal } from "../Helper/style/style";
function Confirm({ openKonfirm, setToCloseKonfirm }) {

  return (
    <Modal
      open={openKonfirm}
      onClose={() => {
        setToCloseKonfirm();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleBoxModal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center ",
            width: "100%",
          }}
        >
          <Typography variant="h5" textAlign="center" sx={{ fontWeight: "bold" }}>
            Apakah Anda yakin?
          </Typography>
          <Box sx={{ display: "flex", mt: 2 , gap :5}}>
            <Button
            color="success"
            variant="contained"
                        size="small"
      
              endIcon={<CheckCircleOutline />}
              onClick={() => {
                setToCloseKonfirm(true);
              }}
              sx={{color:'white', borderRadius:'99px'}}
            >
              Ya
            </Button>
            <Button
            color="error"
            variant="contained"
            size="small"
     
              endIcon={<Close />}
              onClick={() => {
                setToCloseKonfirm(false);
              }}
              sx={{color:'white', borderRadius:'99px'}}
            >
              Tidak
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default Confirm;
