import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { useState } from "react";
import ErrorBox from "../../Components/ErrorBox";
import axios from "axios";
import {
  Close,
  LockReset,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { styleBoxModal } from "../../Helper/style/style";

function GantiPassword() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    openGantiPW,
    setToCloseGantiPW,
    user,
    loginToken,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;

  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //==============================================================validation
  const initialState = {
    password_lama: "",
    password_baru: "",
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    const validationErrors = {};

    if (!data.password_baru) {
      validationErrors.password_baru = "Masukan Password Baru";
    } else {
      const valid =
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,}$/.test(
          data.password_baru
        );
      !valid &&
        (validationErrors.password_baru =
          "Password baru minimal 8 huruf dengan 1 angka dan 1 huruf");
    }
    if (!data.password_lama) {
      validationErrors.password_lama = "Masukan Password Lama";
    }
    if (!data.konfirmasi_password_baru) {
      validationErrors.konfirmasi_password_baru = "Masukan konfirmasi password";
    } else {
      const valid = data.password_baru === data.konfirmasi_password_baru;
      !valid &&
        (validationErrors.konfirmasi_password_baru = "Password tidak cocok");
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      setToOpenKonfirm();
    }
  };

  useEffect(() => {
    if (dataKonfirm === true) {
      handleSubmit();
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm(null);
  }, [dataKonfirm]);

  //========================================handling input

  const [data, setData] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    setData({
      ...data,
      pk_id_user: user.pk_id_user,
    });
  }, [user]);

  //=======================================handling submit
  const handleSubmit = async () => {
    if (!data.password_lama) {
      return;
    }
    const url = process.env.REACT_APP_BASE_URL + "api/user/ganti-password";
    await axios
      .put(url, data, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        setToCloseGantiPW();
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer(
            "Terjadi error saat mengganti password, Mohon coba lagi"
          );
        }
        openErrorBox();
      });
    handleCloseLoading();
  };

  return (
    <Modal
      open={openGantiPW}
      onClose={() => {
        setToCloseGantiPW();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleBoxModal}>
        <Box
          sx={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "white",
            position: "absolute",
            right: "-10px",
            top: "-10px",
            border: "solid",
            borderRadius: "50px",
            borderColor: "primary.main",
          }}
        >
          <Typography
            sx={{ color: "primary.main" }}
            onClick={() => {
              setToCloseGantiPW();
            }}
          >
            <Close />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 3,
          }}
        >
          <ErrorBox sx={{ mb: "3" }} />

          <Box>
            <Typography variant="h4">Ganti Password</Typography>
            <FormControl
              sx={{ mt: 1 }}
              fullWidth
              variant="outlined"
              error={!!errors.password_lama}
              helperText={errors.password_lama}
            >
              <InputLabel
                error={!!errors.password_lama}
                size="small"
                htmlFor="outlined-adornment-password"
              >
                Password Lama
              </InputLabel>
              <OutlinedInput
                fullWidth
                required
                size="small"
                id="password_lama"
                name="password_lama"
                value={data.password_lama}
                onChange={handleInputChange}
                label="Password Lama"
                variant="outlined"
                error={!!errors.password_lama}
                helperText={errors.password_lama}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error>{errors.password_lama}</FormHelperText>
            </FormControl>
            <FormControl
              sx={{ mt: 1 }}
              fullWidth
              variant="outlined"
              error={!!errors.password_baru}
              helperText={errors.password_baru}
            >
              <InputLabel
                error={!!errors.password_baru}
                size="small"
                htmlFor="outlined-adornment-password"
              >
                Password Baru
              </InputLabel>
              <OutlinedInput
                fullWidth
                required
                size="small"
                id="password_baru"
                name="password_baru"
                value={data.password_baru}
                onChange={handleInputChange}
                label="Password baru"
                variant="outlined"
                error={!!errors.password_baru}
                helperText={errors.password_baru}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error>{errors.password_baru}</FormHelperText>
            </FormControl>
            <FormControl
              sx={{ mt: 1 }}
              fullWidth
              variant="outlined"
              error={!!errors.konfirmasi_password_baru}
              helperText={errors.konfirmasi_password_baru}
            >
              <InputLabel
                error={!!errors.konfirmasi_password_baru}
                size="small"
                htmlFor="outlined-adornment-password"
              >
                Konfirmasi Password
              </InputLabel>
              <OutlinedInput
                fullWidth
                required
                size="small"
                id="konfirmasi_password_baru"
                name="konfirmasi_password_baru"
                value={data.konfirmasi_password_baru}
                onChange={handleInputChange}
                label="Konfirmasi password"
                variant="outlined"
                error={!!errors.konfirmasi_password_baru}
                helperText={errors.konfirmasi_password_baru}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error>
                {errors.konfirmasi_password_baru}
              </FormHelperText>
            </FormControl>

            <LoadingButton
              endIcon={<LockReset />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                handleLoading();
                checkValidate();
              }}
              variant="contained"
              sx={{
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                mt: 3,
              }}
            >
              Ganti Password
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default GantiPassword;
