import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Typography,
  Modal,
  Fade,
  TextField,
  MenuItem,
  Grid,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import jwt from "jwt-decode";
import { Search, Send } from "@mui/icons-material";
import "../../Helper/format/currency";
import ErrorBox from "../../Components/ErrorBox";
import {
  styleBoxModal,
  StyledTableCell,
  StyledTableRow,
} from "../../Helper/style/style";
import { LoadingButton } from "@mui/lab";

function DaftarAllKeranjang() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //=========================================================authorize

  const history = useHistory();

  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role !== "super") {
      history.push("/");
    }
  };
  //==============================================================Get Data
  const [data, setData] = useState([]);

  const getDaftarPesan = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/Keranjang/keranjang";
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
      setData(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data keranjang, Mohon  coba lagi"
        );
      }
      openErrorBox();
    }
  };

  useEffect(() => {
    getDaftarPesan(/*usrid*/);
    auth();
  }, []);
  //================================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");
  function searchFunction(query) {
    query = query.toString().toLowerCase(); // Convert the query to lowercase for case-insensitive search

    return data.filter((searchText) => {
      const filteredText = searchText.username_pengirim
        .toLowerCase()
        .includes(query);
      const filteredText2 = searchText.pk_id_keranjang
        .toString()
        .toLowerCase()
        .includes(query);
      return filteredText || filteredText2;
    });
  }

  const handleInputChangeSearch = (e) => {
    const { name, value } = e.target;
    // setInputSearchData(value)
    setInputSearchData({
      ...inputSearchData,
      [name]: value,
    });
    setSearchData(searchFunction(value));
  };
  useEffect(() => {
    setSearchData(data);
  }, [data]);
  //================================================================view modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showImg, setShowImg] = useState();
  //================================================================edit modal
  const [dataEdit, setDataEdit] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //========================================handling input

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDataEdit({
      ...dataEdit,
      [name]: value,
    });
  };
  //=======================================================validate
  const [errors, setErrors] = useState({ status_pesan: "" });

  const checkValidate = () => {
    const validationErrors = {};

    if (dataEdit.status_pesan === null) {
      validationErrors.status_pesan = "Pilih Status Pesan";
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      setToOpenKonfirm();
    }
  };
  //===========================================================handling submit edit

  useEffect(() => {
    if (dataKonfirm === true) {
      if (dataEdit.status_pesan !== null) {
        handleSubmitEdit();
      }
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm();
  }, [dataKonfirm]);

  const handleSubmitEdit = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/keranjang/keranjang";
    await axios
      .put(url, dataEdit, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        getDaftarPesan();
        handleCloseEdit();
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat edit, Mohon  coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };
  //=====================================================countdown
  const [countdownData, setCountdownData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const intervalId = setInterval(() => {
        const currentDate = new Date().getTime();
        const updatedCountdownData = data.map((event) => {
          const timeRemaining = new Date(event.exp_time) - currentDate;
          const hours = Math.floor(
            (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

          if (hours < 0) {
            return "WAKTU HABIS";
          }
          const time = hours + ":" + minutes + ":" + seconds.toString();
          return time;
        });
        if (updatedCountdownData) {
          setCountdownData(updatedCountdownData);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [data]);
  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 5,
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" mr={2}>
                  Daftar Semua Keranjang
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  placeholder="Search…"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  name="searchText"
                  onChange={handleInputChangeSearch}
                  value={inputSearchData.searchText}
                />
              </Grid>
            </Grid>
          </Box>
          <TableContainer sx={{ my: 5 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell align="center">
                    Nama Pengirim
                  </StyledTableCell>
                  <StyledTableCell align="center">Kepada</StyledTableCell>
                  <StyledTableCell align="center">Harga</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Exp</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <StyledTableRow key={row.pk_id_keranjang}>
                    <StyledTableCell component="th" scope="row">
                      {row.pk_id_keranjang}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.username_pengirim}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.nama_mendiang}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.harga.currency()}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.status_pembayaran ? "Sudah Bayar" : "Belum Bayar"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {countdownData[index]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        color="ok"
                        variant="contained"
                        size="small"
                        sx={{ m: 1, color: "white" }}
                        onClick={() => {
                          setShowImg(row.image_pesan_path);
                          handleOpen();
                        }}
                      >
                        Lihat Ucapan
                      </Button>
                      <Button
                        color="success"
                        variant="contained"
                        size="small"
                        sx={{ m: 1, color: "white" }}
                        onClick={() => {
                          setDataEdit({
                            fk_id_mendiang: row.fk_id_mendiang,
                            grenate_time: row.grenate_time,
                            fk_id_pu: row.fk_id_pu,
                            image_pesan_path: row.image_pesan_path,
                            pk_id_keranjang: row.pk_id_keranjang,
                            status_pesan: row.status_pesan,
                            status_pembayaran: row.status_pembayaran,
                          });
                          handleOpenEdit();
                        }}
                      >
                        Edit Status
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleBoxModal}>
            <img
              src={process.env.REACT_APP_BASE_URL + showImg}
              style={{ width: "100%" }}
              alt=""
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openEdit}
        onClose={handleCloseEdit}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openEdit}>
          <Box sx={styleBoxModal}>
            <Typography>Edit Status Pesan</Typography>
            <TextField
              fullWidth
              required
              size="small"
              id="status_pesan"
              name="status_pesan"
              onChange={handleInputChange}
              label="Update Status Pesan"
              variant="outlined"
              select
              defaultValue={dataEdit.status_pesan}
              sx={{ mt: 3 }}
              error={!!errors.status_pesan}
              helperText={errors.status_pesan}
            >
              <MenuItem value={null}>Pilih Status Pesan</MenuItem>
              <MenuItem value={true}>Diterima</MenuItem>
              <MenuItem value={false}>Ditolak</MenuItem>
            </TextField>
            <Typography mt={5}>Edit Status Pembayaran</Typography>
            <TextField
              fullWidth
              required
              size="small"
              id="status_pembayaran"
              name="status_pembayaran"
              onChange={handleInputChange}
              label="Update Status Pesan"
              variant="outlined"
              select
              value={dataEdit.status_pembayaran}
              sx={{ mt: 3 }}
            >
              <MenuItem value={false}>Belum Dibayar</MenuItem>
              <MenuItem value={true}>Sudah Dibayar</MenuItem>
            </TextField>
            <LoadingButton
              color="success"
              size="small"
              endIcon={<Send />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                checkValidate();
                handleLoading();
              }}
              variant="contained"
              sx={{
                px: 2,
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                whiteSpace: "nowrap",
              }}
            >
              Simpan
            </LoadingButton>

            <Button
              color="error"
              variant="contained"
              size="small"
              sx={{
                m: 1,
                px: 2,
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                whiteSpace: "nowrap",
              }}
              onClick={() => {
                handleCloseEdit();
              }}
            >
              Batal
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Layout>
  );
}

export default DaftarAllKeranjang;
