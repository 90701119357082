import { Handshake, LocationCity, Logout, Password, Person, ShoppingCart } from "@mui/icons-material";
import { Badge, Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link as DirectLink, useHistory } from "react-router-dom";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import GantiPassword from "../../Page/User/GantiPassword";
import { useEffect } from "react";


function HeaderBtnLogin() {
  const loginContext = useGlobal();
  const { setToken, setUserLogout } = loginContext;
  const history = useHistory();
  
  const keranjang =()=>{
    history.push('/pesan/keranjang')
  }
  const [show, setShow] = useState(0);
  useEffect(() => {
    const calculate_contaner=()=>{
      const containerWidth = window.innerWidth;
      if(containerWidth<520){
        setShow(false)
      }else{
        setShow(true)
      }
    }
    calculate_contaner();
    // Attach the event listener for window resize
    window.addEventListener('resize', calculate_contaner);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', calculate_contaner);
    }
  }, [])

  //==================================================Context
  const masukContext = useGlobal();
  const { user,setToOpenGantiPW } = masukContext;
  //==================================================Popover user
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <GantiPassword/>
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <List 
        component="nav"
          subheader={
            <ListSubheader component="div">
              Pengaturan
            </ListSubheader>
          }>
          <ListItem disablePadding sx={{display:'flex', flexDirection:"column", alignItems:'start'}}>
            {user.role === 'admin'&&
            <ListItemButton  
              onClick={() => {
                history.push(`/view_rd/${user.fk_id_rd}`)
              }}>
              <ListItemIcon>
                <LocationCity />
              </ListItemIcon>
              <ListItemText primary="Rumah Duka" />
            </ListItemButton>
            }
            {user.role === "admin" || user.role === "super" ? (
              <ListItemButton  
                onClick={() => {
                  history.push(`/custom-twibbon`)
                }}>
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary="Buat Custom Twibbon" />
              </ListItemButton>
               
              ):(
                <Box/>
              )}
            {user.fk_id_sponsor > 0&&
            <ListItemButton  
              onClick={() => {
                history.push(`/sponsor/edit/${user.fk_id_sponsor}`)
              }}>
              <ListItemIcon>
                <Handshake />
              </ListItemIcon>
              <ListItemText primary="Edit Sponsor" />
            </ListItemButton>
            }
            <ListItemButton
            onClick={() =>{
              setToOpenGantiPW();
            }}>
              <ListItemIcon>
                <Password />
              </ListItemIcon>
              <ListItemText primary="Ganti Password" />
            </ListItemButton>
            <ListItemButton
            onClick={() => {
              setUserLogout();
              setToken(null);
              sessionStorage.removeItem("token");
              window.location.reload(false);
            }}
            >
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Keluar" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    {user.role=="user"&&user.fk_id_agent===0?
      <Badge badgeContent={0} color="tertiary" sx={{cursor:"pointer"}}>
        <ShoppingCart sx={{  color: "white" }} onClick={keranjang} />
      </Badge> :
      <Box/>
    }
    {user.role!=="super"&&
    <>
      <Button
        sx={{color: "white" }}
        component={DirectLink}
        to="/pesan/pesanku"
        variant="contained"
        disableElevation
        size="small"
      >
        PesanKu
      </Button>
      <Typography sx={{ color: "white" }}>|</Typography>
      </>
      }
      <Box onClick={handleClick} sx={{cursor:"pointer", display:'flex', alignItems:'center'}} >

      <Person sx={{ color: "white" }} onClick={() => {}} />
      {show&&(
      <Typography sx={{color: "white" }}>{user.username}</Typography>
      )}
      </Box>
     
    </Box>
  );
}

export default HeaderBtnLogin;
