import { createTheme } from "@mui/material";
import Affinity_Future from "../Fonts/Affinity-Future.woff2"

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#BC8EEE",
      light: "#DDABF4",
    },
    secondary: {
      main: "#E1AEFF",
      contrastText: "#fff",
      hitam: "#333333",
    },
    tertiary: {
      main: "#fff",
      "&:hover": {
        backgroundColor: "#999",
      },
      contrastText: "#222",
    },
    success:{
      main:"#24D324"
    },
    ok:{
      main:"#0098FF"
    },
    error:{
      main:"#FF2E65"
    },
    warning:{
      main:"#FFC107"
    },
    
    Button: { textTransform: "none" },
  },
  typography: {
    
    fontFamily: "Poppins,Affinity Future",
    button: {
      textTransform: "none",
      '@media (max-width:450px)': {
        fontSize: '0.6rem',
      },
    },
    h1:{
      '@media (max-width:450px)': {
        fontSize: '3.5rem',
      },
    },
    h2:{
      '@media (max-width:450px)': {
        fontSize: '2.7rem',
      },
    },
    h3:{
      '@media (max-width:450px)': {
        fontSize: '2rem',
      },
    },
    h4:{
      '@media (max-width:450px)': {
        fontSize: '1.6rem',
      },
    },
    h5:{
      '@media (max-width:450px)': {
        fontSize: '1.2rem',
      },
    },
    h6:{
      '@media (max-width:450px)': {
        fontSize: '1rem',
      },
    },
    body1:{
      '@media (max-width:450px)': {
        fontSize: '0.8rem',
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Affinity Future';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Affinity Future'), local('Affinity Future'), url(${Affinity_Future}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },


});
