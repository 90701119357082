import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ErrorBox from "../../Components/ErrorBox";
import { LockReset, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useGlobal } from "../../Helper/Context/GlobalContext";

function ResetPassword() {
  //==================================================modal
  const registerContext = useGlobal();
  const { openErrorBox, setErrorServer } = registerContext;

  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //============================================================ State Data
  const [reset, setReset] = useState("");
  const [konfirm, setKonfirm] = useState("");

  const { result } = useParams(); //=======Email
  //========================================================================validation
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const initialState = {
    password: "",
    konfirm: "",
  };
  const [errors, setErrors] = useState(initialState);
  const checkValidate = () => {
    const validationErrors = {};

    if (!reset) {
      validationErrors.password = "Masukan Password";
    } else {
      const valid =
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,}$/.test(
          reset
        );
      !valid &&
        (validationErrors.password =
          "Password minimal 8 huruf dengan 1 angka dan 1 huruf");
    }
    if (reset !== konfirm) {
      validationErrors.konfirm = "Password Tidak Sama";
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      handleSubmit();
    }
  };

  //==============================================================  Handling Submit
  const history = useHistory();
  const handleSubmit = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/user/lupaPassword";
    await axios
      .put(url, {
        result: result,
        password: reset,
      })
      .then(() => {
        history.push("/response/password-reset");
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat reset password, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };
  return (
    <Box>
      <Box sx={{ display: "flex", m: 3, textDecoration: "none" }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontFamily: "Affinity Future", color: "primary.main" }}
        >
          InMemoriam.id
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mt: 6,
        }}
      >
        <Box
          sx={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" fontWeight="bold" mb={1}>
            Reset Password
          </Typography>

          <ErrorBox />

          <FormControl
            sx={{ mt: 3 }}
            fullWidth
            variant="outlined"
            error={!!errors.password}
          >
            <InputLabel error={!!errors.password} size="small">
              Password
            </InputLabel>
            <OutlinedInput
              fullWidth
              required
              size="small"
              id="password"
              name="password"
              value={reset}
              onChange={(e) => setReset(e.target.value)}
              label="Password"
              variant="outlined"
              error={!!errors.password}
              helperText={errors.password}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    //onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>{errors.password}</FormHelperText>
          </FormControl>
          <FormControl
            sx={{ mt: 3 }}
            fullWidth
            variant="outlined"
            error={!!errors.konfirm}
          >
            <InputLabel error={!!errors.konfirm} size="small">
              Konfirmasi Password
            </InputLabel>
            <OutlinedInput
              fullWidth
              required
              size="small"
              id="konfirmasi_password"
              name="konfirmasi_password"
              value={konfirm}
              onChange={(e) => setKonfirm(e.target.value)}
              label="Konfirmasi Password"
              variant="outlined"
              error={!!errors.konfirm}
              helperText={errors.konfirm}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    //onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>{errors.konfirm}</FormHelperText>
          </FormControl>

          <LoadingButton
            // disabled={disabled}
            loading={loadingSts}
            loadingPosition="end"
            onClick={() => {
              handleLoading();
              checkValidate();
            }}
            endIcon={<LockReset />}
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 3, color: "white", borderRadius: "99px" }}
          >
            Konfirmasi
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPassword;
