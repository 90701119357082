import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import Backdrop from "@mui/material/Backdrop";
import {
  Box,
  FormHelperText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";

import { useGlobal } from "../../Helper/Context/GlobalContext";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { ConfirmationNumber } from "@mui/icons-material";

import jwt from "jwt-decode";

function FormTambahVoucher() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //=========================================================authorize

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role != "super") {
      history.push("/");
    }
  };

  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //========================================handling input
  const initialState = {
    nama_voucher: "",
    jenis_potongan: "persentase",
    exp_date: "",
    nominal: 0,
  };
  const [data, setData] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "nominal") {
      setData({
        ...data,
        [name]: parseInt(value),
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    auth();
  }, []);
  //==============================================================validation
  const initialStateError = {
    nama_voucher: "",
    jenis_potongan: "",
    exp_date: "",
  };
  const [errors, setErrors] = useState(initialStateError);

  const checkValidate = () => {
    const validationErrors = {};
    if (!data.nama_voucher) {
      validationErrors.nama_voucher = "Masukan Nama Voucher";
    }
    if (!data.nominal) {
      validationErrors.nominal = "Masukan Nominal";
    }
    if (!data.jenis_potongan) {
      validationErrors.jenis_potongan = "Masukan Jenis Potongan";
    }
    if (!data.exp_date) {
      validationErrors.exp_date = "Masukan Tanggal Expired";
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      setToOpenKonfirm();
    }
  };
  //========================================handling submit
  useEffect(() => {
    if (dataKonfirm === true) {
      handleSubmit();
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm(null);
  }, [dataKonfirm]);

  const handleSubmit = async () => {

    const url = process.env.REACT_APP_BASE_URL + "api/voucher/voucher";
    await axios
      .post(url, data, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        history.push("/voucher");
      })
      .catch((error) => {

        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat tambah voucher, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" textAlign="center" my={4}>
            Form Tambah Voucher
          </Typography>

          <ErrorBox />
          <TextField
            fullWidth
            required
            size="small"
            id="nama_voucher"
            name="nama_voucher"
            onChange={handleInputChange}
            label="Masukan nama voucher"
            variant="outlined"
            sx={{ mt: 3 }}
            error={!!errors.nama_voucher}
            helperText={errors.nama_voucher}
          />
          <Box sx={{ display: "flex", mt: 3, width: "100%", gap: 3 }}>
            <Box sx={{ width: "80%" }}>
              <TextField
                fullWidth
                required
                size="small"
                id="nominal"
                name="nominal"
                onChange={handleInputChange}
                label="Masukan nominal"
                variant="outlined"
                type="number"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={!!errors.nominal}
                helperText={errors.nominal}
              />
            </Box>
            <Box sx={{ width: "20%" }}>
              <TextField
                required
                fullWidth
                size="small"
                id="jenis_potongan"
                name="jenis_potongan"
                onChange={handleInputChange}
                label="Pilih jenis potongan"
                variant="outlined"
                defaultValue="persentase"
                select
                error={!!errors.jenis_potongan}
                helperText={errors.jenis_potongan}
              >
                <MenuItem value="persentase">%</MenuItem>
                <MenuItem value="rupiah">Rupiah</MenuItem>
              </TextField>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              id="exp_date"
              label="Tanggal Expired"
              disablePast
              format="DD MMM YYYY"
              onChange={(e) =>
                setData({
                  ...data,
                  exp_date: e.format("YYYY-MM-DD"),
                })
              }
              error={!!errors.exp_date}
              helperText={errors.exp_date}
            />
            <FormHelperText error>{errors.exp_date}</FormHelperText>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              mt: 5,
            }}
          >
            <LoadingButton
              endIcon={<ConfirmationNumber />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                handleLoading();
                checkValidate();
              }}
              variant="contained"
              sx={{
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                mt: 3,
              }}
            >
              Simpan
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default FormTambahVoucher;
