import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../Components/Layout";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ErrorBox from "../../Components/ErrorBox";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { SaveAs } from "@mui/icons-material";
import jwt from "jwt-decode";
import { baseStyle } from "../../Helper/style/style";
//===============================================style dropzone

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
//========================================end dropzone style
function FormTambahResumeTemplate() {
  //==================================================Context
  const masukContext = useGlobal();
  const { loginToken, openErrorBox, setErrorServer } = masukContext;
  //=========================================================authorize

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role !== "super") {
      history.push("/");
    }
  };
  //=======================================dropzone handle svg
  const [SVGFile, setSVGFile] = useState();

  const {
    getRootProps: getRootSVGProps,
    getInputProps: getInputSVGProps,
    isFocused: isFocusedSVG,
    isDragAccept: isDragAcceptSVG,
    isDragReject: isDragRejectSVG,
    isDragActive: isDragActiveSVG,
    acceptedFiles: acceptedFilesSVG,
  } = useDropzone({ accept: { "image/svg+xml": [".svg"] } });

  const stylesvg = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedSVG ? focusedStyle : {}),
      ...(isDragAcceptSVG ? acceptStyle : {}),
      ...(isDragRejectSVG ? rejectStyle : {}),
    }),
    [isFocusedSVG, isDragAcceptSVG, isDragRejectSVG]
  );

  useEffect(() => {
    setSVGFile(acceptedFilesSVG[0]);
  }, [acceptedFilesSVG]);

  const hapusSvg = () => {
    setSVGFile();
  };

  //=======================================dropzone handle svg
  const [SVGTvFile, setSVGTvFile] = useState();

  const {
    getRootProps: getRootSVGTvProps,
    getInputProps: getInputSVGTvProps,
    isFocused: isFocusedSVGTv,
    isDragAccept: isDragAcceptSVGTv,
    isDragReject: isDragRejectSVGTv,
    isDragActive: isDragActiveSVGTv,
    acceptedFiles: acceptedFilesSVGTv,
  } = useDropzone({ accept: { "image/svg+xml": [".svg"] } });

  const stylesvgtv = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedSVGTv ? focusedStyle : {}),
      ...(isDragAcceptSVGTv ? acceptStyle : {}),
      ...(isDragRejectSVGTv ? rejectStyle : {}),
    }),
    [isFocusedSVGTv, isDragAcceptSVGTv, isDragRejectSVGTv]
  );

  useEffect(() => {
    setSVGTvFile(acceptedFilesSVGTv[0]);
  }, [acceptedFilesSVGTv]);

  const hapusSvgTv = () => {
    setSVGTvFile();
  };

  //=======================================dropzone handle thumb
  const [file, setFile] = useState(null);
  const {
    getRootProps: getRootThumbProps,
    getInputProps: getInputThumbProps,
    isFocused: isFocusedThumb,
    isDragAccept: isDragAcceptThumb,
    isDragReject: isDragRejectThumb,
    isDragActive: isDragActiveThumb,
    acceptedFiles: acceptedFilesThumb,
  } = useDropzone({ accept: { "image/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedThumb ? focusedStyle : {}),
      ...(isDragAcceptThumb ? acceptStyle : {}),
      ...(isDragRejectThumb ? rejectStyle : {}),
    }),
    [isFocusedThumb, isDragAcceptThumb, isDragRejectThumb]
  );
  const createLink = () => {
    if (acceptedFilesThumb.length !== 0) {
      setFile(URL.createObjectURL(acceptedFilesThumb[0]));
    }
  };
  useEffect(() => {
    createLink();
  }, [acceptedFilesThumb]);

  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //========================================edit handle
  const params = useParams();
  const getData = () => {
    if (params.id) {
      const getUrl =
        process.env.REACT_APP_BASE_URL +
        "api/ResumeTemplate/resume-templateById?id=" +
        params.id;
      axios
        .get(getUrl)
        .then((response) => {
          setData(response.data);
          setFile(
            process.env.REACT_APP_BASE_URL +
              response.data.path_thumb_resume_template
          );
        })
        .catch((error) => {
          if (error.response.data.length < 100) {
            setErrorServer(error.response.data);
          } else {
            setErrorServer(
              "Terjadi error saat mengambil data template, Mohon coba lagi"
            );
          }
          openErrorBox();
        });
    }
  };

  const [dataRD, setDataRD] = useState([]);

  const getDaftarRD = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/RumahDuka/RumahDuka";
    try {
      const response = await axios.get(url);
      setDataRD(
        response.data.map((e) => {
          return {
            pk_id_rd: e.pk_id_rd,
            nama_rd: e.nama_rd,
            alamat: e.alamat,
            commission: e.commission,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  useEffect(() => {
    getData();
    auth();
    getDaftarRD();
  }, []);

  //========================================handling input

  const initialState = {
    nama_resume_template: "",
    fk_id_custom_resume: 0,
  };
  const [data, setData] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  //==============================================================validation

  const [errors, setErrors] = useState({
    nama_template: "",
  });

  const checkValidate = (action_button) => {
    const validationErrors = {};
    if (!SVGFile && !data.path_resume_template) {
      validationErrors.path_resume_template = "Masukan Resume SVG";
    }
    if (!SVGTvFile && !data.path_resume_template_tv) {
      validationErrors.path_resume_template_tv = "Masukan Resume SVG TV";
    }
    if (!file && !data.path_thumb_template) {
      validationErrors.path_thumb_template = "Masukan Thumbnail";
    }
    if (!data.nama_resume_template) {
      validationErrors.nama_resume_template = "Masukan Nama Resume Template";
    }
    if (!data.max_width) {
      validationErrors.max_width = "Masukan Lebar Maksimal Untuk Twibbon";
    }
    if (!data.max_width_tv) {
      validationErrors.max_width_tv = "Masukan Lebar Maksimal Untuk Twibbon TV";
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      action_button();
    }
  };

  //=========================================handle submit edit
  const handleSubmitEdit = async () => {
    const formData = new FormData();
    if (SVGFile) {
      formData.append("svg", SVGFile);
    } else {
      formData.append("path_resume_template", data.path_resume_template);
    }
    if (SVGTvFile) {
      formData.append("svgTv", SVGTvFile);
    } else {
      formData.append("path_resume_template_tv", data.path_resume_template_tv);
    }
    if (acceptedFilesThumb[0]) {
      formData.append("thumb", acceptedFilesThumb[0]);
    } else {
      formData.append("path_thumb_template", data.path_thumb_resume_template);
    }
    formData.append("nama_resume_template", data.nama_resume_template.trim());
    formData.append("pk_id_resume_template", data.pk_id_resume_template);
    formData.append("fk_id_custom_resume", data.fk_id_custom_resume);
    formData.append("max_width", data.max_width);
    formData.append("max_width_tv", data.max_width_tv);
    const url =
      process.env.REACT_APP_BASE_URL + "api/ResumeTemplate/resume-template";
    await axios
      .put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        history.push(`/resume-template/daftar`);
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat menambah, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };
  //=========================================handle submit

  const handleSubmit = async () => {
    if (
      acceptedFilesThumb.length === 0 ||
      acceptedFilesSVG.length === 0 ||
      acceptedFilesSVGTv.length === 0
    ) {
      handleCloseLoading();
      setErrorServer("Foto Belum di Upload");
      openErrorBox();
      return;
    }

    const formData = new FormData();
    formData.append("svg", acceptedFilesSVG[0]);
    formData.append("svgTv", acceptedFilesSVGTv[0]);
    formData.append("thumb", acceptedFilesThumb[0]);
    formData.append("nama_resume_template", data.nama_resume_template.trim());
    formData.append("fk_id_custom_resume", data.fk_id_custom_resume);
    formData.append("max_width", data.max_width);
    formData.append("max_width_tv", data.max_width_tv);

    const url =
      process.env.REACT_APP_BASE_URL + "api/ResumeTemplate/resume-template";
    await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        history.push(`/resume-template/daftar`);
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer(
            "Terjadi error saat menambah template resume, Mohon coba lagi"
          );
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", mt: 7 }}>
            <ErrorBox />

            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography variant="h5">Upload Thumbnail Resume</Typography>
                <Typography mb={5}>
                  Eksistensi file jpg/png dengan maks 200kb
                </Typography>
                {file ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img width={"80%"} src={file} alt="uploaded file" />
                  </Box>
                ) : (
                  <Box sx={{}} />
                )}
                <Box my={3} />
                <Box {...getRootThumbProps({ style })} className="dropzone">
                  <input type="file" {...getInputThumbProps()} />
                  {isDragAcceptThumb && <p>All files will be accepted</p>}
                  {isDragRejectThumb && <p>Some files will be rejected</p>}
                  {isDragActiveThumb
                    ? "Drop the files here ..."
                    : "Drag 'n' drop some files here, or click to select files"}
                </Box>
                <FormHelperText error>
                  {errors.path_thumb_resume_template}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5">Upload SVG File</Typography>
                <Typography mb={5}>
                  Pastikan kode SVG telah diberikan id untuk editor
                </Typography>
                {SVGFile ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>{SVGFile.name}</Typography>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ borderRadius: "100px", color: "white" }}
                      onClick={() => {
                        hapusSvg();
                      }}
                    >
                      Batal
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{
                        ...getRootSVGProps,
                        ...stylesvg,
                        boxSizing: "border-box",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "20px",
                        borderWidth: 2,
                        borderRadius: 2,
                        borderColor: "#eeeeee",
                        borderStyle: "dashed",
                        backgroundColor: "#fafafa",
                        color: "#bdbdbd",
                        outline: "none",
                        transition: "border .24s ease-in-out",
                        width: "100%",
                        mt: 5,
                      }}
                      {...getRootSVGProps({ stylesvg })}
                    >
                      <input {...getInputSVGProps()} />
                      {isDragAcceptSVG && <p>File will be accepted</p>}
                      {isDragRejectSVG && <p>File will be rejected</p>}
                      {isDragActiveSVG
                        ? "Drop the files here ..."
                        : "Drag 'n' drop some files here, or click to select files"}
                    </Box>
                    <FormHelperText error>
                      {errors.path_resume_template}
                    </FormHelperText>
                  </Box>
                )}

                <Typography mt={3} variant="h5">
                  Upload SVG TV File
                </Typography>
                <Typography mb={5}>
                  Pastikan kode SVG TV telah diberikan id untuk editor
                </Typography>
                {SVGTvFile ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>{SVGTvFile.name}</Typography>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ borderRadius: "100px", color: "white" }}
                      onClick={() => {
                        hapusSvgTv();
                      }}
                    >
                      Batal
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "20px",
                        borderWidth: 2,
                        borderRadius: 2,
                        borderColor: "#eeeeee",
                        borderStyle: "dashed",
                        backgroundColor: "#fafafa",
                        color: "#bdbdbd",
                        outline: "none",
                        transition: "border .24s ease-in-out",
                        width: "100%",
                        mt: 5,
                      }}
                      {...getRootSVGTvProps({ stylesvgtv })}
                    >
                      <input {...getInputSVGTvProps()} />
                      {isDragAcceptSVGTv && <p>File will be accepted</p>}
                      {isDragRejectSVGTv && <p>File will be rejected</p>}
                      {isDragActiveSVGTv
                        ? "Drop the files here ..."
                        : "Drag 'n' drop some files here, or click to select files"}
                    </Box>
                    <FormHelperText error>
                      {errors.path_resume_template_tv}
                    </FormHelperText>
                  </Box>
                )}

                <TextField
                  fullWidth
                  size="small"
                  id="nama_resume_template"
                  name="nama_resume_template"
                  value={data.nama_resume_template}
                  onChange={handleInputChange}
                  label="Judul resume template"
                  variant="outlined"
                  sx={{ mt: 3 }}
                  error={!!errors.nama_resume_template}
                  helperText={errors.nama_resume_template}
                />
                <TextField
                  fullWidth
                  size="small"
                  id="fk_id_custom_resume"
                  name="fk_id_custom_resume"
                  onChange={handleInputChange}
                  label="Target Twibbon"
                  variant="outlined"
                  select
                  value={dataRD ? data.fk_id_custom_resume : 0}
                  sx={{ mt: 3 }}
                >
                  <MenuItem value={0}>General Twibbon</MenuItem>
                  {dataRD?.map((option) => (
                    <MenuItem key={option.pk_id_rd} value={option.pk_id_rd}>
                      {option.nama_rd}
                    </MenuItem>
                  ))}
                </TextField>
                <Box sx={{ display: "flex", mt: 3, gap: 3 }}>
                  <TextField
                    fullWidth
                    type="number"
                    required
                    size="small"
                    id="max_width"
                    name="max_width"
                    onChange={handleInputChange}
                    label="Maksimal lebar tulisan"
                    variant="outlined"
                    value={data.max_width || ""}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    error={!!errors.max_width}
                    helperText={errors.max_width}
                  />
                  <TextField
                    fullWidth
                    type="number"
                    required
                    size="small"
                    id="max_width_tv"
                    name="max_width_tv"
                    onChange={handleInputChange}
                    label="Maksimal lebar tulisan tv"
                    variant="outlined"
                    value={data.max_width_tv || ""}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    error={!!errors.max_width_tv}
                    helperText={errors.max_width_tv}
                  />
                </Box>

                <LoadingButton
                  endIcon={<SaveAs />}
                  loading={loadingSts}
                  loadingPosition="end"
                  variant="contained"
                  sx={{ borderRadius: "100px", color: "white", mt: 4 }}
                  onClick={() => {
                    handleLoading();
                    params.id
                      ? checkValidate(handleSubmitEdit)
                      : checkValidate(handleSubmit);
                  }}
                >
                  Simpan
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default FormTambahResumeTemplate;
