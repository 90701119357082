import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Frame from "../../Assets/Avatar_frame.webp";
import { Link as DirectLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import {
  getIndonesianFormattedDate,
  getIndonesianFormattedDateTime,
} from "../../Helper/format/date";

function ListMendiang({ login, data, deleteMendiang, readOnly }) {
  //=============================================button click
  const handleBoxClick = () => {
    // Navigate to another page when the box is clicked
    history.push(`/daftar-mendiang/detail/${data.pk_id_mendiang}`);
  };
  const handleButtonClick = (e) => {
    // Prevent the click event from propagating to the box
    e.stopPropagation();
  };
  //=============================================formating date time
  const date = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  const history = useHistory();
  //=======================================================hover
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const divStyle = {
    // Define the default styles (without shadow)

    backgroundColor: "white",

    // Apply shadow when hovered
    boxShadow: isHovered ? "0 0 10px rgba(0, 0, 0, 0.5)" : "none",

    // Add transition for smooth effect
    transition: "box-shadow 0.3s ease-in-out",
  };
  const handleDownload = async () => {
    try {
      const url_rar =
        process.env.REACT_APP_BASE_URL +
        "api/pesan/pesan/download/" +
        data.pk_id_mendiang;
      const response = await axios.get(url_rar, { responseType: "blob" });

      // Create a temporary URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${data.nama_mendiang}.zip`); // Specify the filename here
      document.body.appendChild(link);

      // Initiate the download
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle error cases here
    }
  };
  //===============================================handle delete

  const handleDelete = deleteMendiang;
  return (
    <Grid
    spacing={2}
      container
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        handleBoxClick();
      }}
      style={divStyle}
      sx={{
        width: "100%",
        padding: 2,
        my: 1,
        borderRadius: 3,
        boxSizing: "border-box",
      }}
    >
      <Grid item xs={12} md={2} >
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              width: 75,
              height: 75,
              borderRadius: 75,
              position: "absolute",
              overflow: "hidden",
            }}
          >
            <img
              src={process.env.REACT_APP_BASE_URL + data.path_image}
              width={75}
              height={75}
              alt={data.path_image}
            />
          </Box>
          <Box position="relative">
            <img src={Frame} width={78} alt="frame" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems:  { xs: "center", md: "start" },
          }}
        >
          <Typography
            sx={{ textAlign: { xs: "center", md: "left" } }}
            variant="h5"
          >
            {data.nama_mendiang}
          </Typography>
          <Typography variant="p" sx={{ textAlign: { xs: "center", md: "left" } }}>
            {`Lahir : ${getIndonesianFormattedDate(data.tanggal_lahir)}`}
          </Typography>
          <Typography variant="p" sx={{ textAlign: { xs: "center", md: "left" } }}>
            {`Wafat : ${getIndonesianFormattedDateTime(data.tanggal_wafat)} ${
              data.utc_difference_time
            }`}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "start" },
          }}
        >
          <Typography
            variant="h5"
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            {data.nama_rd}
          </Typography>
          <Typography variant="p" sx={{ textAlign: { xs: "center", md: "left" } }}>
            Ruangan : {data.nama_ruangan}
          </Typography>
          <Typography variant="p" sx={{ textAlign: { xs: "center", md: "left" } }}>
            {`${getIndonesianFormattedDate(
              data.tanggal_masuk
            )} - ${getIndonesianFormattedDate(data.tanggal_keluar)}`}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={2}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!readOnly ? (
            <Box>
              <Button
                color="ok"
                variant="contained"
                size="small"
                sx={{
                  m: 0.5,
                  color: "white",
                  borderRadius: "99px",
                  fontSize: "9px",
                }}
                component={DirectLink}
                to={`/pesan/order`}
                onClick={(e) => {
                  handleButtonClick(e);
                  sessionStorage.setItem(
                    "selected_mendiang",
                    data.pk_id_mendiang
                  );
                }}
              >
                Kirim Ucapan
              </Button>
              {(login.role === "admin" && login.fk_id_rd === data.fk_id_rd) ||
              login.role === "super" ? (
                <Button
                  color="success"
                  variant="contained"
                  size="small"
                  sx={{
                    m: 0.5,
                    color: "white",
                    borderRadius: "99px",
                    fontSize: "9px",
                  }}
                  component={DirectLink}
                  to={`/edit_mendiang/${data.pk_id_mendiang}`}
                  onClick={(e) => {
                    handleButtonClick(e);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <Box />
              )}

              {login.role === "super" ? (
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  sx={{
                    m: 0.5,
                    fontSize: "9px",
                    color: "white",
                    borderRadius: "99px",
                  }}
                  id={data.pk_id_avatar}
                  onClick={(e) => {
                    handleButtonClick(e);
                    handleDelete();
                  }}
                >
                  Delete
                </Button>
              ) : (
                <Box />
              )}
            </Box>
          ) : (
            <Button
              color="ok"
              variant="contained"
              size="small"
              sx={{
                m: 0.5,
                color: "white",
                borderRadius: "99px",
                fontSize: "9px",
              }}
              onClick={(e) => {
                handleButtonClick(e);
                handleDownload();
              }}
            >
              Download Ucapan
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default ListMendiang;
