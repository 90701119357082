import {
  Box,
  FormControl,
  FormHelperText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ErrorBox from "../ErrorBox";
import { LoadingButton } from "@mui/lab";
import { AddCircle, Close } from "@mui/icons-material";
import axios from "axios";
import "../../Helper/format/currency";
import { useEffect } from "react";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { styleBoxModal } from "../../Helper/style/style";
//============================================style modal

function TopUp({ openModal, closeModal, data }) {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //==============================================================Close Modal
  const onCloseModal = () => {
    closeModal(true);
    setDataAgent({
      ...dataAgent,
      topup: 0,
    });
  };
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }

  //========================================handling input

  const initialState = {
    topup: "",
  };
  const [dataAgent, setDataAgent] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDataAgent({
      ...dataAgent,
      [name]: value,
    });
  };
  //=====================================================================Validate
  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    const validationErrors = {};

    if (!dataAgent.topup) {
      validationErrors.topup = "Masukan Topup";
    } else {
      const valid = dataAgent.topup > 0;
      !valid && (validationErrors.topup = "Topup tidak boleh minus");
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      setToOpenKonfirm();
    }
  };
  //=====================================================================handle submit
  useEffect(() => {
    if (dataKonfirm === true) {
      if (dataAgent.topup > 0) {
        handleSubmit();
      }
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm();
  }, [dataKonfirm]);

  const handleSubmit = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/agent/agent/topup";

    await axios
      .put(url, dataAgent, {
        headers: {
          Authorization: `bearer ${data.loginToken}`,
        },
      })
      .then(() => {
        onCloseModal();
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer(
            "Terjadi error saat topup, Mohon hubungi pengembang dan coba lagi"
          );
        }
        openErrorBox();
      })
      .finally(handleCloseLoading());
  };

  useEffect(() => {
    setDataAgent({
      ...dataAgent,
      pk_id_agent: data.pk_id_agent,
    });
  }, [data]);

  return (
    <Modal
      open={openModal}
      onClose={() => {
        onCloseModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleBoxModal}>
        <Box
          sx={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "white",
            position: "absolute",
            right: "-10px",
            top: "-10px",
            border: "solid",
            borderRadius: "50px",
            borderColor: "primary.main",
          }}
        >
          <Typography
            sx={{ color: "primary.main" }}
            onClick={() => {
              onCloseModal();
            }}
          >
            <Close />
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 3,
          }}
        >
          <ErrorBox sx={{ mb: "3" }} />

          <Typography textAlign="center" variant="h4">
            TopUp Saldo Agent
          </Typography>
          <Typography textAlign="center" sx={{ mt: 3 }}>
            Saldo awal agent {data.saldo.currency()}
          </Typography>
          <FormControl fullWidth>
            <TextField
              fullWidth
              size="small"
              type="number"
              id="topup"
              name="topup"
              onChange={handleInputChange}
              label="Masukan TopUp Saldo"
              variant="outlined"
              sx={{ mt: 3 }}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              error={!!errors.topup}
            />
            <FormHelperText error>{errors.topup}</FormHelperText>
          </FormControl>
          <Typography textAlign="center" sx={{ mt: 3 }}>
            Saldo agent akan menjadi{" "}
            {(
              parseInt(data.saldo) +
              (dataAgent.topup ? parseInt(dataAgent.topup) : 0)
            ).currency()}
          </Typography>

          <LoadingButton
            endIcon={<AddCircle />}
            // disabled={disabled}
            loading={loadingSts}
            loadingPosition="end"
            onClick={() => {
              handleLoading();
              checkValidate();
            }}
            variant="contained"
            sx={{
              borderRadius: "999px",
              textTransform: "none",
              fontWeight: "400",
              marginRight: "20px",
              color: "white",
              mt: 3,
            }}
          >
            TopUp Saldo
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default TopUp;
