import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Backdrop from "@mui/material/Backdrop";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Button,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";
import Avatar from "react-avatar-edit";
import { useDropzone } from "react-dropzone";
import { useMemo } from "react";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { LocationCity } from "@mui/icons-material";
import jwt from "jwt-decode";
import TemplateModal from "../../Components/Template/TemplateModal";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../Components/cropImage";
import { baseStyle } from "../../Helper/style/style";
import { base64ToFile } from "../../Helper/image/imageFunction";
import ResumeTemplateModal from "../../Components/ResumeTemplate/ResumeTemplateModal";
const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function EditRumahDuka() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    user,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //=========================================================authorize
  const [disable, setDisable] = useState(true);

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role !== "super" && token.role !== "admin") {
      history.push("/");
    }

    if (token.role == "super") {
      setDisable(false);
    }
  };

  const params = useParams();
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //==============================================Agent
  const [agent, setAgent] = useState(false);
  const handleChange = (event) => {
    setAgent(event.target.checked);
  };

  const handleChangeUniversal = (event) => {
    setData({ ...data, universal_template: event.target.checked });
  };

  const handleChangeShow = (event) => {
    setData({ ...data, show_rd_generate: event.target.checked });
  };

  //========================================handling input
  const initialState = {
    logo: "",
    nama_rd: "",
    alamat: "",
    tlp: "",
    fk_id_prov: "",
    fk_id_kota: "",
    harga: "",
    commission: "",
    fk_id_template: "",
    saldo: "",
    discount: "",
    universal_template: true,
  };

  const [data, setData] = useState(initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });

    if (name === "fk_id_prov") {
      GetKota(value);
    }
  };
  //========================================logo input
  const [logo, setLogo] = useState();
  const cropAvatar = (view) => {
    setLogo(view);
  };
  //========================================Dropzone Default
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    acceptedFiles,
  } = useDropzone({ accept: { "image/*": [] } });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  useEffect(() => {
    if (acceptedFiles.length !== 0) {
      handleImageDrop("default", acceptedFiles[0]);
    }
  }, [acceptedFiles]);
  //========================================Dropzone Akhir
  const {
    getRootProps: getRootPropsEnd,
    getInputProps: getInputPropsEnd,
    isFocused: isFocusedEnd,
    isDragAccept: isDragAcceptEnd,
    isDragReject: isDragRejectEnd,
    isDragActive: isDragActiveEnd,
    acceptedFiles: acceptedFilesEnd,
  } = useDropzone({ accept: { "image/*": [] } });

  const styleend = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocusedEnd ? focusedStyle : {}),
      ...(isDragAcceptEnd ? acceptStyle : {}),
      ...(isDragRejectEnd ? rejectStyle : {}),
    }),
    [isFocusedEnd, isDragAcceptEnd, isDragRejectEnd]
  );
  useEffect(() => {
    if (acceptedFilesEnd.length !== 0) {
      handleImageDrop("end", acceptedFilesEnd[0]);
    }
  }, [acceptedFilesEnd]);

  //========================================get Rumah Duka
  const getData = async (/*userID*/) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/RumahDuka/RumahDukaById?id=" +
      params.id;
    try {
      const response = await axios.get(url);
      setData(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  //=============================================templates
  const [modal, setModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const closeModal = () => {
    setModal(false);
    const path = sessionStorage.getItem("selected_template");
    if (path) {
      setSelectedTemplate(
        dataTemplate.filter((item) =>
          item.path_image_template.includes(path)
        )[0]
      );
    }
  };

  const openModal = () => {
    setModal(true);
  };
  const [dataTemplate, setDataTemplate] = useState([]);

  const getDaftarTemplate = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/template/template";
    try {
      const response = await axios.get(url);
      setDataTemplate(
        response.data.map((e) => {
          return {
            nama_template: e.nama_template,
            pk_id_template: e.pk_id_template,
            religi: e.religi,
            bahasa: e.bahasa,
            path_thumb_template: e.path_thumb_template,
            path_image_template: e.path_image_template,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil daftar template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  //=============================================resume-templates
  const [swResume, setSwResume] = useState(true);
  const handleChangeResume = (event) => {
    setSwResume(event.target.checked);
  };

  const [modalResume, setModalResume] = useState(false);
  const [selectedTemplateResume, setSelectedTemplateResume] = useState(false);
  const closeModalResume = (id) => {
    setModalResume(false);
    setData({
      ...data,
      fk_id_resume_template: id,
    });
    if (id) {
      setSelectedTemplateResume(
        dataTemplateResume.filter((item) => item.pk_id_resume_template == id)[0]
      );
    }
  };
  const openModalResume = () => {
    setModalResume(true);
  };
  const [dataTemplateResume, setDataTemplateResume] = useState([]);

  const getDaftarTemplateResume = async (fk_id_rd) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/ResumeTemplate/resume-template/" +
      fk_id_rd;
    try {
      const response = await axios.get(url);
      setDataTemplateResume(
        response.data.map((e) => {
          return {
            nama_resume_template: e.nama_resume_template,
            pk_id_resume_template: e.pk_id_resume_template,
            path_thumb_resume_template: e.path_thumb_resume_template,
            path_resume_template: e.path_resume_template,
            path_resume_template_tv: e.path_resume_template_tv,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data resume template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  //========================================get prov & Kota

  const [dataProv, setDataProv] = useState([]);

  const GetProv = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/Provinsi/Provinsi";
    try {
      const response = await axios.get(url);
      setDataProv(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data provinsi, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  const [dataKota, setDataKota] = useState([]);

  const GetKota = async (value) => {
    const url =
      process.env.REACT_APP_BASE_URL + "api/Kota/KotaByProv?id=" + value;

    try {
      const response = await axios.get(url);
      setDataKota(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data kota, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  useEffect(() => {
    getDaftarTemplate();
    getData();
    GetProv();
  }, []);
  useEffect(() => {
    if (data.pk_id_rd) {
      GetKota(data.fk_id_prov);
      getDaftarTemplateResume(data.pk_id_rd);
      setCroppedImage(process.env.REACT_APP_BASE_URL + data.path_image_def);
    }
    if (data.fk_id_agent) {
      setAgent(true);
    }
    if (data.path_image_end) {
      setCroppedImageEnd(process.env.REACT_APP_BASE_URL + data.path_image_end);
    }
  }, [data]);

  useEffect(() => {
    auth();
  }, [user]);

  useEffect(() => {
    if (data.pk_id_rd > 0 && dataTemplate.length > 0) {
      setSelectedTemplate(
        dataTemplate.filter(
          (item) => item.pk_id_template === data.fk_id_template
        )[0]
      );
    }
  }, [data, dataTemplate]);

  useEffect(() => {
    if (data.pk_id_rd > 0 && dataTemplateResume.length > 0) {
      setSelectedTemplateResume(
        dataTemplateResume.filter(
          (item) => item.pk_id_resume_template === data.fk_id_resume_template
        )[0]
      );
    }
  }, [data, dataTemplateResume]);
  //================================================================================ modal crop
  const [openCrop, setOpenCrop] = React.useState(false);
  const handleOpen = () => setOpenCrop(true);
  const handleClose = () => setOpenCrop(false);

  //================================================================================= cropping
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImageEnd, setCroppedImageEnd] = useState(null);
  const [name, setName] = useState("default");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleImageDrop = (name, acceptedFiles) => {
    const selectedImage = URL.createObjectURL(acceptedFiles);
    setImage(selectedImage);
    handleOpen();
    setName(name);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
      if (name === "default") {
        setCroppedImage(croppedImage);
      } else {
        setCroppedImageEnd(croppedImage);
      }
    } catch (e) {}
  }, [croppedAreaPixels, 0]);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const CROP_AREA_ASPECT = 16 / 9;

  //==============================================================validation

  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    const validationErrors = {};
    if (!data.nama_rd) {
      validationErrors.nama_rd = "Masukan Nama Rumah Duka";
    }
    if (!data.alamat) {
      validationErrors.alamat = "Masukan Alamat";
    }
    if (!data.tlp) {
      validationErrors.tlp = "Masukan Nomor Telepon";
    } else {
      const valid = /^[0-9]{1,13}$/.test(data.tlp);
      !valid && (validationErrors.tlp = "Telepon Tidak Valid");
    }
    if (!data.fk_id_prov) {
      validationErrors.fk_id_prov = "Masukan Provinsi";
    }
    if (!data.fk_id_kota) {
      validationErrors.fk_id_kota = "Masukan Kota";
    }
    if (!data.harga) {
      validationErrors.harga = "Masukan Harga Ucapan";
    } else {
      const valid = data.harga > 0;
      !valid && (validationErrors.harga = "Harga tidak boleh minus");
    }
    if (!data.commission) {
      validationErrors.commission = "Masukan Komisi";
    } else {
      const valid = data.commission > 0;
      !valid && (validationErrors.commission = "Komisi tidak boleh minus");
    }

    if (!selectedTemplate.pk_id_template) {
      validationErrors.fk_id_template = "Pilih template pesan";
    }
    if (agent) {
      if (!data.saldo) {
        validationErrors.saldo = "Masukan saldo";
      } else {
        const valid = data.saldo > 0;
        !valid && (validationErrors.saldo = "saldo tidak boleh minus");
      }
      if (!data.discount) {
        validationErrors.discount = "Masukan discount";
      } else {
        const valid = data.discount > 0;
        !valid && (validationErrors.discount = "Diskon tidak boleh minus");
      }
    }
    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      setToOpenKonfirm();
    }
  };
  //========================================handling submit
  useEffect(() => {
    if (dataKonfirm === true) {
      handleSubmit();
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm(null);
  }, [dataKonfirm]);
  const handleSubmit = async () => {
    const formData = new FormData();
    if (acceptedFiles[0]) {
      const response = await fetch(croppedImage);
      const blob = await response.blob();
      formData.append("default_image", blob, `${data.nama_rd.trim()}.jpg`);
    } else {
      formData.append("path_image_def", data.path_image_def);
    }
    if (acceptedFilesEnd[0]) {
      const responseEnd = await fetch(croppedImageEnd);
      const blobEnd = await responseEnd.blob();
      formData.append("end_image", blobEnd, `${data.nama_rd.trim()}end.jpg`);
    } else {
      formData.append("path_image_end", data.path_image_end);
    }

    if (logo) {
      const avatar = base64ToFile(logo);
      formData.append("avatar", avatar, `${data.nama_rd}.jpg`);
    } else {
      formData.append("path_image", data.path_image);
    }

    formData.append("pk_id_rd", data.pk_id_rd);
    formData.append("nama_rd", data.nama_rd.trim());
    formData.append("alamat", data.alamat);
    formData.append("tlp", data.tlp);
    formData.append("fk_id_prov", data.fk_id_prov);
    formData.append("fk_id_kota", data.fk_id_kota);
    formData.append("fk_id_avatar", data.fk_id_avatar);
    formData.append("fk_id_agent", data.fk_id_agent);
    formData.append("harga", data.harga);
    formData.append("commission", data.commission);
    formData.append("fk_id_template", selectedTemplate.pk_id_template);
    formData.append("universal_template", data.universal_template);
    formData.append(
      "fk_id_resume_template",
      selectedTemplateResume.pk_id_resume_template
    );
    formData.append("show_resume_tv", swResume);
    formData.append("show_rd_generate", data.show_rd_generate);
    if (agent) {
      formData.append("agent.saldo", data.saldo);
      formData.append("agent.discount", data.discount);
    }
    if (data.pesan) {
      formData.append("pesan", data.pesan);
    }
    if (data.keterangan_pengirim) {
      formData.append("keterangan_pengirim", data.keterangan_pengirim);
    }
    const url = process.env.REACT_APP_BASE_URL + "api/RumahDuka/RumahDuka";
    await axios
      .put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        history.push("/tambah_rd");
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat edit rumah duka, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" mt={4}>
            Form Edit Rumah Duka
          </Typography>

          <ErrorBox />

          <Avatar width={150} height={150} onCrop={cropAvatar} />
          <Typography>Logo Rumah Duka</Typography>

          <TextField
            fullWidth
            required
            size="small"
            id="nama_rd"
            name="nama_rd"
            value={data.nama_rd}
            onChange={handleInputChange}
            label="Masukan nama rumah duka"
            variant="outlined"
            sx={{ mt: 3 }}
            disabled={disable}
            error={!!errors.nama_rd}
            helperText={errors.nama_rd}
          />
          <TextField
            fullWidth
            required
            size="small"
            id="alamat"
            name="alamat"
            value={data.alamat}
            onChange={handleInputChange}
            label="Masukan alamat rumah duka"
            variant="outlined"
            sx={{ mt: 3 }}
            error={!!errors.alamat}
            helperText={errors.alamat}
          />

          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="outlined-controlled"
                required
                size="small"
                name="fk_id_prov"
                onChange={handleInputChange}
                label="Pilih Provinsi"
                variant="outlined"
                select
                value={dataProv ? data.fk_id_prov : 0}
                error={!!errors.fk_id_prov}
                helperText={errors.fk_id_prov}
              >
                <MenuItem value={0}>Pilih Provinsi...</MenuItem>
                {dataProv.map((option) => (
                  <MenuItem key={option.pk_id_prov} value={option.pk_id_prov}>
                    {option.nama_prov}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                size="small"
                id="outlined-controlled"
                name="fk_id_kota"
                onChange={handleInputChange}
                label="Pilih Kota"
                variant="outlined"
                select
                value={dataKota.length > 0 ? data.fk_id_kota : 0}
                error={!!errors.fk_id_kota}
                helperText={errors.fk_id_kota}
              >
                <MenuItem value={0}>Pilih Kota...</MenuItem>
                {dataKota?.map((option) => (
                  <MenuItem key={option.pk_id_kota} value={option.pk_id_kota}>
                    {option.nama_kota}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <TextField
            fullWidth
            required
            size="small"
            id="tlp"
            name="tlp"
            onChange={handleInputChange}
            label="Masukan telepon rumah duka"
            variant="outlined"
            value={data.tlp}
            sx={{ mt: 3 }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            error={!!errors.tlp}
            helperText={errors.tlp}
          />

          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                required
                size="small"
                id="harga"
                name="harga"
                onChange={handleInputChange}
                label="Masukan harga ucapan"
                variant="outlined"
                value={data.harga || ""}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                disabled={disable}
                error={!!errors.harga}
                helperText={errors.harga}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                required
                size="small"
                id="commission"
                name="commission"
                onChange={handleInputChange}
                label="Masukan komisi Rumah Duka"
                variant="outlined"
                value={data.commission || ""}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                disabled={disable}
                error={!!errors.commission}
                helperText={errors.commission}
              />
            </Grid>
          </Grid>
          <Typography variant="h5" mt={4}>
            Template Default
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <Typography>Gunakan template untuk semua ruangan</Typography>
            <Switch
              checked={data.universal_template || false}
              onChange={handleChangeUniversal}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                size="small"
                id="keterangan_pengirim"
                name="keterangan_pengirim"
                onChange={handleInputChange}
                label="Masukan keterangan pengirim pesan"
                variant="outlined"
                value={data.keterangan_pengirim || " "}
                error={!!errors.keterangan_pengirim}
                helperText={errors.keterangan_pengirim}
                inputProps={{
                  maxLength: 30, // Set the maximum character limit
                }}
              />

              <TextField
                fullWidth
                required
                multiline
                size="small"
                id="pesan"
                name="pesan"
                onChange={handleInputChange}
                label="Masukan pesan"
                variant="outlined"
                sx={{ mt: 3 }}
                value={data.pesan || " "}
                error={!!errors.pesan}
                helperText={errors.pesan}
                inputProps={{
                  maxLength: 100, // Set the maximum character limit
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                <Typography>
                  Gunakan tambahan kata "Rumah Duka" untuk generate pesan
                </Typography>
                <Switch
                  checked={data.show_rd_generate || false}
                  onChange={handleChangeShow}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {selectedTemplate ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_BASE_URL +
                      selectedTemplate.path_thumb_template
                    }
                    alt={selectedTemplate.nama_template}
                    style={{ width: "300px" }}
                  />
                  <Typography>{selectedTemplate.nama_template}</Typography>
                  <Button
                    onClick={() => {
                      setSelectedTemplate(null);
                      openModal();
                    }}
                    variant="contained"
                    size="small"
                    sx={{
                      color: "white",
                      borderRadius: "999px",
                      textTransform: "none",
                    }}
                  >
                    Ubah
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      openModal();
                    }}
                    variant="contained"
                    size="small"
                    sx={{
                      color: "white",
                      borderRadius: "999px",
                      textTransform: "none",
                    }}
                  >
                    Pilih Template
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
          <FormHelperText error>{errors.fk_id_template}</FormHelperText>
          <Typography mt={8} mb={2}>
            Template Twibbon Default
          </Typography>
          {selectedTemplateResume ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  selectedTemplateResume.path_thumb_resume_template
                }
                alt={selectedTemplateResume.nama_resume_template}
                style={{ width: "400px" }}
              />
              <Typography>
                {selectedTemplateResume.nama_resume_template}
              </Typography>
              <Button
                onClick={() => {
                  setSelectedTemplateResume(null);
                  openModalResume();
                }}
                variant="contained"
                size="small"
                sx={{
                  color: "white",
                  borderRadius: "999px",
                  textTransform: "none",
                }}
              >
                Ubah
              </Button>
            </Box>
          ) : (
            <Button
              onClick={() => {
                openModalResume();
              }}
              variant="contained"
              size="small"
              sx={{
                color: "white",
                borderRadius: "999px",
                textTransform: "none",
              }}
            >
              Pilih Twibbon Template
            </Button>
          )}
          <FormHelperText error>{errors.fk_id_resume_template}</FormHelperText>
          <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
            <Typography>Tampilkan Twibbon di TV </Typography>
            <Switch
              checked={swResume}
              onChange={handleChangeResume}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Typography variant="h5" mt={4}>
            Pesan Tambahan
          </Typography>
          <Grid mt={1} container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography my={2}>Ucapan Default</Typography>

              {croppedImage ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img width={"80%"} src={croppedImage} alt="uploaded file" />
                </Box>
              ) : (
                <Box sx={{}} />
              )}
              <Box mt={2} {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragAccept && <p>All files will be accepted</p>}
                {isDragReject && <p>Some files will be rejected</p>}
                {isDragActive
                  ? "Drop the files here ..."
                  : "Drag 'n' drop some files here, or click to select files"}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography my={2}>Ucapan Akhir</Typography>
              {croppedImageEnd ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    width={"80%"}
                    src={croppedImageEnd}
                    alt="uploaded file"
                  />
                </Box>
              ) : (
                <Box sx={{}} />
              )}
              <Box
                sx={{
                  boxSizing: "border-box",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  borderWidth: 2,
                  borderRadius: 2,
                  borderColor: "#eeeeee",
                  borderStyle: "dashed",
                  backgroundColor: "#fafafa",
                  color: "#bdbdbd",
                  outline: "none",
                  transition: "border .24s ease-in-out",
                  width: "100%",
                  mt: 2,
                }}
                {...getRootPropsEnd({ styleend })}
              >
                <input {...getInputPropsEnd()} />
                {isDragAcceptEnd && <p>All files will be accepted</p>}
                {isDragRejectEnd && <p>Some files will be rejected</p>}
                {isDragActiveEnd
                  ? "Drop the files here ..."
                  : "Drag 'n' drop some files here, or click to select files"}
              </Box>
            </Grid>
          </Grid>
          {!disable && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                <Typography>Agent</Typography>
                <Switch
                  checked={agent}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
              {agent ? (
                <Box sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    id="saldo"
                    name="saldo"
                    onChange={handleInputChange}
                    label="Saldo Agent"
                    variant="outlined"
                    value={data.saldo}
                    sx={{ my: 3 }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    error={!!errors.saldo}
                    helperText={errors.saldo}
                  />
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!errors.discount}
                  >
                    <InputLabel size="small" error={!!errors.discount}>
                      Discount Agent
                    </InputLabel>
                    <OutlinedInput
                      type="number"
                      size="small"
                      id="discount"
                      name="discount"
                      label="Discount Agent"
                      onChange={handleInputChange}
                      variant="outlined"
                      value={data.discount}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                    <FormHelperText error>{errors.discount}</FormHelperText>
                  </FormControl>
                </Box>
              ) : (
                <Box />
              )}
            </>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              mt: 5,
            }}
          >
            <LoadingButton
              endIcon={<LocationCity />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                handleLoading();
                checkValidate();
              }}
              variant="contained"
              sx={{
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                mt: 3,
              }}
            >
              Simpan
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCrop}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openCrop}>
          <Box sx={style}>
            {image ? (
              <Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: "100px",
                  }}
                >
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={CROP_AREA_ASPECT}
                    onCropChange={onCropChange}
                    onZoomChange={onZoomChange}
                    onCropComplete={onCropComplete}
                    disableAutomaticStylesInjection={true}
                  />
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                    bottom: "0",
                    left: "50%",
                    width: "100%",
                    transform: "translateX(-50%)",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                  }}
                >
                  <Box
                    sx={{
                      width: "80%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Slider
                      maxWidth="30%"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e, zoom) => onZoomChange(zoom)}
                    />
                    <Box
                      sx={{
                        ml: 4,
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          showCroppedImage();
                          handleClose();
                        }}
                        sx={{
                          mr: 1,
                          whiteSpace: "nowrap",
                          color: "white",
                          borderRadius: "100px",
                        }}
                      >
                        Crop & Resize
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          handleClose();
                        }}
                        sx={{
                          whiteSpace: "nowrap",
                          color: "white",
                          borderRadius: "100px",
                        }}
                      >
                        Batal
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box />
            )}
          </Box>
        </Fade>
      </Modal>
      <TemplateModal data={dataTemplate} setClose={closeModal} modal={modal} />
      <ResumeTemplateModal
        data={dataTemplateResume}
        setClose={closeModalResume}
        modal={modalResume}
      />
    </Layout>
  );
}

export default EditRumahDuka;
