import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import jwt from "jwt-decode";
import TemplateModal from "../../Components/Template/TemplateModal";
function EditRuangan() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
    DataRuanganTemplate,
    wipeToRuanganTemplate
  } = masukContext;
  //=========================================================authorize

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role != "super" && token.role != "admin") {
      history.push("/");
    }
  };
  //========================================handling input
  const initialState = {
    nama_ruangan: "",
    lantai: "",
  };
  const [data, setData] = useState(initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  //=========================================  Get Data
  //get from params
  const params = useParams();

  const getDaftarRD = async (/*userID*/) => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/Ruangan/RuanganById?id=" +
      params.id;
    try {
      const response = await axios.get(url);
      setData(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data ruangan, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  //=============================================templates
  const [modal, setModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const closeModal = () => {
    setModal(false);
    const path = sessionStorage.getItem("selected_template");
    if (path) {
      setSelectedTemplate(
        dataTemplate.filter((item) =>
          item.path_image_template === path
        )[0]
      );
    }
  };
  const openModal = () => {
    setModal(true);
    sessionStorage.removeItem("selected_template");
  };
  const [dataTemplate, setDataTemplate] = useState([]);

  const getDaftarTemplate = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/template/template";
    try {
      const response = await axios.get(url);
      setDataTemplate(
        response.data.map((e) => {
          return {
            nama_template: e.nama_template,
            pk_id_template: e.pk_id_template,
            deskripsi: e.deskripsi,
            path_thumb_template: e.path_thumb_template,
            path_image_template: e.path_image_template,
            bahasa: e.bahasa,
            religi: e.religi,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  useEffect(() => {
    getDaftarRD();
    auth();
    getDaftarTemplate();
  }, []);

  useEffect(() => {
    if (data.pk_id_ruangan > 0 && dataTemplate.length > 0) {
      setSelectedTemplate(
        dataTemplate.filter(
          (item) => item.pk_id_template === data.fk_id_template
        )[0]
      );
    }
  }, [data, dataTemplate]);

  useEffect(() => {
    if (selectedTemplate) {
      setData({
        ...data,
        fk_id_template: selectedTemplate.pk_id_template,
      });
    }
  }, [selectedTemplate]);
  //==============================================================validation

  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {

    const validationErrors = {};
    if (!data.nama_ruangan) {
      validationErrors.nama_ruangan = "Masukan Nama Ruangan";
    }
    if (!data.lantai) {
      validationErrors.lantai = "Masukan Lantai";
    }
    if (!selectedTemplate.pk_id_template && !DataRuanganTemplate.universal_template) {
      validationErrors.fk_id_template = "Pilih template pesan";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setToOpenKonfirm();
      if(!selectedTemplate){
        setData({
          ...data,
          fk_id_template: DataRuanganTemplate.fk_id_template,
        });
      }
    }
  };
  //========================================handling submit
  useEffect(() => {
    if (dataKonfirm === true) {
      if (data) {
        handleSubmit();
      }
    } else if (dataKonfirm === false) {
    }
    setDataKonfirm();
  }, [dataKonfirm]);

  const handleSubmit = async () => {
    
   
    const url = process.env.REACT_APP_BASE_URL + "api/Ruangan/Ruangan";
    await axios
      .put(url, data, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        history.push(`/view_rd/${data.fk_id_rd}`);
        wipeToRuanganTemplate()
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat edit, Mohon coba lagi");
        }
        openErrorBox();
      });
  };

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" mt={4}>
            Edit Ruangan Rumah Duka
          </Typography>

          <ErrorBox />

          <TextField
            fullWidth
            required
            size="small"
            id="nama_ruangan"
            name="nama_ruangan"
            onChange={handleInputChange}
            label="Masukan nama ruangan rumah duka"
            variant="outlined"
            value={data.nama_ruangan}
            sx={{ mt: 3 }}
            error={!!errors.nama_ruangan}
            helperText={errors.nama_ruangan}
          />
          <TextField
            fullWidth
            required
            type="number"
            size="small"
            id="lantai"
            name="lantai"
            onChange={handleInputChange}
            label="Masukan lantai ruangan rumah duka"
            variant="outlined"
            value={data.lantai}
            sx={{ mt: 3 }}
            error={!!errors.lantai}
            helperText={errors.lantai}
          />
          {!DataRuanganTemplate.universal_template&&(
          <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}>
            <Typography my={2}>Template Default</Typography>
            {selectedTemplate ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={
                    process.env.REACT_APP_BASE_URL +
                    selectedTemplate.path_thumb_template
                  }
                  alt={selectedTemplate.nama_template}
                  style={{ width: "300px" }}
                />
                <Typography>{selectedTemplate.nama_template}</Typography>
                <Button
                  onClick={() => {
                    setSelectedTemplate(null);
                    openModal();
                  }}
                  variant="contained"
                  size="small"
                  sx={{
                    color: "white",
                    borderRadius: "999px",
                    textTransform: "none",
                  }}
                >
                  Ubah
                </Button>
              </Box>
            ) : (
              <Button
                onClick={() => {
                  openModal();
                }}
                variant="contained"
                size="small"
                sx={{
                  color: "white",
                  borderRadius: "999px",
                  textTransform: "none",
                }}
              >
                Pilih Template
              </Button>
            )}
          </Box>
          )}
          <FormHelperText error>{errors.fk_id_template}</FormHelperText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              mt: 5,
            }}
          >
            <Button
              variant="contained"
              sx={{ borderRadius: "100px", color: "white" }}
              onClick={() => {
                checkValidate();
              }}
            >
              Simpan
            </Button>
          </Box>
          <TemplateModal
            data={dataTemplate}
            setClose={closeModal}
            modal={modal}
          />
        </Box>
      </Box>
    </Layout>
  );
}

export default EditRuangan;
