import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  TableBody,
  Button,
  Typography,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Link as DirectLink } from "react-router-dom";
import axios from "axios";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import "../../Helper/format/currency";
import { AddCircle, Search } from "@mui/icons-material";
import TopUp from "../../Components/Agent/TopUp";
import jwt from "jwt-decode";
import ErrorBox from "../../Components/ErrorBox";
import { StyledTableCell, StyledTableRow } from "../../Helper/style/style";

function ViewRD() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    user,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
    setToRuanganTemplate
  } = masukContext;
  //=========================================================authorize
  const history = useHistory();
  const auth = () => {
    if (loginToken) {
      const token = jwt(loginToken);
      if (token.role !== "super" && token.role !== "admin") {
        history.push("/");
      }
    } else {
      history.push("/");
    }
  };

  const params = useParams();

  //===================================Get Data
  const [data, setData] = useState([]);
  const initialStateRD = {
    nama_rd: "",
    alamat: "",
    nama_kota: "",
    nama_prov: "",
    saldo: "",
    fk_id_agent: 0,
    guid_rd: "-",
  };
  const [dataRD, setDataRD] = useState(initialStateRD);
  const getData = async (/*userID*/) => {
    const url =
      process.env.REACT_APP_BASE_URL + "api/Ruangan/RuanganByIdRD/" + params.id;
    const url2 =
      process.env.REACT_APP_BASE_URL +
      "api/RumahDuka/RumahDukaById?id=" +
      params.id;
    try {
      const response = await axios.get(url);
      const response2 = await axios.get(url2);

      setData(
        response.data.map((e) => {
          return {
            fk_id_rd: e.fk_id_rd,
            pk_id_ruangan: e.pk_id_ruangan,
            nama_ruangan: e.nama_ruangan,
            lantai: e.lantai,
            nama_template: e.nama_template,
          };
        })
      );
      setSearchData(
        response.data.map((e) => {
          return {
            fk_id_rd: e.fk_id_rd,
            pk_id_ruangan: e.pk_id_ruangan,
            nama_ruangan: e.nama_ruangan,
            lantai: e.lantai,
            nama_template: e.nama_template,
          };
        })
      );
      getDaftarTemplate(response2.data.fk_id_template);
      setDataRD(response2.data);
      setToRuanganTemplate({
        pk_id_rd: response2.data.pk_id_rd,
        fk_id_template: response2.data.fk_id_template,
        universal_template: response2.data.universal_template,
      })
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  //==================================================template
  const [selectedTemplate, setDataTemplate] = useState(false);
  const getDaftarTemplate = async (id) => {
    const url =
      process.env.REACT_APP_BASE_URL + "api/template/TemplateById?id=" + id;
    try {
      const response = await axios.get(url);
      setDataTemplate(response.data);
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  //==================================================delete ruangan
  const [id, setId] = useState();
  useEffect(() => {
    if (dataKonfirm === true) {
      if (id > 0) {
        handleDelete(id);
      }
    } else if (dataKonfirm === false) {
    }
    setId();
    setDataKonfirm();
  }, [dataKonfirm]);

  const handleDelete = async (id) => {
    const url = process.env.REACT_APP_BASE_URL + "api/Ruangan/Ruangan?id=" + id;
    await axios
      .delete(url, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        setSearchData(data.filter((item) => item.pk_id_ruangan != id));
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer(
            "Terjadi error saat mengambil data provinsi, Mohon coba lagi"
          );
        }
        openErrorBox();
      });
  };
  // ===================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");
  function searchRuangan(query) {
    query = query.toLowerCase(); // Convert the query to lowercase for case-insensitive search

    return data.filter((ruangan) => {
      const nama_ruangan = ruangan.nama_ruangan.toLowerCase().includes(query);
      return nama_ruangan;
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setInputSearchData(value)
    setInputSearchData({
      ...inputSearchData,
      [name]: value,
    });
    setSearchData(searchRuangan(value));
  };

  //================================================topUp

  const [openTopUp, setOpenTopUp] = useState(false);

  const setToOpenTopUp = () => {
    setOpenTopUp(true);
  };

  const setToCloseTopUp = (berhasil) => {
    setOpenTopUp(false);

    if (berhasil) {
      getData();
    }
  };
  const initialStateTopUp = {
    pk_id_agent: 0,
    saldo: 0,
  };
  const [dataTopUp, setdataTopUp] = useState(initialStateTopUp);

  useEffect(() => {
    getData(/*usrid*/);
    auth();
  }, []);
  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              mt: 5,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    borderRadius: 100,
                    overflow: "hidden",
                  }}
                >
                  {dataRD.path_image && (
                    <img
                      src={process.env.REACT_APP_BASE_URL + dataRD.path_image}
                      width={100}
                      height={100}
                      alt={dataRD.nama_rd}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box>
                  <Typography variant="h4">{dataRD.nama_rd}</Typography>
                  <Typography mb={3}>
                    {dataRD.alamat}, {dataRD.nama_kota}, {dataRD.nama_prov}
                  </Typography>
                  <Typography mb={3}>
                    TV Token : {dataRD.guid_rd.toLowerCase()}
                  </Typography>
                  {dataRD.fk_id_agent ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="h5">
                        Saldo Agent : {dataRD.saldo.currency()}
                      </Typography>
                      {user.role === "super" && (
                        <Box
                          sx={{ ml: 1, cursor: "pointer" }}
                          onClick={() => {
                            setToOpenTopUp();
                            setdataTopUp({
                              loginToken: loginToken,
                              saldo: dataRD.saldo,
                              pk_id_agent: dataRD.fk_id_agent,
                            });
                          }}
                        >
                          <AddCircle color="primary" />
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box />
                  )}
                  {user.fk_id_rd > 0 && (
                    <Button
                      component={DirectLink}
                      to={`/edit_rd/${user.fk_id_rd}`}
                      variant="contained"
                      sx={{ borderRadius: "100px", color: "white", mt: 3 }}
                    >
                      Edit Rumah Duka
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 3,
              }}
            >
              <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="h6" mt={2}>
                      Pesan Default
                    </Typography>
                    {dataRD.path_image_def && (
                      <img
                        style={{ maxWidth: "100%" }}
                        src={
                          process.env.REACT_APP_BASE_URL + dataRD.path_image_def
                        }
                        alt="uploaded file"
                      />
                    )}
                  </Box>
                </Grid>

                {dataRD.path_image_end && (
                  <Grid item sm={4} xs={12}>
                    <Box sx={{ width: "100%" }}>
                      <Typography variant="h6" mt={2}>
                        Pesan Akhir
                      </Typography>
                      <img
                        style={{ maxWidth: "100%" }}
                        src={
                          process.env.REACT_APP_BASE_URL + dataRD.path_image_end
                        }
                        alt="uploaded file"
                      />
                    </Box>
                  </Grid>
                )}

                {dataRD.universal_template && (
                  <Grid item sm={4} xs={12}>
                    <Box sx={{ width: "100%" }}>
                      <Typography variant="h6" mt={2}>
                        Template
                      </Typography>
                      {selectedTemplate && (
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL +
                            dataRD.path_thumb_template
                          }
                          alt={selectedTemplate.nama_template}
                          style={{ maxWidth: "100%" }}
                        />
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>

            <Typography mt={4} variant="h6">
              Template Twibbon Mendiang            
            </Typography>
            <Box sx={{my:3}}>
            <img
              src={
                process.env.REACT_APP_BASE_URL +
                dataRD.path_thumb_resume_template
              }
              alt={selectedTemplate.nama_template}
              style={{ maxWidth: "400px" }}
            />
            </Box>

            {dataRD.show_resume_tv?<Typography>Twibbon akan ditampilkan di TV</Typography>:<Typography>Twibbon tidak ditampilkan di TV</Typography>}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mt: 5,
              }}
            >
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="h5" mr={2}>
                    Daftar Ruangan
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    placeholder="Search…"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    name="searchText"
                    onChange={handleInputChange}
                    value={inputSearchData.searchText}
                  />
                </Grid>
              </Grid>
              <Box>
                <Button
                  component={DirectLink}
                  to={`/tambah_ruangan/${dataRD.pk_id_rd}`}
                  variant="contained"
                  sx={{ borderRadius: "100px", color: "white" }}
                >
                  Tambah
                </Button>
              </Box>
            </Box>
          </Box>
          <TableContainer sx={{ my: 5 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>No</StyledTableCell>
                  <StyledTableCell align="center">Nama Ruangan</StyledTableCell>
                  <StyledTableCell align="center">Lantai</StyledTableCell>
                  {!dataRD.universal_template && (
                    <StyledTableCell align="center">Template</StyledTableCell>
                  )}
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <StyledTableRow key={row.pk_id_ruangan}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.nama_ruangan}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.lantai}
                    </StyledTableCell>
                    {!dataRD.universal_template && (
                      <StyledTableCell align="center">
                        {row.nama_template}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center">
                      <Button
                        color="success"
                        variant="contained"
                        size="small"
                        sx={{ mr: 1, color: "white" }}
                        component={DirectLink}
                        to={`/edit_ruangan/${row.pk_id_ruangan}`}
                      >
                        Edit
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setId(row.pk_id_ruangan);
                          setToOpenKonfirm();
                        }}
                        sx={{ color: "white" }}
                      >
                        Delete
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <TopUp
        openModal={openTopUp}
        closeModal={setToCloseTopUp}
        data={dataTopUp}
      />
    </Layout>
  );
}

export default ViewRD;