import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Typography,
  Modal,
  Fade,
  TextField,
  Grid,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import "../../Helper/format/currency";
import { Search } from "@mui/icons-material";
import "../../Helper/format/currency";
import ErrorBox from "../../Components/ErrorBox";
import {
  styleBoxModal,
  StyledTableCell,
  StyledTableRow,
} from "../../Helper/style/style";
import DokuPayment from "../../Components/Modal/DokuPayment";

function DaftarKeranjang() {
  //==================================================Context
  const masukContext = useGlobal();
  const { loginToken, user, openErrorBox, setErrorServer } = masukContext;
  //=========================================================authorize
  const history = useHistory();

  const auth = () => {
    if (!loginToken) {
      history.push("/");
    }
  };
  //==============================================================Get Data
  const [data, setData] = useState([]);
  const getDaftarPesan = async (/*userID*/) => {
    if (user.pk_id_user) {
      let url =
        process.env.REACT_APP_BASE_URL +
        `api/keranjang/keranjang/user/${user.pk_id_user}`;

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `bearer ${loginToken}`,
          },
        });
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat mengirim pesan, Mohon  coba lagi");
        }
        openErrorBox();
      }
    }
  };

  useEffect(() => {
    getDaftarPesan(/*usrid*/);
    auth();
  }, [user]);
  //================================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");
  function searchFunction(query) {
    query = query.toString().toLowerCase(); // Convert the query to lowercase for case-insensitive search

    return data.filter((searchText) => {
      const filteredText = searchText.nama_mendiang
        .toLowerCase()
        .includes(query);
      const filteredText2 = searchText.pk_id_keranjang
        .toString()
        .toLowerCase()
        .includes(query);
      return filteredText || filteredText2;
    });
  }

  const handleInputChangeSearch = (e) => {
    const { name, value } = e.target;
    // setInputSearchData(value)
    setInputSearchData({
      ...inputSearchData,
      [name]: value,
    });
    setSearchData(searchFunction(value));
  };
  useEffect(() => {
    setSearchData(data);
  }, [data]);
  //=================================================confirm wa
  const SendWA = async (nama_mendiang, invoice_guid) => {
    const phoneNumber = "628126613388";
    const message = `Hallo, Aku memesan untuk pesan karangan yang ditujukan untuk ${nama_mendiang}, dengan invoice id ${invoice_guid}, Berikut lampiran foto bukti transfernya`; // Replace with your desired message

    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  //sending email after confirmation
  const SendEmail = async (row) => {
    try {
      const url =
        process.env.REACT_APP_BASE_URL + `api/keranjang/confirm/payment`;
      await axios.post(url, row, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      });
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer("Terjadi error saat mengirim pesan, Mohon  coba lagi");
      }
      openErrorBox();
    }
  };
  //=========================================================copy
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    const textField = document.createElement("textarea");
    textField.innerText = 7612270123;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    document.body.removeChild(textField);
    setCopied(true);
  };
  //================================================================view modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showImg, setShowImg] = useState();
  //===============================================Doku Payment
  const [dokuPaymentUrl, setDokuPaymentUrl] = useState("");
  const [openPayment, setOpenPayment] = useState(false);
  const ClosePayment = () => {
    setOpenPayment(false);
  };
  //====================================================countdown
  const sliceArray = (id) => {
    const newData = data.filter((item) => item.pk_id_keranjang !== id);

    setData(newData);
  };

  const [countdownData, setCountdownData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const intervalId = setInterval(() => {
        const currentDate = new Date().getTime();
        const updatedCountdownData = data.map((event) => {
          const timeRemaining = new Date(event.exp_time) - currentDate;
          const hours = Math.floor(
            (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

          if (hours < 0) {
            sliceArray(event.pk_id_keranjang);
            return;
          }
          const time = hours + ":" + minutes + ":" + seconds.toString();
          return time;
        });
        if (updatedCountdownData) {
          setCountdownData(updatedCountdownData);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [data]);
  return (
    <Layout>
      <Sidebar />
      {dokuPaymentUrl && (
        <DokuPayment
          open={openPayment}
          closeModal={ClosePayment}
          paymentUrl={dokuPaymentUrl}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 5,
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" mr={2}>
                  Daftar Keranjang
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  placeholder="Search…"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  name="searchText"
                  onChange={handleInputChangeSearch}
                  value={inputSearchData.searchText}
                />
              </Grid>
            </Grid>
          </Box>
          {/* <Box sx={{ my: 2 }}>
            <Typography textAlign="center">
              Pembayaran dilakukan dengan transfer ke rekening BCA dengan nomor
              :
            </Typography>
            <Typography
              onClick={() => {
                handleCopyClick();
              }}
              textAlign="center"
              sx={{ cursor: "pointer" }}
            >
              7612270123 -An PT. BINER INFO TEKNOLOGI &#40; copy to clipboard
              &#41;
            </Typography> 
          </Box>
            */}
          <TableContainer sx={{ mt: 6 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">ID </StyledTableCell>
                  <StyledTableCell align="center">
                    Nama Pengirim
                  </StyledTableCell>
                  <StyledTableCell align="center">Kepada</StyledTableCell>
                  <StyledTableCell align="center">Harga</StyledTableCell>
                  <StyledTableCell align="center">Exp</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchData.map((row, index) => (
                  <StyledTableRow key={row.pk_id_keranjang}>
                    <StyledTableCell component="th" scope="row">
                      {row.pk_id_keranjang}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.username_pengirim}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.nama_mendiang}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.harga.currency()}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {countdownData[index]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        color="ok"
                        variant="contained"
                        size="small"
                        sx={{ m: 1, color: "white" }}
                        onClick={() => {
                          setShowImg(row.image_pesan_path);
                          handleOpen();
                        }}
                      >
                        Lihat Ucapan
                      </Button>
                      {row.payment_url ? (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ m: 1, color: "white" }}
                          onClick={() => {
                            setOpenPayment(true);
                            setDokuPaymentUrl(row.payment_url);
                          }}
                        >
                          Bayar
                        </Button>
                      ) : (
                        <Button
                          color="success"
                          variant="contained"
                          size="small"
                          sx={{ m: 1, color: "white" }}
                          onClick={() => {
                            SendWA(row.nama_mendiang, row.pk_id_keranjang);
                            SendEmail(row);
                          }}
                        >
                          Konfirmasi Pembayaran
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleBoxModal}>
            <img
              src={process.env.REACT_APP_BASE_URL + showImg}
              style={{ width: "100%" }}
              alt=""
            />
          </Box>
        </Fade>
      </Modal>
      <Snackbar
        open={copied}
        autoHideDuration={3000}
        onClose={() => {
          setCopied(false);
        }}
        message="Copied to clipboard"
      />
    </Layout>
  );
}

export default DaftarKeranjang;
