import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

function MainCarousel(props) {
  const [Height, setHeight] = useState(0);
  useEffect(() => {
    const calculate_contaner = () => {
      const containerWidth = window.innerWidth;
      const calculatedHeight = (((containerWidth * 80) / 100) * 9) / 16 + 40;
      setHeight(calculatedHeight);
    };
    calculate_contaner();
    // Attach the event listener for window resize
    window.addEventListener("resize", calculate_contaner);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", calculate_contaner);
    };
  }, []);

  return (
    <Carousel
      animation="slide"
      duration="300"
      swipe={true}
      sx={{ height: `${Height}px` }}
    >
      {props.slides.map((item) => (
        <Paper key={item.pk_id_pesan}>
          <img
            src={process.env.REACT_APP_BASE_URL + item.image_pesan_path}
            alt=""
            style={{ width: "100%" }}
          />
        </Paper>
      ))}
    </Carousel>
  );
}

export default MainCarousel;
