import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { useState } from "react";
import ErrorBox from "../../Components/ErrorBox";
import axios from "axios";
import {
  Close,
  LockReset,
  LoginOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { styleBoxModal } from "../../Helper/style/style";
function Login() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    openMasuk,
    setToCloseMasuk,
    setToOpenRegister,
    setToken,
    setToOpenResponse,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //===================================================go to register
  const toRegist = () => {
    setToOpenRegister();
    setToCloseMasuk();
  };

  //===========================================lupa password
  const [lupaPass, setLupaPass] = useState(false);
  const openLupaPass = () => {
    setLupaPass(true);
  };
  const closeLupaPass = () => {
    setLupaPass(false);
  };
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //========================================handling input

  const initialState = {
    email: "",
    password: "",
  };
  const [data, setData] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  //==============================================================validation
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    const validationErrors = {};

    if (!data.email) {
      validationErrors.email = "Masukan Email";
    } else {
      const vallid =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          data.email
        );
      !vallid && (validationErrors.email = "Email Tidak Valid");
    }
    if (!data.password) {
      validationErrors.password = "Masukan Password";
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      handleSubmit();
    }
  };
  //===========================================validation reset
  const checkValidateReset = () => {
    const validationErrors = {};

    if (!data.email) {
      validationErrors.email = "Masukan Email";
    } else {
      const vallid =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          data.email
        );
      !vallid && (validationErrors.email = "Email Tidak Valid");
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      handleReset();
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Call login function when Enter key is pressed
      handleLoading();
      checkValidate();
    }
  };

  //=======================================handling submit
  const handleSubmit = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/user/login";
    await axios
      .post(url, data)
      .then((response) => {
        sessionStorage.setItem("token", response.data.token);
        setToCloseMasuk();
        setToken(response.data.token);
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat login, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };
  //=======================================handling reset
  const handleReset = async () => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/user/ResetPassword?email=" +
      data.email;
    await axios
      .get(url)
      .then(() => {
        setToCloseMasuk();
        setToOpenResponse({
          pathImage: "EmailSend.png",
          tittle: "Request Berhasil",
          massage: "Silahkan cek email anda untuk reset password.",
          nameButton: "Tutup",
        });
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat kirim email, Mohon coba lagi");
        }
        openErrorBox();
      });
    handleCloseLoading();
  };
  return (
    <Modal
      open={openMasuk}
      onClose={() => {
        setToCloseMasuk();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleBoxModal}>
        <Box
          sx={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "white",
            position: "absolute",
            right: "-10px",
            top: "-10px",
            border: "solid",
            borderRadius: "50px",
            borderColor: "primary.main",
          }}
        >
          <Typography
            sx={{ color: "primary.main" }}
            onClick={() => {
              setToCloseMasuk();
            }}
          >
            <Close />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ErrorBox sx={{ mb: "3" }} />

          {lupaPass ? (
            <Box>
              <Typography variant="h4">Lupa Password</Typography>
              <TextField
                fullWidth
                size="small"
                id="email"
                name="email"
                value={data.email}
                onChange={handleInputChange}
                label="Masukan Email"
                variant="outlined"
                sx={{ mt: 3 }}
                error={!!errors.email}
                helperText={errors.email}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    closeLupaPass();
                  }}
                  underline="none"
                >
                  Login?
                </Typography>
                <LoadingButton
                  endIcon={<LockReset />}
                  // disabled={disabled}
                  loading={loadingSts}
                  loadingPosition="end"
                  onClick={() => {
                    handleLoading();
                    checkValidateReset();
                  }}
                  variant="contained"
                  sx={{
                    borderRadius: "999px",
                    textTransform: "none",
                    fontWeight: "400",
                    marginRight: "20px",
                    color: "white",
                    mt: 3,
                  }}
                >
                  Request Reset
                </LoadingButton>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography variant="h4">Login</Typography>
              <TextField
                fullWidth
                onKeyDown={handleKeyPress}
                size="small"
                id="email"
                name="email"
                value={data.email}
                onChange={handleInputChange}
                label="Masukan Email"
                variant="outlined"
                sx={{ mt: 3 }}
                error={!!errors.email}
                helperText={errors.email}
              />
              <FormControl
                sx={{ mt: 3 }}
                fullWidth
                variant="outlined"
                error={!!errors.password}
              >
                <InputLabel error={!!errors.password} size="small">
                  Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  onKeyDown={handleKeyPress}
                  required
                  size="small"
                  id="password"
                  name="password"
                  value={data.password}
                  onChange={handleInputChange}
                  label="Password"
                  variant="outlined"
                  error={!!errors.password}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        //onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error>{errors.password}</FormHelperText>
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    color="primary"
                    onClick={() => {
                      openLupaPass();
                    }}
                    sx={{ cursor: "pointer", mt: 2 }}
                    underline="none"
                  >
                    Lupa Password?
                  </Typography>
                  <Typography
                    color="primary"
                    onClick={() => {
                      toRegist();
                    }}
                    sx={{ cursor: "pointer", mt: 2 }}
                    underline="none"
                  >
                    Buat Akun.
                  </Typography>
                </Box>
                <LoadingButton
                  endIcon={<LoginOutlined />}
                  // disabled={disabled}
                  loading={loadingSts}
                  loadingPosition="end"
                  onClick={() => {
                    handleLoading();
                    checkValidate();
                  }}
                  variant="contained"
                  sx={{
                    borderRadius: "999px",
                    textTransform: "none",
                    fontWeight: "400",
                    marginRight: "20px",
                    color: "white",
                    mt: 3,
                  }}
                >
                  Login
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default Login;
