import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Box, Button, Grid, Typography } from "@mui/material";
import Banner from "../Assets/Homepage_banner.webp";
import MarqueeCard from "../Components/Homepage/MarqueeCard";
import Marquee from "react-fast-marquee";
import { Search } from "@mui/icons-material";
import Example from "../Assets/ex_card.webp";
import Sidebar from "../Components/Sidebar";
import axios from "axios";
import { Link as DirectLink } from "react-router-dom";
import "../App.css";
import { HelmetProvider, Helmet } from "react-helmet-async";
function Homepage() {
  //=========================================================getdata

  const [data, setData] = useState([]);

  const getDaftarMendiang = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/mendiang/mendiang/date";
    try {
      const response = await axios.get(url);
      setData(
        response.data.map((e) => {
          return {
            nama_mendiang: e.nama_mendiang,
            pk_id_mendiang: e.pk_id_mendiang,
            tanggal_lahir: e.tanggal_lahir,
            tanggal_wafat: e.tanggal_wafat,
            path_image: e.path_image,
            tanggal_masuk: e.tanggal_masuk,
            tanggal_keluar: e.tanggal_keluar,
            nama_rd: e.nama_rd,
            nama_ruangan: e.nama_ruangan,
          };
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    getDaftarMendiang();
  }, []);

  return (
    <Layout>
      <HelmetProvider>
        <Helmet>
          <meta
            property="og:title"
            content="Kirim Ucapan Belasugkawamu yakan"
          />
          <meta property="og:description" content="In Loving Memory" />
          <meta
            property="og:image"
            itemprop="image"
            content="http://belasungkawa.id/Assets/Thumb.jpg"
          />
          <meta property="og:image:alt" content="fotomendiang" />
          <meta property="og:url" content="http://belasungkawa.id/" />
        </Helmet>
      </HelmetProvider>
      <Sidebar />
      <Box
        sx={{
          backgroundImage: `url(${Banner})`,
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h1"
          fontFamily={"Tangerine"}
          mt={10}
          sx={{
            textShadowColor: "white",
            textShadowOffset: { width: -1, height: 0 },
            textShadowRadius: 10,
            textAlign: "center",
          }}
        >
          Turut Berduka Cita
        </Typography>
        <Marquee
          pauseOnHover={true}
          speed={100}
          gradient={true}
          gradientWidth={50}
        >
          {data.map((row) => (
            <Box key={row.pk_id_mendiang} my={1}>
              <MarqueeCard key={row.pk_id_mendiang} data={row} />
            </Box>
          ))}
        </Marquee>
        <Button
          component={DirectLink}
          to={`/daftar-mendiang`}
          size="large"
          variant="contained"
          sx={{ my: 5, borderRadius: "99px", color: "white" }}
        >
          <Search mr={1} /> Cari Informasi Kedukaan
        </Button>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box sx={{ width: "80%" }}>
              <Typography variant="h3">Kirim Ucapan Belasungkawamu.</Typography>
              <Typography mt={2}>
                Situasi duka, tentu saja pernah dialami oleh setiap manusia.
                Oleh sebab itu, orang-orang sekitar perlu berusaha menguatkan
                dan mensupport mereka dengan baik. Salah satu cara untuk
                menguatkan serta mendukung orang yang sedang berduka cita adalah
                dengan mengucapkan belasungkawa yang tulus serta berisi doa-doa
                atau harapan baik.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <Box sx={{ width: "80%" }}>
                <img style={{ width: "100%" }} src={Example} alt="example" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}

export default Homepage;
