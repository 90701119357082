import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { Person } from "@mui/icons-material";
import jwt from "jwt-decode";

function FormTambahAdmin() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //=========================================================authorize

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role != "super") {
      history.push("/");
    }
  };

  //========================================get data rd
  const [dataRD, setDataRD] = useState([]);
  const GetDataRD = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/rumahduka/rumahduka";
    await axios
      .get(url)
      .then((response) => {
        setDataRD(response.data);
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer(
            "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
          );
        }
        openErrorBox();
      });
    handleCloseLoading();
  };

  useEffect(() => {
    GetDataRD();
    auth();
  }, []);

  //========================================handling input
  const initialState = {
    username: "",
    email: "",
    tlp: "",
    role: "admin",
    password: "admin",
    fk_id_rd: "",
  };

  const [data, setData] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  //====================================================validation
  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    const validationErrors = {};
    if (!data.username) {
      validationErrors.username = "Masukan Username";
    }
    if (!data.tlp) {
      validationErrors.tlp = "Masukan Nomor Telepon";
    } else {
      const valid = /^[0-9]{1,13}$/.test(data.tlp);
      !valid && (validationErrors.tlp = "Telepon Tidak Valid");
    }
    if (!data.email) {
      validationErrors.email = "Masukan Email";
    } else {
      const valid =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          data.email
        );
      !valid && (validationErrors.email = "Email Tidak Valid");
    }
    if (data.fk_id_rd <= 0) {
      validationErrors.fk_id_rd = "Pilih Rumah Duka";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      setToOpenKonfirm();
    }
  };

  //========================================handling submit
  useEffect(() => {
    if (dataKonfirm === true) {
      if (data.username) {
        handleSubmit();
      }
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm();
  }, [dataKonfirm]);

  const handleSubmit = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/user/RegisterUser";
    await axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      })
      .then(() => {
        history.push(`/users`);
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat menyimpan user, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" mt={4}>
            Form Admin Rumah Duka
          </Typography>

          <ErrorBox />

          <TextField
            fullWidth
            required
            size="small"
            id="username"
            name="username"
            value={data.username}
            onChange={handleInputChange}
            label="Username"
            variant="outlined"
            sx={{ mt: 3 }}
            error={!!errors.username}
            helperText={errors.username}
          />

          <Box sx={{ display: "flex", width: "100%" }}>
            <TextField
              fullWidth
              required
              size="small"
              id="email"
              name="email"
              value={data.email}
              onChange={handleInputChange}
              label="Email"
              variant="outlined"
              sx={{ mt: 3, mr: 3 }}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              fullWidth
              required
              size="small"
              id="tlp"
              name="tlp"
              value={data.tlp}
              onChange={handleInputChange}
              label="No Whatsapp"
              variant="outlined"
              sx={{ mt: 3 }}
              error={!!errors.tlp}
              helperText={errors.tlp}
            />
          </Box>

          <TextField
            fullWidth
            required
            size="small"
            id="rumah_duka"
            name="fk_id_rd"
            onChange={handleInputChange}
            label="Pilih Rumah Duka"
            variant="outlined"
            select
            value={data.fk_id_rd}
            sx={{ mt: 3 }}
            error={!!errors.fk_id_rd}
            helperText={errors.fk_id_rd}
          >
            {dataRD.map((option) => (
              <MenuItem key={option.pk_id_rd} value={option.pk_id_rd}>
                {option.nama_rd}
              </MenuItem>
            ))}
          </TextField>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              mt: 5,
            }}
          >
            <LoadingButton
              endIcon={<Person />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                handleLoading();
                checkValidate();
              }}
              variant="contained"
              sx={{
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                mt: 3,
              }}
            >
              Simpan
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default FormTambahAdmin;
