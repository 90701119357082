import moment from "moment";
import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Frame from "../../Assets/Avatar_frame.webp";
import {
  Box,
  Button,
  Grid,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Download, Facebook, WhatsApp } from "@mui/icons-material";
import { CustomTabPanel, a11yProps } from "../../Helper/style/style";
import { defineTimezone, getIndonesianFormattedDate, getIndonesianFormattedDateOnly, getIndonesianFormattedDateTime } from "../../Helper/format/date";

function InfoMendiang({data}) {
  //============================================custom date time
  const Date_ = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  const Time_ = {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };

  const DateTime_ = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };

  const namaHari = [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
  ];
  
  
  //============================================Tab Panel
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //===============================================count age
  const [age, setAge] = useState();
  const getAge = () => {
    const tanggal_lahir = moment(data.tanggal_lahir, "YYYY-MM-DD hh:mm");
    const tanggal_wafat = moment(data.tanggal_wafat, "YYYY-MM-DD hh:mm");
    const ageDuration = tanggal_wafat.diff(tanggal_lahir,"years");
    setAge(ageDuration);
  };
  //==============================================tabpane1data
  const Tab1Data = [
    {
      namaTab: "Wafat",
      valueTab: `: ${getIndonesianFormattedDateTime(data.tanggal_wafat)} ${defineTimezone(data.utc_difference_time)}`,
    },
   
    {
      namaTab: "Di",
      valueTab: ": " + (data.tempat_wafat ? data.tempat_wafat : "-"),
    },
    {
      namaTab: "Usia",
      valueTab: ": " + age,
    },
  ];
  const Tab1_Data = [
    {
      namaTab: "Nama Rumah Duka",
      valueTab: ": " + data.nama_rd,
    },
    {
      namaTab: "Nama Ruangan",
      valueTab: ": " + data.nama_ruangan + " di lantai " + data.lantai,
    },
    {
      namaTab: "Tanggal Masuk Ruangan",
      valueTab:
        `: ${getIndonesianFormattedDateTime(data.tanggal_masuk)} ${defineTimezone(data.utc_difference_time)}`
    },
    {
      namaTab: "Tanggal Keluar Ruangan",
      valueTab:
        `: ${getIndonesianFormattedDateTime(data.tanggal_keluar)} ${defineTimezone(data.utc_difference_time)}`
    },
  ];

  const [tab2Data, setTab2Data] = useState([]);

  useEffect(() => {
    if (data.acara.length > 0) {
      const dataAcara = [];
      data.acara.forEach((acara) => {
        dataAcara.push({
          namaTab: acara.nama_acara,
          valueTab:
            ": " +
            (acara.tanggal_acara
              ? getIndonesianFormattedDate(acara.tanggal_acara): "-"),
          valueTab2:
            acara.tanggal_acara &&
            `${new Date(acara.tanggal_acara).toLocaleString(undefined, Time_)}  ${defineTimezone(data.utc_difference_time)}`,
        });
      });
      setTab2Data(dataAcara);
    }
  }, [data]);

  const Tab3Data = [
    {
      namaTab: "Tempat",
      valueTab: ": " + (data.tempat_terakhir ? data.tempat_terakhir : "-"),
    },
    {
      namaTab: "Hari & Tanggal",
      valueTab:
        ": " +
        (data.tanggal_keluar
          ? getIndonesianFormattedDate(data.tanggal_keluar)
          : "-"),
    },
    {
      namaTab: "Pukul",
      valueTab:
        ": " +
        (data.tanggal_keluar
          ? `${new Date(data.tanggal_keluar).toLocaleString(undefined, Time_)} ${defineTimezone(data.utc_difference_time)}`
          : "-"),
    },
  ];

  
  useEffect(() => {
    getAge();
  }, [data]);
  //====================================================Blob
  function base64ToFile(base64String) {
    const binaryData = atob(base64String);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const byteArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    // Create a blob from the binary data
    const blob = new Blob([arrayBuffer], { type: "image/jpeg" });

    return blob;
  }
  //====================================================download resume
  const handleDownload = async () => {
    const url =
      process.env.REACT_APP_BASE_URL +
      "api/mendiang/resume?path_image=" +
      data.path_image_resume;
    try {
      const response = await axios.get(url);
      const blob = base64ToFile(response.data);
      // Create a URL for the image blob
      const imageUrl = window.URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = data.nama_mendiang + ".jpg"; // Set the filename
      link.click();

      // Revoke the URL to release resources
      window.URL.revokeObjectURL(imageUrl);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };
  const ShareWA = async () => {
    const link = data.path_html?(process.env.REACT_APP_BASE_URL+data.path_html).replace(/ /g, "%2520"):`${process.env.REACT_APP_BASE_URL_FRONT}`

    const message = `Berita duka cita atas berpulangnya ${data.nama_mendiang}, semoga seluruh keluarga diberikan kekuatan dan ketabahan ,informasi kedukaan dapat dilihat di ${link}`; // Replace with your desired message

    const whatsappLink = `https://api.whatsapp.com/send?text=${message}`;
    window.open(whatsappLink, "_blank");
  };
  const ShareFB = async () => {
    const link = data.path_html?(process.env.REACT_APP_BASE_URL+data.path_html).replace(" ", "%20"):`${process.env.REACT_APP_BASE_URL_FRONT}`
    const facebookLink = `https://www.facebook.com/sharer.php?app_id=1555492125272562&u=${link}`;

    window.open(facebookLink, "_blank");
  };

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography fontFamily={"Tangerine"} sx={{textAlign:'center'}} variant="h3">
        Telah Berpulang Dengan Damai
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
        <Box
          sx={{
            width: 170,
            height: 170,
            borderRadius: 170,
            position: "absolute",
            overflow: "hidden",
          }}
        >
          {data.path_image && (
            <img
              src={process.env.REACT_APP_BASE_URL + data.path_image}
              width={170}
              height={170}
              alt={data.path_image}
            />
          )}
        </Box>
        <Box position="relative">
          <img src={Frame} width={175} alt="frame" />
        </Box>
      </Box>
      <Typography variant="h5">{data.nama_mendiang}</Typography>
      <Typography variant="p" sx={{textAlign:'center'}} color="primary">{data.keterangan_mendiang}</Typography>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Rumah Duka" {...a11yProps(0)} />
          <Tab label="Detail Acara" {...a11yProps(1)} />
          <Tab label={data.metode_jenazah} {...a11yProps(2)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Box>
          <TableContainer>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
              size="small"
              border={0}
            >
              <TableBody border={0}>
                <TableRow>
                  <TableCell colSpan={2}>
                    Detail Kepulangan Mendiang :
                  </TableCell>
                </TableRow>

                {Tab1Data.map((row, index) => (
                  <TableRow key={index} border={0} width="300px">
                    <TableCell>{row.namaTab}</TableCell>
                    <TableCell style={{ maxWidth: "400px" }}>
                      {row.valueTab}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: 500 }}>
                    Tempat Persemayaman :
                  </TableCell>
                </TableRow>
                {Tab1_Data.map((row, index) => (
                  <TableRow key={index} border={0} width="300px">
                    <TableCell>{row.namaTab}</TableCell>
                    <TableCell style={{ maxWidth: "400px" }}>
                      {row.valueTab}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          size="small"
          border={0}
        >
          <TableHead border={0}>
            <TableRow>
              <TableCell>Acara</TableCell>
              <TableCell>Hari & Tanggal</TableCell>
              <TableCell>Pukul</TableCell>
            </TableRow>
          </TableHead>
          <TableBody border={0}>
            {tab2Data.map((row, index) => (
              <TableRow key={index} border={0} width="300px">
                <TableCell style={{ maxWidth: "30%" }}>{row.namaTab}</TableCell>
                <TableCell style={{ maxWidth: "400px" }}>
                  {row.valueTab}
                </TableCell>
                <TableCell style={{ maxWidth: "400px" }}>
                  {row.valueTab2}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {tab2Data.length === 0 && (
          <Typography>
            Kami belum mendapat informasi dari keluarga mendiang
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          size="small"
          border={0}
        >
          <TableBody border={0}>
            {Tab3Data.map((row, index) => (
              <TableRow key={index} border={0} width="300px">
                <TableCell style={{ width: "1px", whiteSpace: "nowrap" }}>
                  {row.namaTab}
                </TableCell>
                <TableCell style={{ maxWidth: "400px" }}>
                  {row.valueTab}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CustomTabPanel>
      {data.path_image_resume && (
        <Grid my={3} container spacing={1}>
          <Grid item xs={12} md={5}>
            <Typography textAlign="center" variant="h5">
              {" "}
              Share kepada kerabat lainnya{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleDownload();
                    }}
                    endIcon={<Download />}
                    sx={{
                      color: "white",
                      borderRadius: "99px",
                      whiteSpace: "nowrap",
                      
                    }}
                  >
                    Gambar untuk dibagikan
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<WhatsApp />}
                    sx={{
                      color: "white",
                      borderRadius: "99px",
                      whiteSpace: "nowrap",
                      mr: 1,
                    }}
                    onClick={ShareWA}
                  >
                    WhatsApp
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<Facebook />}
                    sx={{
                      color: "white",
                      borderRadius: "99px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={ShareFB}
                  >
                    Facebook
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default InfoMendiang;
