import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Link as DirectLink } from "react-router-dom";
import ListMendiang from "../../Components/Mendiang/ListMendiang";
import axios from "axios";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import SearchIcon from "@mui/icons-material/Search";
import ErrorBox from "../../Components/ErrorBox";
import { a11yProps, CustomTabPanel } from "../../Helper/style/style";
import { defineTimezone } from "../../Helper/format/date";

function DaftarMendiang() {
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    user,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;

  //============================================Tab Panel
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //=========================================================get data
  const [data, setData] = useState([]);

  const getDaftarMendiang = async (/*userID*/) => {
    let url;
    if (user.role === "admin") {
      url =
        process.env.REACT_APP_BASE_URL +
        `api/Mendiang/Mendiang/rd/${user.fk_id_rd}`;
    } else if (user.role === "super") {
      url =
        process.env.REACT_APP_BASE_URL + "api/Mendiang/Mendiang/date-future";
    } else {
      url = process.env.REACT_APP_BASE_URL + "api/Mendiang/Mendiang/date";
    }
    try {
      if (url) {
        const response = await axios.get(url);
        setSearchData(
          response.data.map((e) => {
            return {
              nama_mendiang: e.nama_mendiang,
              pk_id_mendiang: e.pk_id_mendiang,
              fk_id_rd: e.fk_id_rd,
              tanggal_lahir: e.tanggal_lahir,
              tanggal_wafat: e.tanggal_wafat,
              path_image: e.path_image,
              tanggal_masuk: e.tanggal_masuk,
              tanggal_keluar: e.tanggal_keluar,
              nama_rd: e.nama_rd,
              nama_ruangan: e.nama_ruangan,
              fk_id_avatar: e.fk_id_avatar,
              utc_difference_time: defineTimezone(e.utc_difference_time)
            };
          })
        );
        setData(
          response.data.map((e) => {
            return {
              nama_mendiang: e.nama_mendiang,
              pk_id_mendiang: e.pk_id_mendiang,
              fk_id_rd: e.fk_id_rd,
              tanggal_lahir: e.tanggal_lahir,
              tanggal_wafat: e.tanggal_wafat,
              path_image: e.path_image,
              tanggal_masuk: e.tanggal_masuk,
              tanggal_keluar: e.tanggal_keluar,
              nama_rd: e.nama_rd,
              nama_ruangan: e.nama_ruangan,
              fk_id_avatar: e.fk_id_avatar,
              utc_difference_time: defineTimezone(e.utc_difference_time)
            };
          })
        );
      }
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data mendiang, Mohon  coba lagi"
        );
      }
      openErrorBox();
    }
  };

  //=================================================delete data
  const [selected, setSelected] = useState();

  //confirmation action
  useEffect(() => {
    if (dataKonfirm === true) {
      if (selected) {
        handleDelete(selected.pk_id_mendiang);
      }
    } else if (dataKonfirm === false) {
    }
    setDataKonfirm();
    setSelected();
  }, [dataKonfirm]);

  const handleDelete = async (id) => {
    const url =
      process.env.REACT_APP_BASE_URL + "api/Mendiang/Mendiang?id=" + id;
    await axios
      .delete(url, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        setData(data.filter((item) => item.pk_id_mendiang !== id));
        setSearchData(data.filter((item) => item.pk_id_mendiang !== id));
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat hapus mendiang, Mohon  coba lagi");
        }
        openErrorBox();
      });
  };
  //================================================get initial data
  useEffect(() => {
    getDaftarMendiang();
  }, [user]);

  // ===================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");
  function searchMendiang(query) {
    query = query.toLowerCase(); // Convert the query to lowercase for case-insensitive search
    if ((value === 0) | (value === 1)) {
      return data.filter((mendiang) => {
        const nama_mendiang = mendiang.nama_mendiang
          .toLowerCase()
          .includes(query);
        const nama_rd = mendiang.nama_rd.toLowerCase().includes(query);
        return nama_mendiang || nama_rd;
      });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setInputSearchData(value)
    setInputSearchData({
      ...inputSearchData,
      [name]: value,
    });
    setSearchData(searchMendiang(value));
  };
  //=================================================filtered data
  const [nowData, setNowData] = useState([]);
  const [futureData, setFutureData] = useState([]);

  useEffect(() => {
    if (searchData) {
      if (value === 0 || value === 1) {
        const nowDate = new Date();
        setNowData(
          searchData.filter(
            (mendiang) => new Date(mendiang.tanggal_masuk) <= nowDate
          )
        );
        setFutureData(
          searchData.filter(
            (mendiang) => new Date(mendiang.tanggal_masuk) >= nowDate
          )
        );
      }
    }
  }, [searchData, value]);

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Box
            sx={{
              mt: 5,
              mb: 5,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" mr={2}>
                  Daftar Mendiang
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  placeholder="Search…"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  name="searchText"
                  onChange={handleInputChange}
                  value={inputSearchData.searchText}
                />
              </Grid>
            </Grid>
            <Box sx={{display:'flex',flexWrap:'wrap', ml:1, justifyContent:'end', gap:1}}>
              {user.role === "admin" && (
                <Box>
                  <Button
                    component={DirectLink}
                    to={`/tambah_mendiang`}
                    variant="contained"
                    sx={{ borderRadius: "100px", color: "white" }}
                  >
                    Tambah
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Ruangan Hari Ini" {...a11yProps(0)} />
              {(user.role === "admin" || user.role === "super") && (
                <Tab label="Ruangan Akan Datang" {...a11yProps(1)} />
              )}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
          <Box width={'80vw'}>
            {nowData.map((row) => (
              <ListMendiang
                login={user}
                key={row.pk_id_mendiang}
                data={row}
                id={row.pk_id_mendiang}
                deleteMendiang={() => {
                  setToOpenKonfirm();
                  setSelected({
                    pk_id_mendiang: row.pk_id_mendiang,
                    fk_id_avatar: row.fk_id_avatar,
                  });
                }}
              />
            ))}
          </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
          <Box width={'80vw'}>
            {futureData.map((row) => (
              <ListMendiang
                login={user}
                key={row.pk_id_mendiang}
                data={row}
                id={row.pk_id_mendiang}
                deleteMendiang={() => {
                  setToOpenKonfirm();
                  setSelected({
                    pk_id_mendiang: row.pk_id_mendiang,
                    fk_id_avatar: row.fk_id_avatar,
                  });
                }}
              />
            ))}
          </Box>
          </CustomTabPanel>
        </Box>
      </Box>
    </Layout>
  );
}

export default DaftarMendiang;
