import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import logo from "../../Assets/Logo.svg";
import { Send } from "@mui/icons-material";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import axios from "axios";
import ErrorBox from "../../Components/ErrorBox";
import { LoadingButton } from "@mui/lab";
export default function FormContact() {
  const masukContext = useGlobal();
  const { user, setToOpenResponse, openErrorBox, setErrorServer } =
    masukContext;
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  const initialMessage = {
    full_name: "",
    message_body: "",
    contact: "",
  };
  const [message, setMessage] = useState(initialMessage);

  useEffect(() => {
    setMessage({
      ...message,
      full_name: user.username,
      contact: user.tlp,
    });
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMessage({
      ...message,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/ContactUs/contact-us";

    await axios
      .post(url, message, {})
      .then((response) => {
        setToOpenResponse({
          pathImage: "EmailSend.png",
          tittle: "Pesan Terkirim",
          massage: "Kami akan mengubungi anda sesegera mungkin, terima kasih.",
          pathButton: "/",
          nameButton: "Tutup",
        });
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer("Terjadi error saat mengirim pesan, Mohon coba lagi");
        }
        openErrorBox();
      })
      .finally(() => {
        handleCloseLoading();
      });
  };

  return (
    <Layout>
      <ErrorBox sx={{ mb: "3" }} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2} mt={4}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h4" mb={4} textAlign="center">
                  Hubungi Kami
                </Typography>
                <img src={logo} alt="inmemoriam logo" width="100px" />
                <Typography variant="h5" my={3} textAlign="center">
                  Bantuan & Dukungan
                </Typography>
                <Typography variant="p" fontSize="1em" textAlign="center">
                  Punya pertanyaan atau ingin melaporkan masalah pada produk
                  atau layanan Inmemoriam? Kami siap membantu Anda.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  required
                  size="small"
                  id="full_name"
                  name="full_name"
                  onChange={handleInputChange}
                  label="Nama Lengkap"
                  variant="outlined"
                  value={message.full_name || " "}
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  required
                  size="small"
                  id="message_body"
                  name="message_body"
                  onChange={handleInputChange}
                  label="Pesan untuk kami"
                  variant="outlined"
                  value={message.message_body || " "}
                  sx={{ mt: 2 }}
                  multiline
                />
                <TextField
                  fullWidth
                  required
                  size="small"
                  id="contact"
                  name="contact"
                  onChange={handleInputChange}
                  label="E-mail atau Nomor Telepon"
                  variant="outlined"
                  value={message.contact || " "}
                  sx={{ mt: 2 }}
                />
                <LoadingButton
                  onClick={() => {
                    handleSubmit();
                    handleLoading();
                  }}
                  size="small"
                  endIcon={<Send />}
                  loadingPosition="end"
                  loading={loadingSts}
                  variant="contained"
                  sx={{ my: 5, borderRadius: "99px", color: "white" }}
                >
                  Kirim
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
}
