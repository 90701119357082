import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./Page/Homepage";
import FormTambahRD from "./Page/RumahDuka/FormTambahRD";
import TambahRumahDuka from "./Page/RumahDuka/TambahRumahDuka";
import EditRumahDuka from "./Page/RumahDuka/EditRumahDuka";
import ViewRD from "./Page/RumahDuka/ViewRD";
import FormTambahRuangan from "./Page/Ruangan/FormTambahRuangan";
import EditRuangan from "./Page/Ruangan/EditRuangan";
import DaftarMendiang from "./Page/Mendiang/DaftarMendiang";
import TambahMendiang from "./Page/Mendiang/TambahMendiang";
import EditMendiang from "./Page/Mendiang/EditMendiang";
import DetailMendiang from "./Page/Mendiang/DetailMendiang";
import DaftarPesan from "./Page/Pesan/DaftarPesan";
import TemplateEditor from "./Page/Template/TemplateEditor";
import DaftarListTemplate from "./Page/Template/DaftarListTemplate";
import FormTambahTemplate from "./Page/Template/FormTambahTemplate";
import Register from "./Page/User/Register";
import ResetPassword from "./Page/User/ResetPassword";
import DaftarUser from "./Page/User/DaftarUser";
import FormAdmin from "./Page/User/FormAdmin";
import PaymentPage from "./Page/Payment/PaymentPage";
import DaftarKeranjang from "./Page/Pesan/DaftarKeranjang";
import PesanKu from "./Page/Pesan/PesanKu";
import DaftarAllKeranjang from "./Page/Pesan/DaftarAllKeranjang";
import KirimUcapanMendiang from "./Page/Mendiang/KirimUcapanMendiang";
import Response from "./Page/Response/Response";
import Report from "./Page/Report/Report";
import Komisi from "./Page/Report/Komisi";
import SponsorAddForm from "./Page/Sponsor/SponsorAddForm";
import SponsorEditForm from "./Page/Sponsor/SponsorEditForm";
import DaftarVoucher from "./Page/Voucher/DaftarVoucher";
import FormTambahVoucher from "./Page/Voucher/FormTambahVoucher";
import NotFound from "./Page/NotFound";
import DaftarListResumeTemplate from "./Page/ResumeTemplate/DaftarListResumeTemplate";
import FormTambahResumeTemplate from "./Page/ResumeTemplate/FormTambahResumeTemplate";
import CustomTwibbon from "./Page/Mendiang/CustomTwibbon";
import RiwayatMendiang from "./Page/Mendiang/RiwayatMendiang";
import FormContact from "./Page/ContactUs/FormContact";

function routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route exact path="/tambah_rd">
          <TambahRumahDuka />
        </Route>
        <Route exact path="/form_tambah_rd">
          <FormTambahRD />
        </Route>
        <Route exact path="/edit_rd/:id">
          <EditRumahDuka />
        </Route>
        <Route exact path="/view_rd/:id">
          <ViewRD />
        </Route>
        <Route exact path="/tambah_ruangan/:id">
          <FormTambahRuangan />
        </Route>
        <Route exact path="/edit_ruangan/:id">
          <EditRuangan />
        </Route>
        <Route exact path="/daftar-mendiang">
          <DaftarMendiang />
        </Route>
        <Route exact path="/tambah_mendiang">
          <TambahMendiang />
        </Route>
        <Route exact path="/edit_mendiang/:id">
          <EditMendiang />
        </Route>
        <Route exact path="/daftar-mendiang/detail/:id">
          <DetailMendiang />
        </Route>
        <Route exact path="/riwayat-mendiang">
          <RiwayatMendiang />
        </Route>
        <Route exact path="/custom-twibbon">
          <CustomTwibbon />
        </Route>
        <Route exact path="/daftar_pesan">
          <DaftarPesan />
        </Route>
        <Route exact path="/template/selected-template">
          <TemplateEditor />
        </Route>
        <Route exact path="/template/daftar">
          <DaftarListTemplate />
        </Route>
        <Route exact path="/template/tambah">
          <FormTambahTemplate />
        </Route>
        <Route exact path="/template/edit/:id">
          <FormTambahTemplate />
        </Route>
        <Route exact path="/resume-template/daftar">
          <DaftarListResumeTemplate />
        </Route>
        <Route exact path="/resume-template/tambah">
          <FormTambahResumeTemplate />
        </Route>
        <Route exact path="/resume-template/edit/:id">
          <FormTambahResumeTemplate />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/reset/:result">
          <ResetPassword />
        </Route>
        <Route exact path="/users">
          <DaftarUser />
        </Route>
        <Route exact path="/users/admin">
          <FormAdmin />
        </Route>
        <Route exact path="/users/admin/:id">
          <FormAdmin />
        </Route>
        <Route exact path="/pesan/order">
          <KirimUcapanMendiang />
        </Route>
        <Route exact path="/pesan/payment/:id">
          <PaymentPage />
        </Route>
        <Route exact path="/pesan/keranjang">
          <DaftarKeranjang />
        </Route>
        <Route exact path="/pesan/pesanku">
          <PesanKu />
        </Route>
        <Route exact path="/pesan/all-keranjang">
          <DaftarAllKeranjang />
        </Route>
        <Route exact path="/response/:data">
          <Response />
        </Route>
        <Route exact path="/daftar_laporan">
          <Report />
        </Route>
        <Route exact path="/komisi">
          <Komisi />
        </Route>
        <Route exact path="/sponsor/form/:id">
          <SponsorAddForm />
        </Route>
        <Route exact path="/sponsor/edit/:id">
          <SponsorEditForm />
        </Route>
        <Route exact path="/voucher">
          <DaftarVoucher />
        </Route>
        <Route exact path="/voucher/create">
          <FormTambahVoucher />
        </Route>
        <Route exact path="/hubungi-kami">
          <FormContact />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default routes;
