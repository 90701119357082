import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import {
  Box,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwt from "jwt-decode";
import { Search } from "@mui/icons-material";
import ListMendiang from "../../Components/Mendiang/ListMendiang";
import Sidebar from "../../Components/Sidebar";
import ErrorBox from "../../Components/ErrorBox";
function RiwayatMendiang() {
  const masukContext = useGlobal();
  const { loginToken, user, openErrorBox, setErrorServer } = masukContext;
  //===============================================================Get data RD
  const [dataRD, setDataRD] = useState([]);

  const getDaftarRD = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/RumahDuka/RumahDuka";
    try {
      const response = await axios.get(url);
      console.log(response)
      setDataRD(
        response.data.map((e) => {
          return {
            pk_id_rd: e.pk_id_rd,
            nama_rd: e.nama_rd,
            alamat: e.alamat,
            commission: e.commission,
            utc_differnce_time: e.utc_difference_time
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data rumah duka, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  
  //=========================================================authorize
  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role !== "admin" && token.role !== "super") {
      history.push("/");
    }
  };
  useEffect(() => {
    auth();
    getDaftarRD();
  }, [loginToken]);
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }
  //==========================================get mendiang past
  const [data, setData] = useState([]);

  const getDaftarMendiangLalu = async (/*userID*/) => {
    try {
      const url =
        process.env.REACT_APP_BASE_URL +
        `api/Mendiang/mendiang/date-past?tanggal_awal_utc=${searchDate.tanggal_awal}&tanggal_akhir_utc=${searchDate.tanggal_akhir}&idrd=${searchDate.fk_id_rd}`;
      const response = await axios.get(url);

      setData(
        response.data.map((e) => {
          return {
            nama_mendiang: e.nama_mendiang,
            pk_id_mendiang: e.pk_id_mendiang,
            fk_id_rd: e.fk_id_rd,
            tanggal_lahir: e.tanggal_lahir,
            tanggal_wafat: e.tanggal_wafat,
            path_image: e.path_image,
            tanggal_masuk: e.tanggal_masuk,
            tanggal_keluar: e.tanggal_keluar,
            nama_rd: e.nama_rd,
            nama_ruangan: e.nama_ruangan,
            fk_id_avatar: e.fk_id_avatar,
          };
        })
      );
      setSearchData(
        response.data.map((e) => {
          return {
            nama_mendiang: e.nama_mendiang,
            pk_id_mendiang: e.pk_id_mendiang,
            fk_id_rd: e.fk_id_rd,
            tanggal_lahir: e.tanggal_lahir,
            tanggal_wafat: e.tanggal_wafat,
            path_image: e.path_image,
            tanggal_masuk: e.tanggal_masuk,
            tanggal_keluar: e.tanggal_keluar,
            nama_rd: e.nama_rd,
            nama_ruangan: e.nama_ruangan,
            fk_id_avatar: e.fk_id_avatar,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat pengambilan data mendiang, Mohon  coba lagi"
        );
      }
      openErrorBox();
    } finally {
      handleCloseLoading();
    }
  };

  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");
  function searchMendiang(query) {
    query = query.toLowerCase(); // Convert the query to lowercase for case-insensitive search

    return data.filter((mendiang) => {
      const nama_mendiang = mendiang.nama_mendiang
        .toLowerCase()
        .includes(query);
      const nama_rd = mendiang.nama_rd.toLowerCase().includes(query);
      return nama_mendiang || nama_rd;
    });
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setInputSearchData(value)
    setInputSearchData({
      ...inputSearchData,
      [name]: value,
    });
    setSearchData(searchMendiang(value));
  };
  //========================================input date
  const initialState = {
    tanggal_awal: "",
    tanggal_akhir: "",
    
  };

  const [searchDate, setSearchDate] = useState({...initialState, fk_id_rd: 0, timeZone:0});
  const [disabled, setDisabled] = useState(false)
  useEffect(() => {
    if(user.fk_id_rd>0){
      const timeZone = dataRD.filter((data)=> data.pk_id_rd = user.fk_id_rd)
      setSearchDate({...searchDate, fk_id_rd: user.fk_id_rd, timeZone:timeZone.utc_difference_time});
      setDisabled(true)
    }
  }, [user, dataRD])
  

  //=============================== validate

  const [errors, setErrors] = useState(initialState);

  const checkValidate = () => {
    const validationErrors = {};
    if (!searchDate.tanggal_awal) {
      validationErrors.tanggal_awal = "Masukan Tanggal Awal";
    }
    if (!searchDate.tanggal_akhir) {
      validationErrors.tanggal_akhir = "Masukan Tanggal Akhir";
    }
    if (searchDate.fk_id_rd === 0 || searchDate.fk_id_rd === null) {
      validationErrors.fk_id_rd = "Pilih Rumah Duka";
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
        setErrors(initialState);
      // Add logic to handle form submission (e.g., sending data to a server)
      getDaftarMendiangLalu();
    }
  };
  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <ErrorBox />
          <Box
            sx={{
              mt: 5,
              mb: 5,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" mr={2}>
                  Daftar Mendiang
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  placeholder="Search…"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  name="searchText"
                  onChange={handleInputChange}
                  value={inputSearchData.searchText}
                />
              </Grid>
            </Grid>
          </Box>
          <Typography textAlign={"center"}>
            Input jangka waktu mendiang berada diruangan
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                required
                size="small"
                id="fk_id_rd"
                name="fk_id_rd"
                onChange={(e) => {
                  setSearchDate({ ...searchDate, fk_id_rd: e.target.value, timeZone:e.target.timeZone });
                }}
                label="Pilih Rumah Duka"
                variant="outlined"
                select
                disabled={disabled}
                value={searchDate.fk_id_rd}
                error={!!errors.fk_id_rd}
                helperText={errors.fk_id_rd}
              >
                <MenuItem value="0">Pilih Rumah Duka...</MenuItem>
                {dataRD.map((option) => (
                  <MenuItem key={option.pk_id_rd} timeZone={option.utc_difference_time} value={option.pk_id_rd}>
                    {option.nama_rd}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <DatePicker
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                  id="tanggal_awal"
                  label="Tanggal Awal"
                  disableFuture
                  format="DD MMM YYYY"
                  value={dayjs(searchDate.tanggal_awal)}
                  onChange={(e) =>
                    setSearchDate({
                      ...searchDate,
                      tanggal_awal: e.format("YYYY-MM-DD"),
                    })
                  }
                />
                <FormHelperText error>{errors.tanggal_awal}</FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <DatePicker
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                  id="tanggal_akhir"
                  label="Tanggal Akhir"
                  minDate={dayjs(searchDate.tanggal_awal)}
                  disableFuture
                  format="DD MMM YYYY"
                  value={dayjs(searchDate.tanggal_akhir)}
                  onChange={(e) =>
                    setSearchDate({
                      ...searchDate,
                      tanggal_akhir: e.format("YYYY-MM-DD"),
                    })
                  }
                />
                <FormHelperText error>{errors.tanggal_akhir}</FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <LoadingButton
                endIcon={<Search />}
                // disabled={disabled}
                loading={loadingSts}
                loadingPosition="end"
                variant="contained"
                sx={{ borderRadius: "100px", color: "white" }}
                onClick={() => {
                  handleLoading();
                  checkValidate();
                }}
              >
                Cari
              </LoadingButton>
            </Grid>
          </Grid>
               
          {searchData.length>0? searchData.map((row) => (
            <ListMendiang
              login={user}
              key={row.pk_id_mendiang}
              data={row}
              id={row.pk_id_mendiang}
              readOnly={true}
            />
          )):
          <Box sx={{width:'100%', display:'flex', justifyContent:'center'}}>
            <Typography> Data tidak ditemukan </Typography>
          </Box>}
        </Box>
      </Box>
    </Layout>
  );
}

export default RiwayatMendiang;
