import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import Frame from "../../Assets/Avatar_frame.webp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function MarqueeCard({ data }) {
  const history = useHistory();

  //=============================================formating date time

  const date = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  return (
    <Box
      onClick={() => {
        history.push(`daftar-mendiang/detail/${data.pk_id_mendiang}`);
      }}
    >
      <Card sx={{ width: 200, borderRadius: 3 }}>
        <CardActionArea>
          {/* <CardMedia component="img" image={Frame} width={150}/> */}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: 150,
                height: 150,
                borderRadius: 150,
                position: "absolute",
                overflow: "hidden",
              }}
            >
              <img
                src={process.env.REACT_APP_BASE_URL + data.path_image}
                width={150}
                height={150}
                alt={data.nama_mendiang}
              />
            </Box>
            <Box position="relative">
              <img src={Frame} alt="frame" />
            </Box>
            <Typography
              sx={{
                maxWidth: 190,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {data.nama_mendiang}
            </Typography>
            <Typography variant="p">
              Wafat:{" "}
              {new Date(data.tanggal_wafat).toLocaleString(undefined, date)}
            </Typography>
            <Typography variant="p">Rumah Duka</Typography>
            <Typography
              variant="p"
              sx={{
                maxWidth: 190,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {data.nama_rd}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default MarqueeCard;
