import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link as DirectLink } from "react-router-dom";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function Response() {
  const initialResponse={
    pathImage:"",
    tittle:"", 
    massage:"", 
    pathButton:"", 
    nameButton:""
  }
    const [dataResponse, setDataResponse] = useState(initialResponse)
    const Image = `../../Assets/Response/${dataResponse.pathImage}`

    const params = useParams();
    useEffect(() => {
     if(params.data==='email-cofirm'){
      setDataResponse({
        pathImage:"Verify.png",
        tittle:"Berhasil Aktivasi", 
        massage:"Akun anda berhasil diaktivasi, silahkan login pada inmemoriam.id", 
        pathButton:"/", 
        nameButton:"Login"
      })
     }
     if(params.data==='error'){
      setDataResponse({
        pathImage:"Error.png",
        tittle:"Internal Server Error", 
        massage:"Maaf atas ketidaknyamanan anda, Silahkan hubungi admin inmemoriam.id atau coba lagi nanti", 
        pathButton:"/", 
        nameButton:"Kembali"
      })
     }
     if(params.data==='already-active'){
      setDataResponse({
        pathImage:"Ok.png",
        tittle:"Akun Sudah Diaktifkan Sebelumnya", 
        massage:"Akun anda berhasil diaktivasi, silahkan login pada inmemoriam.id", 
        pathButton:"/", 
        nameButton:"Login"
      })
     }
     if(params.data==='user-not-found'){
      setDataResponse({
        pathImage:"worng.png",
        tittle:"Akun Anda Tidak Terdaftar", 
        massage:"Silahkan Registrasi pada inmemoriam.id", 
        pathButton:"/", 
        nameButton:"Registrasi"
      })
     }
     if(params.data==='password-reset'){
      setDataResponse({
        pathImage:"ResetPW.png",
        tittle:"Password Telah Diganti", 
        massage:"Silahkan login menggunakan password baru pada inmemoriam.id", 
        pathButton:"/", 
        nameButton:"Login"
      })
     }
    }, [params])
    
  return (
    <Box>
      <Box
      
        sx={{ display: "flex", m:3, textDecoration: "none" }}
      >
        <Typography variant="h6" component="div" sx={{fontFamily:'Affinity Future', color:'primary.main'}}>
          InMemoriam.id
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent:"center", flexDirection:"column", alignItems:"center ", width:"100%"}}>

        <Box>
          <img src={Image} alt={dataResponse.pathImage} width="250px" />
        </Box>
        {dataResponse.tittle&&(
        <Typography textAlign="center" mt={3} variant="h5" component="div" color="secondary">
          {dataResponse.tittle}
        </Typography>
        )}
        {dataResponse.massage&&(

        <Typography textAlign="center" sx={{ m: 3 }} variant="p">
         {dataResponse.massage} 
        </Typography>
        )}
        <Box>
        {dataResponse.pathButton&&(

        <Button
          component={DirectLink}
          to={`${dataResponse.pathButton}`}
          variant="contained"
          color="secondary"
          size="Large"
          sx={{borderRadius:'99px'}}
        >
          {dataResponse.nameButton?dataResponse.nameButton:"Oke"}
        </Button>
        )}
        </Box>
      </Box>
      
    </Box>
  )
}

export default Response