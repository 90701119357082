import React, { useState } from "react";
import Layout from "../../Components/Layout";
import { Box, Button, Typography } from "@mui/material";
import ErrorBox from "../../Components/ErrorBox";
import { Link as DirectLink } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import Sidebar from "../../Components/Sidebar";
import ResumeTemplateCard from "../../Components/ResumeTemplate/ResumeTemplateCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwt from "jwt-decode";

function DaftarListResumeTemplate() {
  //==================================================Context
  const masukContext = useGlobal();
  const { user, openErrorBox, setErrorServer, loginToken } = masukContext;

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role !== "super") {
      history.push("/");
    }
  };
  //==========================================================get data
  const [data, setData] = useState([]);

  const getDaftarTemplate = async (/*userID*/) => {
    const url =
      process.env.REACT_APP_BASE_URL + "api/ResumeTemplate/resume-template/all";
    try {
      const response = await axios.get(url);
      setData(
        response.data.map((e) => {
          return {
            nama_resume_template: e.nama_resume_template,
            pk_id_resume_template: e.pk_id_resume_template,
            path_thumb_resume_template: e.path_thumb_resume_template,
            path_resume_template: e.path_resume_template,
            path_resume_template_tv: e.path_resume_template_tv,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data resume template, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };
  useEffect(() => {
    getDaftarTemplate(/*usrid*/);
    auth();
  }, []);

  //==========================================================Delete data
  const deleteData = (id) => {
    setData(data.filter((item) => item.pk_id_template != id));
  };

  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", mt: 7 }}>
            <ErrorBox />

            <Box
              sx={{
                mb: 5,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3">Daftar Resume Template</Typography>
              <Box>
                <Button component={DirectLink} to="/resume-template/tambah">
                  Tambah Resume Template
                </Button>
              </Box>
            </Box>
            <ResumeTemplateCard
              user={user.role}
              data={data}
              deleteData={deleteData}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default DaftarListResumeTemplate;
