import React from "react";
import Layout from "../../Components/Layout";
import Sidebar from "../../Components/Sidebar";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Typography,
  Tabs,
  Tab,
  Modal,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import { Link as DirectLink } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useGlobal } from "../../Helper/Context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ErrorBox from "../../Components/ErrorBox";
import { LoadingButton } from "@mui/lab";
import { AddCircle, Close, Group, Search } from "@mui/icons-material";
import TopUp from "../../Components/Agent/TopUp";
import "../../Helper/format/currency";
import jwt from "jwt-decode";
import {
  StyledTableCell,
  StyledTableRow,
  styleBoxModal,
  a11yProps,
} from "../../Helper/style/style";

function DaftarUser() {
  //============================================Tab Panel
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //==================================================Context
  const masukContext = useGlobal();
  const {
    loginToken,
    dataKonfirm,
    setDataKonfirm,
    setToOpenKonfirm,
    openErrorBox,
    setErrorServer,
  } = masukContext;
  //=========================================================authorize

  const history = useHistory();
  const auth = () => {
    let token = { role: "" };
    if (loginToken) {
      token = jwt(loginToken);
    }
    if (token.role != "super") {
      history.push("/");
    }
  };

  //===================================Get Data

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${loginToken}`,
    },
  };
  const [data, setData] = useState([]);

  const getDaftarUser = async (/*userID*/) => {
    const url = process.env.REACT_APP_BASE_URL + "api/user/getuser";
    try {
      const response = await axios.get(url, axiosConfig);
      setData(
        response.data.map((e) => {
          return {
            pk_id_user: e.pk_id_user,
            username: e.username,
            email: e.email,
            tlp: e.tlp,
            role: e.role,
            fk_id_rd: e.fk_id_rd,
            nama_rd: e.nama_rd,
            fk_id_agent: e.fk_id_agent,
            saldo: e.saldo,
            discount: e.discount,
            fk_id_sponsor: e.fk_id_sponsor,
            nama_sponsor: e.nama_sponsor,
          };
        })
      );
    } catch (error) {
      if (error.response.data.length < 100) {
        setErrorServer(error.response.data);
      } else {
        setErrorServer(
          "Terjadi error saat mengambil data user, Mohon coba lagi"
        );
      }
      openErrorBox();
    }
  };

  useEffect(() => {
    getDaftarUser(/*usrid*/);
    auth();
  }, []);

  // ===================================================search
  const [searchData, setSearchData] = useState([]);
  const [inputSearchData, setInputSearchData] = useState("");
  function searchFunction(query) {
    query = query.toLowerCase(); // Convert the query to lowercase for case-insensitive search

    return data.filter((data) => {
      const filteredData = data.username.toLowerCase().includes(query);
      const filteredData2 = data.email.toLowerCase().includes(query);
      return filteredData || filteredData2;
    });
  }

  const handleInputChangeSearch = (e) => {
    const { name, value } = e.target;
    // setInputSearchData(value)
    setInputSearchData({
      ...inputSearchData,
      [name]: value,
    });
    setSearchData(searchFunction(value));
  };

  useEffect(() => {
    setSearchData(data);
  }, [data]);
  //=================================================filtered data
  const [userData, setUserData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  useEffect(() => {
    if (searchData) {
      setUserData(searchData.filter((user) => user.role === "user"));
      setAdminData(searchData.filter((user) => user.role === "admin"));
    }
  }, [searchData]);
  //============================================================== Loading Button
  const [loadingSts, setLoadingSts] = useState(false);
  function handleLoading() {
    setLoadingSts(true);
  }
  function handleCloseLoading() {
    setLoadingSts(false);
  }

  //===========================================open modal agent
  const [openModal, setOpenModal] = useState(false);
  const setToOpenModal = (pk_id_user) => {
    setOpenModal(true);
    setDataAgent({
      ...dataAgent,
      pk_id_user: pk_id_user,
    });
  };
  const setToCloseModal = () => {
    setOpenModal(false);
  };

  //========================================handling input
  const initialState = {
    email: "",
    password: "",
  };
  const [dataAgent, setDataAgent] = useState(initialState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDataAgent({
      ...dataAgent,
      [name]: value,
    });
  };

  //===========================================================validate
  const [errors, setErrors] = useState(initialState);

  const checkValidateAgent = () => {
    const validationErrors = {};

    if (!dataAgent.saldo) {
      validationErrors.saldo = "Masukan Saldo";
    } else {
      const valid = dataAgent.saldo > 0;
      !valid && (validationErrors.saldo = "Saldo tidak boleh minus");
    }
    if (!dataAgent.discount) {
      validationErrors.discount = "Masukan Discount";
    } else {
      const valid = dataAgent.discount > 0;
      !valid && (validationErrors.discount = "Discount tidak boleh minus");
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set the error messages and don't submit the form
      setErrors(validationErrors);
      handleCloseLoading();
    } else {
      // Add logic to handle form submission (e.g., sending data to a server)
      setToOpenKonfirm();
    }
  };
  //=========================================submit agent
  useEffect(() => {
    if (dataKonfirm === true) {
      if (dataAgent.saldo > 0) {
        handleSubmit();
      }
    } else if (dataKonfirm === false) {
      handleCloseLoading();
    }
    setDataKonfirm();
  }, [dataKonfirm]);

  const handleSubmit = async () => {
    const url = process.env.REACT_APP_BASE_URL + "api/agent/user-agent";
    await axios
      .put(url, dataAgent, {
        headers: {
          Authorization: `bearer ${loginToken}`,
        },
      })
      .then(() => {
        getDaftarUser();
        setToCloseModal();
      })
      .catch((error) => {
        if (error.response.data.length < 100) {
          setErrorServer(error.response.data);
        } else {
          setErrorServer(
            "Terjadi error saat mengambil data agent, Mohon coba lagi"
          );
        }
        openErrorBox();
      })
      .finally(handleCloseLoading());
  };
  //================================================topUp

  const [openTopUp, setOpenTopUp] = useState(false);

  const setToOpenTopUp = () => {
    setOpenTopUp(true);
  };

  const setToCloseTopUp = (berhasil) => {
    setOpenTopUp(false);

    if (berhasil) {
      getDaftarUser();
    }
  };
  const initialStateTopUp = {
    pk_id_agent: 0,
    saldo: 0,
  };
  const [dataTopUp, setdataTopUp] = useState(initialStateTopUp);
  return (
    <Layout>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 5,
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h5" mr={2}>
                  Daftar User
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  placeholder="Search…"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  name="searchText"
                  onChange={handleInputChangeSearch}
                  value={inputSearchData.searchText}
                />
              </Grid>
            </Grid>
            <Box>
              <Button
                component={DirectLink}
                to="/users/admin"
                variant="contained"
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius: "100px",
                  color: "white",
                }}
              >
                Tambah Admin
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="User" {...a11yProps(0)} />
              <Tab label="Admin Rumah Duka" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TableContainer hidden={value !== 0} sx={{ my: 2 }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>No</StyledTableCell>
                  <StyledTableCell align="center">Nama User</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Telepon</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map((row, index) => (
                  <StyledTableRow key={row.pk_id_user}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.username}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.tlp}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.fk_id_agent > 0 ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Box>
                            <Typography fontSize="10px">
                              Saldo Agent {row.saldo.currency()}
                            </Typography>
                            <Typography fontSize="10px">
                              Discount Agent {row.discount}%
                            </Typography>
                          </Box>
                          <AddCircle
                            color="primary"
                            sx={{ ml: 1, cursor: "pointer" }}
                            onClick={() => {
                              setToOpenTopUp();
                              setdataTopUp({
                                loginToken: loginToken,
                                saldo: row.saldo,
                                pk_id_agent: row.fk_id_agent,
                              });
                            }}
                          />
                        </Box>
                      ) : row.fk_id_sponsor > 0 ? (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Box>
                            <Typography fontSize="10px">
                              Sponsor: {row.nama_sponsor}
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              variant="contained"
                              size="small"
                              color="ok"
                              onClick={() => {
                                history.push(
                                  `/sponsor/edit/${row.fk_id_sponsor}`
                                );
                              }}
                              sx={{
                                borderRadius: "100px",
                                color: "white",
                                fontSize: "10px",
                              }}
                            >
                              Edit Sponsor
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Box>
                            <Button
                              variant="contained"
                              size="small"
                              color="success"
                              onClick={() => {
                                setToOpenModal(row.pk_id_user);
                              }}
                              sx={{
                                borderRadius: "100px",
                                color: "white",
                                fontSize: "10px",
                              }}
                            >
                              Tambah Agent
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              variant="contained"
                              size="small"
                              color="ok"
                              onClick={() => {
                                history.push(`/sponsor/form/${row.pk_id_user}`);
                              }}
                              sx={{
                                borderRadius: "100px",
                                color: "white",
                                fontSize: "10px",
                                mt: 0.5,
                              }}
                            >
                              Tambah Sponsor
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer hidden={value !== 1} sx={{ my: 5 }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>No</StyledTableCell>
                  <StyledTableCell align="center">Nama User</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Telepon</StyledTableCell>
                  <StyledTableCell align="center">Rumah Duka</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminData.map((row, index) => (
                  <StyledTableRow key={row.pk_id_user}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.username}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.tlp}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.nama_rd}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Modal
        open={openModal}
        onClose={() => {
          setToCloseModal();
          setDataAgent({ email: "", password: "" });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleBoxModal}>
          <Box
            sx={{
              width: "25px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              backgroundColor: "white",
              position: "absolute",
              right: "-10px",
              top: "-10px",
              border: "solid",
              borderRadius: "50px",
              borderColor: "primary.main",
            }}
          >
            <Typography
              sx={{ color: "primary.main" }}
              onClick={() => {
                setToCloseModal();
              }}
            >
              <Close />
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 3,
            }}
          >
            <ErrorBox />

            <Typography variant="h4">Form Agent</Typography>
            <TextField
              fullWidth
              size="small"
              id="saldo"
              name="saldo"
              onChange={handleInputChange}
              label="Masukan Saldo Awal"
              variant="outlined"
              sx={{ mt: 3 }}
              error={!!errors.saldo}
              helperText={errors.saldo}
            />
            <TextField
              fullWidth
              size="small"
              id="discount"
              name="discount"
              onChange={handleInputChange}
              label="Masukan Discount"
              variant="outlined"
              sx={{ mt: 3 }}
              error={!!errors.discount}
              helperText={errors.discount}
            />

            <LoadingButton
              endIcon={<Group />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                handleLoading();
                checkValidateAgent();
              }}
              variant="contained"
              sx={{
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                mt: 3,
              }}
            >
              Tambah Agent
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModal}
        onClose={() => {
          setToCloseModal();
          setDataAgent({ email: "", password: "" });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleBoxModal}>
          <Box
            sx={{
              width: "25px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              backgroundColor: "white",
              position: "absolute",
              right: "-10px",
              top: "-10px",
              border: "solid",
              borderRadius: "50px",
              borderColor: "primary.main",
            }}
          >
            <Typography
              sx={{ color: "primary.main" }}
              onClick={() => {
                setToCloseModal();
              }}
            >
              <Close />
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 3,
            }}
          >
            <ErrorBox sx={{ mb: "3" }} />

            <Typography variant="h4">Form Agent</Typography>
            <TextField
              fullWidth
              size="small"
              id="saldo"
              name="saldo"
              onChange={handleInputChange}
              label="Masukan Saldo Awal"
              variant="outlined"
              sx={{ mt: 3 }}
              error={!!errors.saldo}
              helperText={errors.saldo}
            />
            <TextField
              fullWidth
              size="small"
              id="discount"
              name="discount"
              onChange={handleInputChange}
              label="Masukan Discount"
              variant="outlined"
              sx={{ mt: 3 }}
              error={!!errors.discount}
              helperText={errors.discount}
            />

            <LoadingButton
              endIcon={<Group />}
              // disabled={disabled}
              loading={loadingSts}
              loadingPosition="end"
              onClick={() => {
                handleLoading();
                checkValidateAgent();
              }}
              variant="contained"
              sx={{
                borderRadius: "999px",
                textTransform: "none",
                fontWeight: "400",
                marginRight: "20px",
                color: "white",
                mt: 3,
              }}
            >
              Tambah Agent
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <TopUp
        openModal={openTopUp}
        closeModal={setToCloseTopUp}
        data={dataTopUp}
      />
    </Layout>
  );
}

export default DaftarUser;
